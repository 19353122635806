// Created by SPe on 25/09/2022
// component for Validate Dataset Form
<template>
  <div class="root">
    <h2>Validate Dataset</h2>
    <!-- Dataset Name -->
    <h4 class="py-2">Engine Version to validate with:</h4>
    <select  class="border-2 border-gray-800 rounded-md" @change="onEngineSelected($event)">
        <option value=null>Select a Engine Version</option>
        <option v-for="eng in availableEngines" :key="eng" :value="eng.EngineId">{{eng.EngineVer}}</option>
    </select>
    <!-- Execution Machine -->
    <h2>Execution Machine</h2>
    <select class="border-2 border-gray-800 rounded-md px-1 mx-2" @change="onNewMachineSelected($event)" v-model="machineSelected">
        <option value="$VAST$">Datacenter 1 Instance</option>
        <option value="$RUNPOD$">Datacenter 2 Instance</option>
        <option value="$AWS$">AWS EC2 Instance</option>
        <option v-for="machine in machineList" :key="machine.MachineId" :value="machine.MachineId">{{machine.MachineName}}</option>
    </select>
    <div><small>Execution machine for this training.</small></div>


    <!-- Error message -->
    <p v-if="errorMsg" style="background-color:#FDD">{{errorMsg}}</p>
    <!-- Submit/Cancel Buttons -->
    <table style="width:100%">
        <tr style="width:100%">
            <td style="text-align: center"><button @click="submitForm" class="bg-button-green rounded-lg">Submit</button></td>
            <td style="text-align: center"><button @click="cancel" class="bg-red-400">Cancel</button></td>
        </tr>
    </table>    
  </div> 
</template>

<script>
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
    },
    data: function(){
        return {
            errorMsg: null,
            selectedEngineId: null, // Dataset Id      
            handleKeyDown: null,    

            machineSelected: '$VAST$', 
        }
    },
    props: {
        availableEngines: {type: Array, default: []},
        machineList: {type: Array, default: []}, // List of machines available
    },
    methods: {
        submitForm() {
            console.log('Submit Form');
            // Send event
            this.$emit("validateDatasetSubmitted", 
                {
                    EngineId: this.selectedEngineId,
                    MachineId: this.machineSelected
                }
            );
            console.log('Form successfully submitted.');
        },
        cancel() {
            this.$emit("validateDatasetSubmitted", null);
        },
        onEngineSelected(event) {
            this.selectedEngineId = event.target.value;            
            console.log(`onEngineSelected. Engine Id: ${this.selectedEngineId}`);
        },
        onNewMachineSelected() {
            console.log(`onNewMachineSelected: ${this.machineSelected}`);
        },
    },
    computed: {    

    },
    
    // Lifecycle hooks
    mounted() {
        console.log('Validate Dataset Form Mounting');
        
        this.handleKeyDown = (event) => { 
            // console.log('Key Pressed: ' + event.code);           
            if (event.code === "Enter") this.submitForm();
            else if (event.code === "Escape") this.cancel();   
        };
        window.addEventListener("keydown", this.handleKeyDown, false);
        
    },
    unmounted() {
        console.log('Validate Dataset Form Unmounted');
        window.removeEventListener("keydown", this.handleKeyDown, false); 
    },
        
})
export default class NavBar extends Vue {}
</script>
// Style only for this component/view
<style scoped>
    .root {
        width: 25em;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px;
        margin-top: 100px;
        border-radius: 20px;
    }
    input {
        border: 1px solid;
        border-color: #5A5;
        background: #EFE;
        border-radius: 4px;
        outline: none;
        border-bottom: 1px solid #ddd;
        font-size: 1em;
        padding: 5px 0;
        margin: 10px 0 5px 0;
        width: 100%;
    }
    button {
        padding: 10px 20px;
        margin-top: 10px;
        border: none;
        color: white;
    }
    .error {
        border-color: rgb(243, 39, 39);
        background: rgb(255, 217, 208);
    }
</style>


