// Created by SPe on 
// Page to 
<template>
    <div>
        <nav class="relative w-full flex flex-wrap items-center justify-between py-0 bg-darkBlue text-gray-50 shadow-lg navbar navbar-expand-lg navbar-light">
            <div class="container-fluid w-full flex flex-wrap items-center justify-between px-6">
                <!-- Logo -->
                <a class="navbar-brand mx-2" href="#">
                    <img src="../assets/pics/logo.png" style="height: 2rem" alt="ProInspector" loading="lazy" class="cursor-pointer" @click="goMainPage()"/>
                </a>
                
                <!-- connection icons -->
                <div class="px-0 mx-0 mt-1" style="display: flex; flex: 1 1 auto; align-self: stretch;">
                    <i v-if="servConnected !== null" :style="wsConnectedColor" class="bi bi-rss-fill" style="font-size: 1.2rem; width: 1.2rem;"></i>
                    <i v-if="devConnected !== null" class="bi bi-device-ssd-fill" :style="wrtcConnectedColor" style=" font-size: 1.2rem; width: 1rem;"></i>                    
                </div>                     
                <!-- Dev Name -->
                <div v-if="devName" class="flex flex-auto text-xl px-2 self-stretch pt-2">{{devName}}</div>
                <!-- Info -->
                <div  v-if="showInfo != ''" class="px-2 pt-2" v-tooltip="{displayArrow: true, theme: {placement: 'bottom'}, text: showInfo}"><BIconInfoCircle /></div>

                <div v-if="selectOptions" class="px-2 flex" :title="selectPopUp">
                    <p class="px-2">{{selectTitle}}</p>
                    <select name="select" v-model="optionSelected" class='select text-gray-800' @change="(e)=>{onOptionSelected(e.target.value)}">
                        <!-- <option value="-Select-">-Select-</option> -->
                        <option class="text-gray-800" v-for="option in selectOptions" :key="option.id" :value="option.id">{{option.name}}</option>
                    </select>
                </div>                               

                <div v-if="extraButtonText" class="d-flex align-items-center px-2">
                    <button type="button" @click="onExtraButtonTextClick" class="btn btn-primary me-3" :style="{'background-color': extraButtonColor}">
                    {{extraButtonText}}
                    </button>
                </div>

                <div v-if="showDatePicker" class="px-2" title="Select the date and time to start device image history">
                    <Datepicker v-model="date" @update:modelValue="onNedDate" enableSeconds ></Datepicker>
                </div>

                <div v-if="showRefreshButton" class="d-flex align-items-center" role="status">
                    <i class="bi bi-arrow-counterclockwise px-2 cursor-pointer" @click="refresh" title="Refresh"></i>
                </div>           

                <div v-if="showSpinner" class="spinner-border text-warning" role="status">
                    <span class="sr-only"></span>
                </div>
                
                <div v-if="showSubscribeButton" class="d-flex align-items-center">
                    <button type="button" @click="subscribe" v-tooltip="'Subscribe to event notifications'" class="btn btn-primary me-3">
                    Subscribe
                    </button>
                </div>

                <div v-if="showUnSubscribeButton" v-tooltip="'Unsubscribe from event notifications'" class="d-flex align-items-center px-2">
                    <button type="button" @click="unsubscribe" class="btn btn-primary me-3">
                    Unsubscribe
                    </button>
                </div>      

                <i v-if="showBackButton" class="bi bi-arrow-left px-2 cursor-pointer" @click="$router.back()" title="Go Back"></i>   

                <!-- User Name --> 
                <div v-if="loggedIn" class="d-flex align-items-center hover:text-blue-500 relative px-3">
                    <p class="cursor-pointer" @click.stop="showUserMenu=true" :title="customer">{{user}}</p>
                    <!-- <p class="cursor-pointer" @click.stop="showUserMenu=true">impresor1@imprenta.com</p> -->
                    <div v-if="showUserMenu" id="myDropdown" class="dropdown-content">
                        <div class="py-2 px-2 cursor-pointer" @click="logOut" title="Log Out">
                            <i class="bi bi-box-arrow-left px-2 "></i>
                            <span>Log Out</span>
                        </div>
                    </div>
                </div>

            
                <!-- Login -->
                <div v-if="!loggedIn" class="d-flex align-items-center">
                    <i class="bi bi-box-arrow-in-right px-2 cursor-pointer" @click="logIn" title="Log In"></i>
                </div>
            </div>
        </nav>
    </div>  
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { Options, Vue } from 'vue-class-component';
import { BIconInfoCircle } from 'bootstrap-icons-vue';
import store from '@/store/index.js';

@Options({
    components: {
        Datepicker,
        BIconInfoCircle
    },
    data: function(){
        return {
            loggedIn: store.state.login.loggedIn,
            user: store.state.login.email,
            customer: store.state.login.authorizedCustomer,
            date: new Date(),
            optionSelected: null,
            showUserMenu: false,
        }
    },
    props: {
        showBackButton: {type: Boolean, default: false},
        showRefreshButton: {type: Boolean, default: false},
        devName: {type: String, default: null},
        showSpinner: {type: Boolean, default: false},
        servConnected: {type: Boolean, default: null},
        devConnected: {type: Boolean, default: null},
        selectTitle: {type: String, default: null},
        selectPopUp: {type: String, default: null},
        selectOptions: {type: Array, default: null},
        showDatePicker: {type: Boolean, default: false},
        dateForDatePicker: {type: Date, default: new Date()},
        showSubscribeButton: {type: Boolean, default: false},
        showUnSubscribeButton: {type: Boolean, default: false},
        showInfo: {type: String, default: ''},
        extraButtonText: {type: String, default: null},
        extraButtonColor: {type: String, default: 'green'},
    },
    methods: {
        goMainPage() {
            console.log(`Going to main page: / from: ${this.$route.path}`);
            window.location.href = '/';            
        },
        logOut() {
            console.log(`Logging out`);
            this.$router.push({path: `/logout`});
        },
        logIn() {
            console.log(`Logging in`);
            this.$router.push({path: `/login`});
        },
        refresh() {
            console.log(`Refresh`);
            this.$emit("refreshButtonPress", null);
        },
        onOptionSelected(newOption) {
            console.log(`NavBar. New Option selected: ${newOption}`);
            this.$emit("newOptionSelected", newOption);
        },
        onNedDate(modelData) {
            console.log(`NavBar. New Date selected: ${modelData}`);
            this.$emit("newDateSelected", modelData);
        },
        subscribe(){
            console.log(`NavBar. Subscribe`);
            this.$emit("subscribe", null);
        },
        unsubscribe() {
            console.log(`NavBar. Unsubscribe`);
            this.$emit("unsubscribe", null);
        },
        onExtraButtonTextClick() {
            console.log(`NavBar. onExtraButtonTextClick`);
            this.$emit("onExtraButtonTextClick", null);
        }
    },
    computed: {
        wsConnectedColor() {
            if (this.servConnected) return { color: 'green'}
            else return { color: 'red'}
        },
        wrtcConnectedColor() {
            if (this.devConnected) return { color: 'green'}
            else return { color: 'red'}
        }

    },
    // Lifecycle hooks
    mounted() {
        //console.log('NavBar View Mounting'); 
        this.date = this.dateForDatePicker; // Copy prop to datepicker component

        window.onclick = () => {
            console.log(`Window Click`);
            this.showUserMenu = false;
        }
    },
    // unmounted() {
    //     console.log('NavBar View Unmounted')
    // },
    // beforeCreate() {console.log('NavBar View beforeCreate')},
    // beforeMount() {console.log('NavBar View beforeMount')},
    // created() {console.log('NavBar View created')},
    // beforeUpdate() {console.log('NavBar View beforeUpdate')},
    // updated() {console.log('NavBar View updated')},
    // activated() {console.log('NavBar View activated')},
    // deactivated() {console.log('NavBar View deactivated')},
    // beforeUnmount() {console.log('NavBar View beforeUnmount')},
    // errorCaptured() {console.log('NavBar View errorCaptured')},
    // renderTracked() {console.log('NavBar View renderTracked')},
    
})
export default class NavBar extends Vue {}
</script>
// Style only for this component/view
<style scoped>

.dropdown-content {
  position: absolute;
  top: 20px;
  right: 0px;
  background-color: #f1f1f1;
  min-width: 100px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}



    
</style>


