// Created by SPe on 02/08/22
// Page to edit a dataset

<template>
  <div class="dataseteditor" ref="divContainer">
    <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
    <div class="sticky-top" ref="navBar">        
        <NavBar 
            :showBackButton="showBackButton" 
            :showSpinner="showSpinner"            
            :showInfo="helpInfo" 
        />
        <div v-if="errorMsgPre !== ''" style="background-color: red;">
            <h1 style="font-size: 2em;">{{errorMsgPre}}</h1>
        </div>
        <!-- Image controls -->
        <div id="prompt" class="bg-gray-200">
            <i class="bi bi-funnel cursor-pointer border-r-2 border-gray-400 px-2"  title="Filter images" @click="()=>{if (!anyModalShowing) {filterModalShow =! filterModalShow;}}"></i>
            <i class="bi bi-sort-down cursor-pointer border-r-2 border-gray-400 px-2" title="Sort images" @click="()=>{if (!anyModalShowing) {sortModalShow = ! sortModalShow;}}"></i>
            <!-- <i class="bi bi-check2-square cursor-pointer border-r-2 border-gray-400 px-2" title="Select all images" @click="()=>{if (!anyModalShowing) {selectAll();}}"></i> -->
            <i class="bi bi-check2-square cursor-pointer border-r-2 border-gray-400 px-2" title="Select images" @click="()=>{if (!anyModalShowing) {selectImagesModalShow = ! selectImagesModalShow;}}"></i>
            <img class="icon h-4 inline cursor-pointer border-r-2 border-gray-400 px-2" :src="require('@/assets/pics/copy.png')" title="Copy/Move selected images" @click="onCopyClick()" />
            <i class="bi bi-trash cursor-pointer border-r-2 border-gray-400 px-2" title="Remove selected images" @click="()=>{if (!anyModalShowing) {removeImages();}}"></i>
            <i class="bi bi-cloud-upload cursor-pointer border-r-2 border-gray-400 px-2" title="Upload images from disk" @click="()=>{if (!anyModalShowing) {uploadModalShow = ! uploadModalShow;}}"></i>
            <i class="bi bi-cloud-download cursor-pointer border-r-2 border-gray-400 px-2" title="Download images to disk" @click="()=>{if (!anyModalShowing) {downloadModalShow = ! downloadModalShow;}}"></i>
            <i class="bi bi-arrow-clockwise cursor-pointer border-r-2 border-gray-400 px-2" title="Refresh images" @click="()=>{if (!anyModalShowing) {refreshDatasetsData(); refreshImages();}}"></i>
            <i class="bi bi-info-circle px-2" :title="infoTooltip"></i>
            <p>Dataset: {{datasetName}} - Num. Images: Sel.: {{numSelectedImgs}} - Filt.:{{numImagesFiltered}} - Total:{{numImagesinDataset}}</p>
        </div>

    </div>  
    <!-- Image container -->
    <div>            
        <div v-if="imagesToShow.length && !redrawing" ref="mainScroller" :style="{width: camRowWidth + 'px'}">
        <!-- <p>{{imagesToShow}}</p> -->
            <RecycleScroller
                class="scroller"
                :items="camRowList"
                :item-size="imgHeight"
                key-field="id"
                :pageMode="true"            
                :buffer="500"
                :emitUpdate="true"
                @update="onScrollerUpdate"     
            >
                <template v-slot="{ item }">
                    <div class="cam_row">
                        <!-- <p v-for="(n, col) in numColumns " :key="col">{{item[`${col}`]}}</p> -->
                        <InferImageML v-for="(n, col) in numColumns " :key="col"
                            :imgObj = "item[col]"
                            :imgObjChanged = "item[col].chng"
                            :devName = "item[col].dn"
                            :camPos = "item[col].ps"
                            :imgHeight = "imgHeight"
                            :imgSrc = "item[col].url"
                            :decryptPswd = "decryptionPassword"
                            :imgFileKey = "item[col].fk"
                            :imgTimestamp = "getTimeIfExist(item[col].dt)"
                            :predictLabels = "item[col].pls"
                            :predictBBoxes = "item[col].pbbs"
                            :assignLabels = "item[col].asls"
                            :assignBBoxes = "item[col].asbbs"
                            :allLabels = "defects"
                            :labelsWithDetection = "defectsWithDetection"
                            :allPredictions = "item[col].ap"
                            :imgMetaData = "item[col].md"
                            :rotate180 = "true"
                            :hideImg = "item[col].hide"
                            :showSelection = "true"
                            :selected = "item[col].selected"
                            :showBBoxButtons = "true"
                            :datasetIdSelected = "datasetId"
                            :outerBorderColor = "imageOuterBorderColor(item, col)"
                            :rowNumber = "item.id"
                            :colNumber = "col"
                            @imgClicked="onCamImgClick(item, col)"
                            @onImgLoaded = "imgData => { onImgLoaded(item[col], imgData)}"
                            @onAnnotateImage = "onAnnotateImage"
                            @onSelection = "onSelection"
                            @onImgExpired = "onImgExpired"
                        />
                    </div>
                </template>               
            </RecycleScroller>
        </div>

        <div v-if="errorMsgPost !== ''" style="background-color: red;">
            <h1 style="font-size: 2em;">{{errorMsgPost}}</h1>
        </div>
        <!-- Footernbar -->
        <Footer />
    </div>
    <!-- Filter Modal Window -->
    <Modal
        v-model="filterModalShow"
        ref="modal"
    >
        <div class="filterModalContent">
            <h1>Filter</h1>
            <h2>Asigned Label</h2>
            <ul style="list-style-type:none">
                <label><input type="checkbox" v-model="filter.assgnLabel.$all" @click="onFilterAssgnLabelClk($event, '$all')" ><b>Toggle all</b></label><br>
                <li v-for="defect in defects" :key="defect" >
                    <label><input type="checkbox" v-model="filter.assgnLabel[defect]" @click="onFilterAssgnLabelClk($event, defect)">{{defect}}</label><br>
                </li>
                <li><br></li>
                <li>
                    <label><input type="checkbox" v-model="filter.assgnLabel.$unbboxed" @click="onFilterAssgnLabelClk($event, '$unbboxed')" >Un-bounding Boxed</label><br>
                </li>
                <li>
                    <label><input type="checkbox" v-model="filter.assgnLabel.$unlabeled" @click="onFilterAssgnLabelClk($event, '$unlabeled')" >Un-labeled</label><br>
                </li>
                <li>
                    <label><input type="checkbox" v-model="filter.assgnLabel.$multilabeled" @click="onFilterAssgnLabelClk($event, '$multilabeled')" >Multiple labels</label><br>
                </li>
            </ul>
            <h2>Inferred Label</h2>
            <ul style="list-style-type:none">
                <div>
                    <h4>Predefined Filters:</h4>
                    <select name="select" v-model="filter.inferLabel.$predefinedSelected" class='select border-2 border-gray-800 rounded-md' @change="(e)=>{onFilterInferSelected(e.target.value)}">                        
                        <option v-for="option in filter.inferLabel.$predefinedOptions" :key="option.id" :value="option.id">{{option.name}}</option>
                    </select>
                </div>
                <div v-if="filter.inferLabel.$predefinedSelected == 'selectable'">
                    <br>
                    <table >
                        <tr>
                            <td><label><input type="checkbox" v-model="filter.inferLabel.$all" @click="onFilterInferLabelClk($event, '$all', null)" ><b>Toggle all</b></label></td>
                            <td><label><input type="checkbox" v-model="filter.inferLabel.$allSF" @click="onFilterInferLabelClk($event, '$allSF', null)" >{{'No'}}</label></td>
                            <td><label><input type="checkbox" v-model="filter.inferLabel.$allDF" @click="onFilterInferLabelClk($event, '$allDF', null)" >{{'~No'}}</label></td>
                            <td><label><input type="checkbox" v-model="filter.inferLabel.$allDT" @click="onFilterInferLabelClk($event, '$allDT', null)" >{{'~Yes'}}</label></td>
                            <td><label><input type="checkbox" v-model="filter.inferLabel.$allST" @click="onFilterInferLabelClk($event, '$allST', null)" >{{'Yes'}}</label></td>
                        </tr>
                        <tr v-for="defect in defects" :key="defect">
                            <td><label><input type="checkbox" v-model="filter.inferLabel[defect].$all" @click="onFilterInferLabelClk($event, defect, '$all')" ><b>{{defect}}</b></label></td>
                            <td><label><input type="checkbox" v-model="filter.inferLabel[defect].SF" @click="onFilterInferLabelClk($event, defect, 'SF')" ></label></td>
                            <td><label><input type="checkbox" v-model="filter.inferLabel[defect].DF" @click="onFilterInferLabelClk($event, defect, 'DF')" ></label></td>
                            <td><label><input type="checkbox" v-model="filter.inferLabel[defect].DT" @click="onFilterInferLabelClk($event, defect, 'DT')" ></label></td>
                            <td><label><input type="checkbox" v-model="filter.inferLabel[defect].ST" @click="onFilterInferLabelClk($event, defect, 'ST')" ></label></td>
                        </tr>
                    </table>
                    <!-- <label><input type="checkbox" v-model="filter.inferLabel.$all" @click="onFilterInferLabelClk($event, '$all')" ><b>Toggle all</b></label><br>
                    <li v-for="defect in defects" :key="defect" >
                        <label><input type="checkbox" v-model="filter.inferLabel[defect]" @click="onFilterInferLabelClk($event, defect)">{{defect}}</label><br>
                    </li> -->
                    <li><br></li>
                    <li>
                        <label><input type="checkbox" v-model="filter.inferLabel.$singlelabeled" @click="onFilterInferLabelClk($event, '$singlelabeled', null)" >Single labeled</label><br>
                    </li>
                    <li>
                        <label><input type="checkbox" v-model="filter.inferLabel.$multilabeled" @click="onFilterInferLabelClk($event, '$multilabeled', null)" >Multiple labels</label><br>
                    </li>
                </div>
            </ul>
            <h2>Inference Accuracy</h2>
            <ul style="list-style-type:none">
                <label><input type="checkbox" v-model="filter.accuracy.$all" @click="onFilterAccuracyClk($event, '$all')" ><b>Toggle all</b></label><br>
                <li>
                    <label><input type="checkbox" v-model="filter.accuracy.correctinference" @click="onFilterAccuracyClk($event, 'correctinference')" >Correct Inference</label><br>
                </li>
                <li>
                    <label><input type="checkbox" v-model="filter.accuracy.incorrectinference" @click="onFilterAccuracyClk($event, 'incorrectinference')" >Inorrect Inference</label><br>
                </li>
            </ul>
            <h2>Device</h2>
            <ul style="list-style-type:none">
                <label><input type="checkbox" v-model="filter.devName.$all" @click="onFilterDeviceClk($event, '$all')" ><b>Toggle all</b></label><br>
                <li v-for="devName in imgDevNames" :key="devName" >
                    <label><input type="checkbox" v-model="filter.devName[devName]" @click="onFilterDeviceClk($event, devName)">{{devName}}</label><br>
                </li>
            </ul>
            <!-- Apply/Cancel Buttons -->
            <div class="flow-root mt-4">
                <button class="float-left text-center bg-green-500 px-3 mx-4" @click="onApplyFilter">Apply</button>
                <button class="float-right text-center bg-red-500 px-3 mx-4" @click="onCancelFilter">Cancel</button>
            </div> 
        </div>        
    </Modal>
    <!-- Sort Modal Window -->
    <Modal
        v-model="sortModalShow"
        ref="modal"
    >
        <div class="sortModalContent">
            <h1>Sort</h1>
            <h2>Capture Time</h2>
            <ul style="list-style-type:none">
                <label><input type="checkbox" v-model="sort.time.asc" @click="onSortClk($event, 'time', 'asc')" ><b>Ascending</b></label><br>
                <label><input type="checkbox" v-model="sort.time.dsc" @click="onSortClk($event, 'time', 'dsc')" ><b>Descending</b></label><br>
                <li><br></li>
            </ul>
            <h2>Asigned Label</h2>
            <ul style="list-style-type:none">
                <label><input type="checkbox" v-model="sort.assgnLabel.asc" @click="onSortClk($event, 'assgnLabel', 'asc')" ><b>Ascending</b></label><br>
                <label><input type="checkbox" v-model="sort.assgnLabel.dsc" @click="onSortClk($event, 'assgnLabel', 'dsc')" ><b>Descending</b></label><br>
                <li><br></li>
            </ul>
            <h2>Inferred Label</h2>
            <ul style="list-style-type:none">
                <label><input type="checkbox" v-model="sort.inferLabel.asc" @click="onSortClk($event, 'inferLabel', 'asc')" ><b>Ascending</b></label><br>
                <label><input type="checkbox" v-model="sort.inferLabel.dsc" @click="onSortClk($event, 'inferLabel', 'dsc')" ><b>Descending</b></label><br>
                <li><br></li>
            </ul>
            <h2>Devices</h2>
            <ul style="list-style-type:none">
                <label><input type="checkbox" v-model="sort.devName.asc" @click="onSortClk($event, 'devName', 'asc')" ><b>Ascending</b></label><br>
                <label><input type="checkbox" v-model="sort.devName.dsc" @click="onSortClk($event, 'devName', 'dsc')" ><b>Descending</b></label><br>
                <li><br></li>
            </ul>
           <!-- Apply/Cancel Buttons -->
            <div class="flow-root mt-4">
                <button class="float-left text-center bg-green-500 px-3 mx-4" @click="onApplySort">Apply</button>
                <button class="float-right text-center bg-red-500 px-3 mx-4" @click="onCancelSort">Cancel</button>
            </div>
        </div>        
    </Modal>

    <!-- Select images Windows -->
    <Modal
        v-model="selectImagesModalShow"
        ref="modal"
    >
        <div class="selectImagesModalContent">
            <h1>Select Images</h1>
            <h2>Num Images: {{numImagesFiltered}}</h2>
            <h2>Select Images %</h2>
            <select class="select border-2 border-gray-800 rounded-md" v-model="selectImagesPercent">
                <option v-for="option in selectImagesPercentOptions" :key="option" :value="option">{{option + '% ( ' + Math.round(numImagesFiltered * option / 100) + ' )'}}</option>
            </select>
            <div class="flow-root mt-4">
                <button class="float-left text-center bg-green-500 px-2 mx-4" @click="selectImages">Select</button>
                <button class="float-left text-center bg-blue-500 px-2 mx-4" @click="unselectAllImages">Unselect all</button>
                <button class="float-right text-center bg-red-500 px-2 mx-2" @click="onCancelSelect">Cancel</button>
            </div>
        </div>
    </Modal>

    <!-- Copy Images Modal Window -->
    <Modal
        v-model="copyModalShow"
        ref="modal"
    >
        <div class="copyModalContent">
            <h1 class="mb-2">Copy/Move Images to other Dataset</h1>
            <p class="text-xs">If Move is selected, images will be delated from this Dataset.</p>  
            <br>
            <p>You will {{copyoperation}} {{numSelectedImgs}} image(s).</p>                     
            
            <!-- Select either all images in same folder or folder per class -->        
            <h2>Select operation</h2>           
            <input class="cursor-pointer"  type="radio" name="copyoperation" v-model="copyoperation" :value="'Copy'" />
            <label class="pl-2">Copy</label> 
            <hr class="opacity-0">
            <input class="cursor-pointer" type="radio" name="copyoperation" v-model="copyoperation" :value="'Move'" />
            <label class="pl-2">Move</label>         
             <!-- Select the destination dataset  -->
             <h2>Select destination Dataset</h2> 
             <select v-model="copyImgsDstId" class="brder-2 border-gray-800">
                <option disabled value="">Please select one</option>
                <option v-for="ds in copyDatasetList" :key="ds" :value="ds.DatasetId">{{ds.DatasetName}}</option>
             </select>

            <!-- Copy/Cancel Buttons -->
             <div class="flow-root mt-4">
                <button :disabled="copyImgsDstId==''" class="float-left text-center bg-green-500 px-3 mx-4 disabled:opacity-20 cursor-not-allowed" @click="copyImages">Copy/Move</button>
                <button class="float-right text-center bg-red-500 px-3 mx-4" @click="copyModalShow=false">Cancel</button>
            </div>  
        </div>        
    </Modal>

    <!-- Upload Images Modal Window -->
    <Modal
        v-model="uploadModalShow"
        ref="modal"
    >
        <div class="uploadModalContent">
            <h1>Uplaod Images from disk</h1>
            <small>Select the labels to assig to images uploaded.</small><br>
            <small>The images should have same size as the images already in dataset.</small> 
            <br>
            <h2>Source type</h2>
            <input class="cursor-pointer"  type="radio" name="upLdSource" v-model="upLdSource" :value="'filesNLabel'" />
            <label class="pl-2">Files and label</label> 
            <hr class="opacity-0">
            <input class="cursor-pointer" type="radio" name="upLdSource" v-model="upLdSource" :value="'zip'" />
            <label class="pl-2">Zip file with csv</label>        
            <div v-if="upLdSource=='filesNLabel'">
                <h2>Assign Labels</h2>
                <ul style="list-style-type:none">
                    <li v-for="defect in defects" :key="defect" >
                        <label><input type="checkbox" v-model="upload.assign[defect]" @click="onUploadLabelClk($event, defect)">{{defect}}</label><br>
                    </li>
                    <li><br></li>
                    <label for="files">Select files to uplload:</label>
                    <input type="file" id="files" name="files" multiple @change="onUploadChange" accept="image/*"><br><br>
                </ul>
                <!-- Upload/Cancel Buttons -->
                <div class="flow-root mt-4">
                    <button class="float-left text-center bg-green-500 px-3 mx-4" @click="uploadFiles">Upload</button>
                    <button class="float-right text-center bg-red-500 px-3 mx-4" @click="uploadModalShow=false">Cancel</button>
                </div>  
            </div>
            <div v-if="upLdSource=='zip'">
                <h2>Zip file</h2>
                <ul style="list-style-type:none">
                    <label for="files">Select zip file to uplload:</label>
                    <input type="file" id="files" name="files" @change="onUploadChange" accept=".zip,.rar,.7zip"><br><br>
                </ul>
                <!-- Upload/Cancel Buttons -->
                <div class="flow-root mt-4">
                    <button class="float-left text-center bg-green-500 px-3 mx-4" @click="uploadFiles">Upload</button>
                    <button class="float-right text-center bg-red-500 px-3 mx-4" @click="uploadModalShow=false">Cancel</button>
                </div>  
            </div>
        </div>        
    </Modal>

    <!-- Download Images Modal Window -->
    <Modal
        v-model="downloadModalShow"
        ref="modal"
    >
        <div class="downloadModalContent">
            <h1 class="mb-2">Download Images from disk</h1>
            <p class="text-xs">Images will be downloaded in a Zip file.</p>
            <p class="text-xs">A csv file will be also included with assigned and infered label probabilities</p>
            <p class="text-xs">Due to browser limitation, very big dataset (> 5000 images) can fail to generate a zip file.</p>
            
            
            <!-- Select either all images in same folder or folder per class -->        
            <h2>Select folder structure</h2>           
            <input class="cursor-pointer"  type="radio" name="dwldZipStructure" v-model="dwldZipStructure" :value="'structuredZip'" />
            <label class="pl-2">One subfolder per label</label> 
            <hr class="opacity-0">
            <input class="cursor-pointer" type="radio" name="dwldZipStructure" v-model="dwldZipStructure" :value="'flatZip'" />
            <label class="pl-2">All images in one folder</label>          
            <!-- Download/Cancel Buttons -->
             <div class="flow-root mt-4">
                <button class="float-left text-center bg-green-500 px-3 mx-4" @click="downloadFiles">Download</button>
                <button class="float-right text-center bg-red-500 px-3 mx-4" @click="downloadModalShow=false">Cancel</button>
            </div>  
        </div>        
    </Modal>

    <!--Modal Overlay-->
    <div
        class="fixed hidden inset-0 bg-gray-600 bg-opacity-50 h-full w-full"                
        id="ImgModal"
    >
        <div v-if="imgModalIsShow && rowIdSelected != null && colIdSelected != null" class="relative h-full w-full text-white flex items-center justify-center"> 
            <!-- Zoom Pan content  -->
            <div id="zoompan"> 
                <InferImageML
                    :imgObj = "camRowList[rowIdSelected][colIdSelected]"
                    :devName = "camRowList[rowIdSelected][colIdSelected].dn"
                    :camPos = "camRowList[rowIdSelected][colIdSelected].ps"
                    :imgHeight = "imgHeightInModal"
                    :imgSrc = "camRowList[rowIdSelected][colIdSelected].url"
                    :decryptPswd = "decryptionPassword"
                    :imgFileKey = "camRowList[rowIdSelected][colIdSelected].fk"
                    :imgTimestamp = "getTimeIfExist(camRowList[rowIdSelected][colIdSelected].dt)"
                    :predictLabels = "camRowList[rowIdSelected][colIdSelected].pls"
                    :predictBBoxes = "camRowList[rowIdSelected][colIdSelected].pbbs"
                    :assignLabels = "camRowList[rowIdSelected][colIdSelected].asls"
                    :assignBBoxes = "camRowList[rowIdSelected][colIdSelected].asbbs"
                    :allLabels = "defects"
                    :labelsWithDetection = "defectsWithDetection"
                    :allPredictions = "camRowList[rowIdSelected][colIdSelected].ap"
                    :imgMetaData = "camRowList[rowIdSelected][colIdSelected].md"
                    :rotate180 = "rotate180"
                    :showSelection = "true"
                    :selected = "camRowList[rowIdSelected][colIdSelected].selected"
                    :showBBoxButtons = "true"
                    :datasetIdSelected = "datasetId"
                    :showComment = "true"
                    @onImgLoaded = "imgData => { onImgLoaded(camRowList[rowIdSelected][colIdSelected], imgData)}"
                    @onAnnotateImage = "onAnnotateImage"
                    @onSetComment = "onSetComment"
                    @onImgExpired = "onImgExpired"
                /> 
            </div>
            <div class="center-down">
                <button class="bg-red-500 bg-opacity-50 rounded-full w-10 h-10 text-red-500"
                    title="Close [X]"
                    @click="closeImgModal">X</button>
            </div>
        </div> 
    </div>

    <!-- Modal Window for Entering Password Form -->
      <Modal
          v-model="enterPasswwordModalShow"
          ref="modal"
      >
        <EnterPasswordForm entityType='Project' :entityName="projectName" @newPasswordSubmitted="newPasswordSubmitted"></EnterPasswordForm>        
      </Modal>
  </div>  
</template>

<script>
//import { EXIF } from 'exif-js';
import { RecycleScroller } from 'vue3-virtual-scroller';
import 'vue3-virtual-scroller/dist/vue3-virtual-scroller.css'
import { Options, Vue } from 'vue-class-component'; 
//import { sendMessageUnified } from '@/library/client-unified-send'
import { getDatasetNameFromDatasetId, getProjectIdFromDatasetId, getDateFromLongStr, arraysEqual, getDateAsISOStrWoMs, getProjectNameFromProjectId, parseCSV, getTimeIfExist} from '@/library/utils.js';
import EnterPasswordForm from '@/components/EnterPasswordForm.vue';
import store from '@/store/index.js';
import auth from '@/library/auth'; 
//import store from '@/store/index.js';
//import { sendWsMessage } from '@/library/websocket'
import LoadingOverlay from '@/components/LoadingOverlay.vue';
//import { connect as connectWrtc, close as closeWrtc } from '@/library/webrtc'
//import { sendMessageUnified } from '@/library/client-unified-send'
//import { registerUnifiedMessageCallBack } from '@/library/client-unified-recei.ve'
import { mapGetters } from 'vuex'
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import  WZoom from 'vanilla-js-wheel-zoom'
import { connect as connectWs, removeOnConnectedCB } from '@/library/websocket.js';
import { getAllImagesDataFromDataset, annotateImage, removeImagesFromDataset, copyImagesBtwDatasets, addImageToDataset, getDatasetsOfProject, setImageComment } from '@/library/http-api-client';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import Button from 'primevue/button';
import InferImageML from '@/components/InferImageML.vue';
import appConfig from '@/config.js';

// Constants
//const NUM_IMAGES_TO_DOWNLOAD = 1000; // Numer of images to download each time
const CSV_SEPARATOR = ';'; // CSV_SEPARATOR for csv file = ';' to avoid problems with ',' in json fields

@Options({
    components: {
        LoadingOverlay,
        NavBar,
        Footer,
        Button,
        EnterPasswordForm,
        RecycleScroller,
        InferImageML,
    },
    data: function(){
        return {
            errorMsgPre: '',
            errorMsgPost: '',
            loadingOverlay: {show: false, text: 'Loading'},
            showBackButton: false,
            showRefreshButton: false,
            showSpinner: false,
                       
            customer: this.$route.params.Customer,
            datasetId: this.$route.params.DatasetId,
            datasetName: null,
            projectId: null,
                       
            gettingData: false,
            justGotData: false,
            lastScrollEndIndex: 0,

            redrawing: false,
                        
            // Viewport size
            windowWidth: null,
            windowHeight: null,
            // Original Image Size
            imgOriginalWidth: 200, // Image natural width
            imgOriginalHeight: 400, // Image natural height
            // Image size so accomadate a row in window
            imgWidth: 200 / 10, // Initial value
            imgHeight: 400 / 10, // Initial value
            rotate180: true,
            imgScale: 1, // Scale to rescale the image when zoom in

            camRowWidth: null, // Cam row width recalculated according to changes

            numColumns: 4, // Number of columns

            // 
            s3Client: auth.getS3Client(appConfig.ProjectsS3Bucket), // S3 client
            camPositions: [], // Sorted list of camera positions
            imgPerKey: {}, // Img object per file key
            camHeads: {}, // Dict with key camera position and value the head index for that camera. First camera row empty for this camera
            camRowHead: 0, // index of camera row head. First completely empty camera row
            camRowList: [], // List of camera rows

            allSelected: false,
            imagesToShow: [], // Images to show

            imagesFiltered: [], // Images filtered

            helpInfo: '',

            imgDevNames: [], // List of dev names 
            filter: {assgnLabel: {}, inferLabel: {}, accuracy: {}, devName: {}},
            lastAppliedFilter: {}, // Store last applied filter to be able to cancel
            sort: {time: {asc: true, dsc: false}, assgnLabel: {asc: false, dsc: false}, inferLabel: {asc: false, dsc: false}, devName: {asc: false, dsc: false}},
            lastAppliedSort: {time: {asc: true, dsc: false}, assgnLabel: {asc: false, dsc: false}, inferLabel: {asc: false, dsc: false}, devName: {asc: false, dsc: false}}, // Store last applied sort to be able to cancel
            
            /// Select images
            selectImagesModalShow: false,
            selectImagesPercent: 100,
            selectImagesPercentOptions: [100, 95, 90, 85, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 5],
            
            upload: {assign: {}},
            filesToUpload: [], // List of files to upload

            cleanFiltersSorts: false, // Flag to indicate tha filters and sort should be clean

            numImagesinDataset: 0, // Number of images in dataset
            numImagesFiltered: 0,

            filterModalShow: false,
            sortModalShow: false,
            uploadModalShow: false,  
            downloadModalShow: false, 
            copyModalShow: false,     
            numSelectedImgs: 0,
            
            upLdSource: 'filesNLabel',
            dwldZipStructure: 'structuredZip',
            copyoperation: 'Copy',
            copyImgsDstId: '', // copy/move images target dataset id
            
            desiredScrollY: -1,
            updatingScrolling: false,

            ///// Image Modal
            rowIdSelected: -1, // Row selected to show in modal window
            colIdSelected: -1, // Column selected to show in modal window
            imgModalIsShow: false,
            imgWidthtInModal: 400,
            imgHeightInModal: 400,
            initialScrollYModal: 0, // window scrollY when open modal
            initialRowSelectedModal: 0, // Initial row selected when open modal

            modalWZoom: null, // WZoom object in modal window

            // Encryption
            enterPasswwordModalShow: false,
            anyImgIsEncrypted: false, // Whether is detected that some images are encrypted

            handleKeyDown: null, // Function to handle key down events

            appConfig: appConfig, // General config

            auth: auth, // Auth object

            redirectinToLogin: false, // Flag to indicate that user is redirected to login page
        }
    },
    props: [],
    methods: {
        getTimeIfExist: getTimeIfExist,
        imageOuterBorderColor(item, col) {
            // console.log(`imageBorderColor. item: ${JSON.stringify(item)}, col: ${col}`);
            return item.id==this.rowIdSelected && col==this.colIdSelected? 'blue' : 'black';
        },
        // Extract the timestamp and camera position from filepath
        extractDateAndPosition(filePath) {
            //console.log(`KEY: ${filePath}`);
            try {
                //console.log(`SSSSSS: ${JSON.stringify(filePath.split('@'))}`)
                let dateString = null;
                let posStr = null;
                if (filePath.includes('@')) {
                    let after = filePath.split('@')[1];
                    
                    if (after && after.includes('C')) {  
                        dateString = after.split('C')[0];
                        posStr = after.split('C')[1].split('.j')[0];
                    } else if (after && after.includes('.')) {
                        dateString = after.split('.')[0];
                        posStr = filePath.split('@')[0][-1];
                    }
                    if (dateString) {
                        //console.log(`dateString: ${dateString}`)
                        let reggie = /(\d{2})-(\d{2})-(\d{2})T(\d{2})-(\d{2})-(\d{2})-(\d{3})/;
                        let dateArray = reggie.exec(dateString); 
                        let dateObject = new Date(
                            (2000+(+dateArray[1])), // Year is only 2 digits
                            (+dateArray[2])-1, // Careful, month starts at 0!
                            (+dateArray[3]),
                            (+dateArray[4]),
                            (+dateArray[5]),
                            (+dateArray[6]),
                            (+dateArray[7])
                        );
                        // Camera position
                        
                        return [dateString, dateObject, +posStr]
                    } else return [null, null, null];      
                } else return [null, null, null];
            } catch (error){
                console.log(`extractDateAndPosition exception with filePath: ${filePath}. Msg: ${error}`);
            }   return [null, null, null];
        },
        async onImgLoaded(imgObj, imgData) { // (imgObj, imgData)
            // console.log(`onImgLoaded. imgData: ${JSON.stringify(imgData)}`);
            // Update original size if changed
            const imgOriginalWidth = imgData.imgOriginalWidth;
            const imgOriginalHeight = imgData.imgOriginalHeight;
            if (imgOriginalWidth !== this.imgOriginalWidth || imgOriginalHeight !== this.imgOriginalHeight) {
                console.log(`onImgLoaded. imgOriginalWidth changed from ${this.imgOriginalWidth} to ${imgOriginalWidth} and imgOriginalHeight changed from ${this.imgOriginalHeight} to ${imgOriginalHeight}`);
                this.imgOriginalWidth = imgOriginalWidth;
                this.imgOriginalHeight = imgOriginalHeight;
                this.resizeImage();
            }
        },
        onImgExpired(imgObj) {
            console.log(`onImgExpired. imgObj: ${JSON.stringify(imgObj)}`);
            if (!this.redirectinToLogin) {
                // Imgage can not be read from S3 --> inform user that session has expired and go to login
                alert(`Session has expired. Please login again`);
                // Redirect to login page
                this.$router.push('/login');
                this.redirectinToLogin = true;
            } else {
                console.error(`onImgExpired. Redirected to login page. No more alerts`);
            }
        },
        onAnnotateImage(imgObj, imgMetaData, fileKey, pos, assgnLabels, assignBBoxes) {

            // Check if it is readonly
            if (imgObj.ReadOnly) {
                console.error(`onAnnotateImage. This dataset is Read Only`);  
                alert(`This dataset is in read only mode. You can not change labels`);
                return;
            }

            // Save annotations to image object
            imgObj.asls = assgnLabels;            
            imgObj.asbbs = assignBBoxes;

            imgObj.md = imgMetaData;       

            imgObj.chng = !imgObj.chng; // Toggle change flag to force re-render
            
            const originator = 'DatasetEditor';
            const userId = this.userId;
            const projectId = imgMetaData['ProjectId'];
            const datasetId = this.datasetId;
            const saveInStore = true;            
            
            annotateImage(imgMetaData, fileKey, pos, assgnLabels, assignBBoxes, originator, userId, projectId, datasetId, saveInStore);
        },
        onSetComment(imgObj, imgMetaData) {
            // Save comment to image object
            console.log(`imgMetaData: ${JSON.stringify(imgMetaData)}`)
            const imageId = imgMetaData.ImageId;
            const comment = imgMetaData.Comment
            imgObj.cm = comment;
            imgObj.chng = !imgObj.chng; // Toggle change flag to force re-render

            const userId = this.userId;
            const projectId = this.projectId;
            const datasetId = this.datasetId;
            const saveInHistory = false; // Do not save in history   

            setImageComment(userId, projectId, datasetId, imageId, comment, saveInHistory);
        },
        onWindowResize() {
            this.windowWidth = document.documentElement.scrollWidth;
            this.windowHeight = document.documentElement.scrollHeight;
            console.log(`onWindowResize. Width: ${this.windowWidth}, Height: ${this.windowHeight}`);
            this.resizeImage();
            if (this.modalWZoom && this.imgModalIsShow) this.modalWZoom.prepare();
        },
        resizeImage() {
            // Calculate the image size to accomodate in window
            this.imgWidth = this.imgScale * Math.round(this.windowWidth / this.numColumns) - 7; // WIdth to accomodate all images in a row. -7 for border. Dont know why 7 exactly
            this.imgHeight = this.imgWidth * this.imgOriginalHeight / this.imgOriginalWidth; // Height to keep aspect ratio
            console.log(`resizeImage. imgWidth: ${this.imgWidth}, imgHeight: ${this.imgHeight}`)
        },
        onScrollerUpdate(startIndex, endIndex) {
            console.log(`onScrollerUpdate. startIndex: ${startIndex}, endIndex: ${endIndex}, desiredScrollY: ${this.desiredScrollY}, Num Camera Rows: ${this.numCamRows}`);
            // Scroll to desired position
            if (this.desiredScrollY > 0) window.scrollTo({left: window.scrollX, top: this.desiredScrollY, behavior: 'instant'} );
            
            console.log(`Scroll: ${window.scrollY}, desiredScrollY: ${this.desiredScrollY}, window.innerHeight: ${window.innerHeight}`);
            if (Math.abs(this.desiredScrollY - window.scrollY) < window.innerHeight / 2) { // Reset if scroll already there
                console.log(`Reseting desiredScrollY`); 
                this.desiredScrollY = -1;
                this.updatingScrolling = false;
                setTimeout(() => { this.loadingOverlay = { show: false };}, 10);
            }
        },
        refreshDatasetsData(onDataReceivedCB=null) {
            // Get Datasets Data for the project
            getDatasetsOfProject(this.projectId, (datasetsDataResult) => {
                this.onDatasetsData(datasetsDataResult);
                // Call callback if defined
                if (onDataReceivedCB) onDataReceivedCB();
            });
        },
        onDatasetsData(datasetsDataResult) {
            console.log(`onDatasetsData: ${JSON.stringify(datasetsDataResult)}`);
        },
        getDataForDataset() {
            console.log(`getDataForDataset. Dataset: ${this.datasetId}`);
            getAllImagesDataFromDataset(this.userId, this.datasetId, this.onAllImgsData);
        },
        refreshImages() {
            console.log(`refreshImages. Dataset: ${this.datasetId}`);
            this.gettingData = true;
            this.loadingOverlay = {show: true, text: `Getting images`};            
            this.getDataForDataset();
        },
        // Called once all images data for dataset has been taken
        async onAllImgsData() {
            console.log(`onAllImgsData`);
            this.imagesToShow = [];
            let numImagesWithUrl = 0;
            let id = 1;
            console.log(`All images loaded. Num Images: ${this.allImagesOfDataset.length}`)
            for (let img of this.allImagesOfDataset) {
                // console.log(`IMG Data: ${JSON.stringify(img)}`)
                const fileName = img['FileName'];
                // const dateNpos = this.extractDateAndPosition(fileName);
                // const dateStr = dateNpos[0];
                // const date = dateNpos[1];
                // const pos = dateNpos[2];
                let dateStr = img.TimeStampStr;
                // Patch to be compatible with old images
                if (!dateStr && fileName.includes('img-')) {
                    dateStr = fileName.split('@')[1]
                }
                let date = null;
                if (dateStr) date = getDateFromLongStr(dateStr);
                const pos = img.CamPos;
                //console.log(`File Name: ${fileName}, dateStr: ${dateStr}, date: ${date}, pos: ${pos}`);
                const fileKey = `${appConfig.DstImgsBucketPrefix(this.customer, this.projectId, this.datasetId)}/${fileName}`; //`public/prj/${this.projectId}/dst/${this.datasetId}/img/${fileName}`;
                // Check if image is encrypted
                if (fileKey.endsWith('.enx')) { // Encrypted image
                    if (!this.anyImgIsEncrypted && !this.decryptionPassword) { // First time encrypted image found and not defined jet
                        // Remove overlay
                        this.loadingOverlay.show = false;
                        //  Show Enter Password Modal 
                        this.enterPasswwordModalShow = true;
                        // Wait until modal is closed
                        while (this.enterPasswwordModalShow) await new Promise(r => setTimeout(r, 100));
                        this.loadingOverlay.show = true;
                    }
                    this.anyImgIsEncrypted = true; // Check if file has encryption extension
                }
                let allPredictions = img['PredictAll'];
                let predictLabels = img['PredictLabels'];
                let predictBBoxes = img['PredictBBoxes'];
                // Calculate PredictLabels if not provided
                if (predictLabels === undefined || predictLabels === null) {
                    console.error(`Do not have predict labels for image: ${fileName}`)
                    if (allPredictions !== undefined && allPredictions !== null) {
                        predictLabels = [];
                        for (let label in allPredictions) {
                            if (allPredictions[label] >= 0.5) predictLabels.push(label);
                        }
                        img['PredictLabels'] = predictLabels;
                    } else {
                        console.error(`Do not have allPredictions for image: ${fileName}`)
                    }
                }
                let assignLabels = img['AssignLabels'];
                let assignBBoxes = img['AssignBBoxes'];
                
                const devName = img['DevName'];
                //console.log(`DevName: ${devName}`)
                if (devName && !this.imgDevNames.includes(devName)) this.imgDevNames.push(devName); // Add DevName to list
                // Default allPredictions if not provided
                if (!allPredictions) {
                    allPredictions = {};
                    for (let label of this.defects) {
                        allPredictions[label] = 0;
                    }
                }
                // Build image data object
                let imgData = {
                    id: id, md: img, chng: false, dn:devName, ps: pos,
                    dt: date, ds: dateStr, fk: fileKey, url: null,
                    asls: assignLabels, asbbs: assignBBoxes,
                    ap: allPredictions, pls: predictLabels,
                    pbbs: predictBBoxes, selected: false
                };
                // Add imgData to imagesToShow
                this.imagesToShow.push(imgData);
                id += 1;
                // Get signed URL asynchronously and add to imgData
                this.s3Client.getSignedUrl('getObject', {
                        Bucket: appConfig.ProjectsS3Bucket,
                        Key: fileKey,
                        Expires: 60 * 60 // 1 hour
                    }, (error, url) => {
                        if (error) {
                            console.error(`Error getting signed URL for file: ${fileKey}. Error: ${error.message}`);
                            numImagesWithUrl++;   
                        } else {
                            // console.log(`getSignedUrl URL: ${url}`);
                            // Update url in imgData
                            imgData.url = url;
                            // Update alse to img object
                            img['S3URL'] = url;
                            img['FileKey'] = fileKey;
                            imgData['PreSignExprTs'] = Date.now() + 60 * 60 * 1000; // 1 hour
                            numImagesWithUrl++;                        
                        }
                    }
                );
            }
            // Wait until all images are loaded
            console.log(`Waiting until all (${this.imagesToShow.length}) images has signed URL`);
            while (numImagesWithUrl < this.imagesToShow.length) await new Promise(r => setTimeout(r, 100));
            console.log(`All images has signed URL`);

            //console.log(`imagesToShow: ${JSON.stringify(this.imagesToShow)}`);
            console.log(`Num imagesToShow: ${this.imagesToShow.length}`);
            console.log(`defects: ${JSON.stringify(this.defects)}`);
            console.log(`defectsWithDetection: ${JSON.stringify(this.defectsWithDetection)}`);

            console.log(`cleanFiltersSorts: ${this.cleanFiltersSorts}`);
            if (this.cleanFiltersSorts) {
                this.cleanFiltersSorts = false;
                // Initialize filter
                this.initializeFilters();
            } else {
                this.redefineDevicesInFilter();
            }

            this.sortImages();
            this.loadingOverlay = {show: false}; 
            this.organizeInColumns();           
        },
        newPasswordSubmitted(data) {
            console.log(`newPasswordSubmitted: ${JSON.stringify(data)}`);
            this.enterPasswwordModalShow = false;
            store.commit('projects/setProjectPassword', {ProjectId: this.projectId, Password: data.Password});
        },
        sortImages() {
            function compare(a, b) {
                if (a > b) return -1;
                if (b > a) return 1;
                return 0;
            }
            console.log(`sortImages: ${JSON.stringify(this.sort)}`);
            if (this.sort.time.asc) {
                console.log(`sortImages time.asc`);
                //this.imagesToShow = this.imagesToShow.sort((a,b) => compare(b.md.TimeStampStr, a.md.TimeStampStr));
                this.imagesToShow = this.imagesToShow.sort((a,b) => compare(b.dt, a.dt));
            } else if (this.sort.time.dsc) {
                console.log(`sortImages time.dsc`);
                //this.imagesToShow = this.imagesToShow.sort((a,b) => compare(a.md.TimeStampStr, b.md.TimeStampStr));
                this.imagesToShow = this.imagesToShow.sort((a,b) => compare(a.dt, b.dt));
            } else if (this.sort.assgnLabel.asc) {
                console.log(`sortImages assgnLabel.dsc`);                
                for (let img of this.imagesToShow) {
                    img.md.AssignLabels.sort((a,b) => compare(b, a)); // Sort AssignLabels list                    
                    img['SortIndex'] = img.md.AssignLabels.join(''); // Join all AssignLabels toguether
                } 
                this.imagesToShow = this.imagesToShow.sort((a,b) => compare(b.SortIndex, a.SortIndex));
            } else if (this.sort.assgnLabel.dsc) {
                console.log(`sortImages assgnLabel.dsc`);                
                for (let img of this.imagesToShow) {                    
                    img.md.AssignLabels.sort((a,b) => compare(a, b)); // Sort AssignLabels list                    
                    img['SortIndex'] = img.md.AssignLabels.join(''); // Join all AssignLabels toguether
                } 
                this.imagesToShow = this.imagesToShow.sort((a,b) => compare(a.SortIndex, b.SortIndex));
            }  else if (this.sort.inferLabel.asc) {
                console.log(`sortImages inferLabel.asc`);                
                for (let img of this.imagesToShow) {
                    img.md.PredictLabels.sort((a,b) => compare(b, a)); // Sort PredictLabels list                    
                    img['SortIndex'] = img.md.PredictLabels.join(''); // Join all PredictLabels toguether
                } 
                this.imagesToShow = this.imagesToShow.sort((a,b) => compare(b.SortIndex, a.SortIndex));
            } else if (this.sort.inferLabel.dsc) {
                console.log(`sortImages inferLabel.dsc`);                
                for (let img of this.imagesToShow) {                    
                    img.md.PredictLabels.sort((a,b) => compare(a, b)); // Sort PredictLabels list                    
                    img['SortIndex'] = img.md.PredictLabels.join(''); // Join all PredictLabels toguether
                } 
                this.imagesToShow = this.imagesToShow.sort((a,b) => compare(a.SortIndex, b.SortIndex));
            }  else if (this.sort.devName.asc) {
                console.log(`sortImages devName.dsc`);                
                this.imagesToShow = this.imagesToShow.sort((a,b) => compare(b.md.devName, a.md.devName));
            } else if (this.sort.devName.dsc) {
                console.log(`sortImages devName.dsc`);                
                this.imagesToShow = this.imagesToShow.sort((a,b) => compare(a.md.devName, b.md.devName));
            }
            //console.log(`sortImages: ${JSON.stringify(this.imagesToShow)}`);
        },
        organizeInColumns(unselect) {
            console.log(`organizeInColumns`);
            this.numImagesinDataset = this.imagesToShow.length;
            this.numSelectedImgs = 0;
            this.camRowHead = 0;
            // Organize in camRowList
            this.redrawing = true;
            this.loadingOverlay = {show: true, text: `Redrawing images`};
            // Build filter lists
            let assgnLabelList = Object.keys(this.filter.assgnLabel).filter(key => this.filter.assgnLabel[key] && !['$all', '$unbboxed', '$unlabeled', '$multilabeled'].includes(key));
            //let inferLabelList = Object.keys(this.filter.inferLabel).filter(key => this.filter.assgnLabel[key] && !['$all', 'unlabeled', 'multilabeled'].includes(key));
            this.imagesFiltered = []; 
            this.camRowList = [];
            let col = 0;
            console.log(`organizeInColumns. Pre Filters Num images to show: ${this.imagesToShow.length}`);
            let numImagesInColumns = 0;
            for (let img of this.imagesToShow) {
                //console.log(`IMG: ${JSON.stringify(img)}`)
                // Apply filters
                img.showing = false; // set initailly to false
                if (unselect) img.selected = false; // Unselect
                // Assign Label
                if (!this.filter.assgnLabel.$all) { // Some conditions to check
                    let remove = true;
                    //console.log(`AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA: ${this.filter.assgnLabel.$all}`);
                    //console.log(`img.md.AssignLabels.length: ${img.md.AssignLabels.length}`);
                    // if (img.md.AssignLabels.includes('FabricDefective')) console.log(`img.md: ${JSON.stringify(img.md)}`);
                    let imgIsAssgnUnBBoxed = false;
                    // console.log(`defectsWithDetection: ${JSON.stringify(this.defectsWithDetection)}`)
                    for(let defectWithDetection of this.defectsWithDetection) {
                        if (img.md.AssignBBoxes && img.md.AssignLabels.includes(defectWithDetection) && (!img.md.AssignBBoxes[defectWithDetection] || img.md.AssignBBoxes[defectWithDetection].length === 0)) imgIsAssgnUnBBoxed = true;
                        // if (img.md.AssignBBoxes) console.log(`DefectWithDetection: ${defectWithDetection}, img.md.AssignBBoxes[defectWithDetection]: ${img.md.AssignBBoxes[defectWithDetection]}`)
                    }
                    let imgIsAssgnUnlabeled = img.md.AssignLabels.length === 0; 
                    let imIsMultilabeled = img.md.AssignLabels.length > 1;
                    // if (imgIsAssgnUnlabeled && !this.filter.assgnLabel.$unlabeled) remove = true; // Skip labeled images if filter does not inclued unlabeled
                    // if (imIsMultilabeled && !this.filter.assgnLabel.$multilabeled) remove = true; // Skip multilabeled images
                    if (!imgIsAssgnUnlabeled) { // Image has some labels
                        remove = false;
                        //console.log(`assgnLabelList: ${JSON.stringify(assgnLabelList)}`)  
                        //console.log(`img.md.AssignLabels: ${JSON.stringify(img.md.AssignLabels)}`)                   
                        let labelIntersection = img.md.AssignLabels.filter(label => assgnLabelList.includes(label)); // Get the intersection between img labels and filter
                        // if (img.md.AssignLabels.includes('FabricDefective')) console.log(`assgnLabelList: ${JSON.stringify(assgnLabelList)}, img.md.AssignLabels: ${JSON.stringify(img.md.AssignLabels)}, labelIntersection: ${JSON.stringify(labelIntersection)}`)
                        //console.log(`labelIntersection: ${JSON.stringify(labelIntersection)}`)
                        if (labelIntersection.length === 0) {
                            remove = true; // Skip images with no labels in filter
                            // if (img.md.AssignLabels.includes('FabricDefective')) console.log(`organizeInColumns.labelIntersection: ${JSON.stringify(labelIntersection)}, Remove: ${remove}`)                            
                        }
                    }
                    // Add unbboxed images if needed
                    if (imgIsAssgnUnBBoxed && this.filter.assgnLabel.$unbboxed) remove = false;
                    // Add unlabeled images if needed
                    if (imgIsAssgnUnlabeled && this.filter.assgnLabel.$unlabeled) remove = false;
                    // Add multilabeled images if needed
                    if (imIsMultilabeled && this.filter.assgnLabel.$multilabeled) remove = false;
                    // Skip it if needed
                    // if (img.md.AssignLabels.includes('FabricDefective')) console.log(`organizeInColumns.AssignLabels: ${JSON.stringify(img.md.AssignLabels)}, UbBBoxed: ${imgIsAssgnUnBBoxed}, Unlabel: ${imgIsAssgnUnlabeled}, Multilabel: ${imIsMultilabeled}, Remove: ${remove}`)
                    if (remove) continue;
                    //console.log(`iNCLUDE THIS IMAGE`)
                }
                ////////////////////////////////// Inferred Label
                // Calculate list of sublabels if not yet
                if (img.md.PredictSubLabels === undefined) { // Not calculates
                    img.md.PredictSubLabels = {}
                    if (img.md.PredictAll) {
                        for (let defect of this.defects) {
                            if (img.md.PredictAll[defect] < 0.25) img.md.PredictSubLabels[defect] = 'SF';
                            else if (img.md.PredictAll[defect] < 0.5) img.md.PredictSubLabels[defect] = 'DF';
                            else if (img.md.PredictAll[defect] < 0.75) img.md.PredictSubLabels[defect] = 'DT';
                            else img.md.PredictSubLabels[defect] = 'ST'
                        }
                    }
                }
                let predefinedFilter = this.filter.inferLabel.$predefinedSelected;
                let skip = true;
                // Selectable 
                if (predefinedFilter === 'selectable') {
                    // console.log(`PredictSubLabels: ${JSON.stringify(img.md.PredictSubLabels)}`)
                    // console.log(`this.filter.inferLabel: ${JSON.stringify(this.filter.inferLabel)}`)
                    // console.log(`this.filter.inferLabel.$singlelabeled: ${JSON.stringify(this.filter.inferLabel.$singlelabeled)}`)
                    // console.log(`this.filter.inferLabel.$multilabled: ${JSON.stringify(this.filter.inferLabel.$multilabled)}`)
                    // console.log(`img.md.PredictLabels.length: ${JSON.stringify(img.md.PredictLabels.length)}`)
                    // console.log(`img.md.PredictLabels: ${JSON.stringify(img.md.PredictLabels)}`)
                    skip = true;
                    if ((this.filter.inferLabel.$singlelabeled && (!img.md.PredictLabels || img.md.PredictLabels.length <= 1)) || (this.filter.inferLabel.$multilabeled && img.md.PredictLabels && img.md.PredictLabels.length > 1)) {
                        skip = false;
                        for (let defect of this.defects) {
                            // console.log(`Defect: ${defect}`)
                            // console.log(`this.filter.inferLabel[defect].$asList: ${JSON.stringify(this.filter.inferLabel[defect].$asList)}`)
                            // console.log(`img.md.PredictSubLabels[defect]: ${JSON.stringify(img.md.PredictSubLabels[defect])}`)
                            if (!this.filter.inferLabel[defect].$asList.includes(img.md.PredictSubLabels[defect])) { // Image label subclass is not included in filter --> skip
                                skip = true; // skip
                                break; // Stop searching
                            } //else console.log(`Not Skiping this image`);
                        }
                    }
                // noLabel --> Skip if any defect is not 'SF', 'DF'
                } else if (predefinedFilter === 'noLabel') {
                    skip = false;
                    for (let defect of this.defects) {
                        if (!['SF', 'DF'].includes(img.md.PredictSubLabels[defect])) { // Image label subclass is not included in ['SF', 'DF'] --> skip
                            skip = true; // skip
                            break; // Stop searching
                        } //else console.log(`Not Skiping this image`);
                    }

                // okAndDefect --> Skip 'Ok' is active ('DT', 'ST') and any other defect is also active ('DT', 'ST')
                } else if (predefinedFilter === 'okAndDefect') {
                    skip = true;
                    if (['DT', 'ST'].includes(img.md.PredictSubLabels['Ok'])) { // Ok is active
                        for (let defect of this.defects) {
                            if (defect !== 'Ok') { // Check rest of labels
                                if (['DT', 'ST'].includes(img.md.PredictSubLabels[defect])) {
                                    //console.log(`Ok and ${defect} active`);
                                    skip = false; // skip
                                    break; // Stop searching
                                }
                            }
                        }
                    }
                }
                //// Skip (filter out) if needed
                if (skip) continue; // Skip this image
                // Accuracy
                if (!this.filter.accuracy.$all) {
                    let imgCorrectInferred = arraysEqual(img.md.PredictLabels, img.md.AssignLabels); 
                    console.log(`Predicted: ${img.md.PredictLabels}, Assigned: ${img.md.AssignLabels}, Equal: ${imgCorrectInferred}`);
                    if (!this.filter.accuracy.correctinference && imgCorrectInferred) continue; // Skip images inferred correctly if correct filter is not selected
                    if (!this.filter.accuracy.incorrectinference && !imgCorrectInferred) continue; // Skip images inferred inccorrectly if incorrect filter is not selected

                }
                //////////////////////////////////
                // Not filtered
                if (!this.filter.devName.$all) { // Some conditions to check            
                    if (!this.filter.devName[img.md.DevName]) continue; // Skip images with no devName in filter
                }
                // Not filtered
                img.showing = true;
                numImagesInColumns ++;
                if (col === 0) this.camRowList.push({id: this.camRowHead})
                //console.log(`col: ${col} this.camRowList 1: ${JSON.stringify(this.camRowList)}`)
                this.imagesFiltered.push(img);
                this.camRowList[this.camRowList.length - 1][col] = img; // Insert img in last row and col
                col ++;
                if (col === this.numColumns) {
                    col = 0;
                    this.camRowHead ++;
                    //console.log(`this.camRowList: ${JSON.stringify(this.camRowList)}`)
                }                
            }
            this.numImagesFiltered = numImagesInColumns;
            console.log(`organizeInColumns. Post Filters Num images in columns: ${numImagesInColumns}`);
            // Complete last row
            if (this.camRowList.length > 0) {
                let lastImgRow = this.camRowList[this.camRowList.length - 1];
                for (let col=0; col < this.numColumns; col++) {
                    if (!lastImgRow[col]) {
                        lastImgRow[col] = { ...this.imagesToShow[this.imagesToShow.length - 1]}; // Clone last image
                        lastImgRow[col].hide = true; // Hide it
                    }
                }
            }
            // Force image resize
            window.dispatchEvent( new Event('resize'));
            setTimeout(() => { this.redrawing = false; this.loadingOverlay = { show: false };}, 50);
        },        
        onKeyRight() {
            console.log('onKeyRight --> Zoom in');
            if (this.updatingScrolling) {
                console.log('onKeyRight not possible. Still updating scroll');
                return;
            }
            let scrollYPerc = window.scrollY / this.scrollMaxY; // Current scroll Y percentage
            let newScrollMaxY = this.scrollMaxY; // estimated new scroll max after changes
            if (this.numColumns > 1) {
                window.setTimeout(() => {this.loadingOverlay = {show: true, text: `Redrawing images`};}, 10);
                let newNumColumns = this.numColumns - 1;
                let newNumCamRows = Math.ceil(this.numImagesFiltered / newNumColumns);
                console.log(`this.numImagesFiltered: ${this.numImagesFiltered}, newNumColumns: ${newNumColumns}, newNumCamRows: ${newNumCamRows}`);
                newScrollMaxY = newScrollMaxY * newNumCamRows /  this.numCamRows * this.numColumns / newNumColumns;
                //console.log(`newScrollMaxY: ${newScrollMaxY}`);
                this.numColumns --;
                console.log(`onKeyRight. Num Columns: ${this.numColumns}`);
                this.organizeInColumns(false);
            } else {
                if (this.imgScale < 10) { // Limit the rescale to 10 times
                    window.setTimeout(() => {this.loadingOverlay = {show: true, text: `Redrawing images`};}, 10);
                    let factor = 1.2;
                    this.imgScale = this.imgScale * factor;
                    this.imgHeight = this.imgHeight * factor;
                    this.imgWidth = this.imgWidth * factor;
                    newScrollMaxY = newScrollMaxY * factor;
                    this.camRowWidth = this.imgWidth * this.numColumns;
                    console.log(`imgWidth: ${this.imgWidth}, numColumns: ${this.numColumns}, camRowWidth: ${this.camRowWidth}`)
                }                
            }            
            let newScrollY = scrollYPerc * newScrollMaxY;
            console.log(`scrollYPerc: ${scrollYPerc}, scrollMaxY: ${this.scrollMaxY}, newScrollMaxY: ${newScrollMaxY}, newScrollY: ${newScrollY}`);
            this.desiredScrollY = newScrollY;
            if (this.imgScale === 1) this.camRowWidth = null; // Remove X scroll bar
        },
        onKeyLeft () {
            console.log('onKeyLeft --> Zoom out');  
            if (this.updatingScrolling) {
                console.log('onKeyRight not possible. Still updating scroll');
                return;
            }         
            let scrollYPerc = window.scrollY / this.scrollMaxY; // Current scroll Y percentage
            let newScrollMaxY = this.scrollMaxY; // estimated new scroll max after changes
            if (this.imgScale > 1) { // Image is scaled
                window.setTimeout(() => {this.loadingOverlay = {show: true, text: `Redrawing images`};}, 10); 
                let factor = 1.2;
                if (this.imgScale < 1.2) factor = this.imgScale
                this.imgScale = this.imgScale / factor;
                this.imgHeight = this.imgHeight / factor;
                this.imgWidth = this.imgWidth / factor;
                newScrollMaxY = newScrollMaxY / factor;
                this.camRowWidth = this.imgWidth * this.numColumns;
                console.log(`imgWidth: ${this.imgWidth}, numColumns: ${this.numColumns}, camRowWidth: ${this.camRowWidth}`)

            } else { // Image is not scaled --> add one column
                if (this.numColumns < 6) {
                    window.setTimeout(() => {this.loadingOverlay = {show: true, text: `Redrawing images`};}, 10); 
                    let newNumColumns = this.numColumns + 1;
                    let newNumCamRows = Math.ceil(this.numImagesFiltered / newNumColumns);
                    newScrollMaxY = newScrollMaxY * newNumCamRows /  this.numCamRows * this.numColumns / newNumColumns;
                    //console.log(`newScrollMaxY: ${newScrollMaxY}`);
                    this.numColumns ++;
                    this.organizeInColumns(false);                    
                }
                console.log(`onKeyLeft. Num Columns: ${this.numColumns}`);
            }            
            let newScrollY = scrollYPerc * newScrollMaxY;
            console.log(`newScrollY: ${newScrollY}`);
            this.desiredScrollY = newScrollY;
            if (this.imgScale === 1) this.camRowWidth = null; // Remove X scroll bar
        },
        onSelection(imgObj, selected) {
            console.log(`onSelection. Img: ${imgObj.fk}, Selected: ${selected}`);
            imgObj.selected = selected;
            // Call this.countSelectedImages(); delayed
            setTimeout(() => {this.countSelectedImages();}, 100);
            
        },
        onMenuToggle() {
            this.showMenu = ! this.showMenu;
        },
        onFilterAssgnLabelClk(event, label) {
            let selected = event.target.checked;
            console.log(`onFilterAssgnLabelClk. Label: ${label}, Selected: ${selected}`);
            // Apply 'all' if needed
            if (label === '$all') {
                if (this.filter.assgnLabel.$all) { // Already all
                    this.filter.assgnLabel.$all = false;
                    this.filter.assgnLabel.$unbboxed = false;
                    this.filter.assgnLabel.$unlabeled = false;
                    this.filter.assgnLabel.$multilabeled = false;
                    for (let defect of this.defects) this.filter.assgnLabel[defect] = false;
                } else { // None
                    this.filter.assgnLabel.$all = true;
                    this.filter.assgnLabel.$unbboxed = true;
                    this.filter.assgnLabel.$unlabeled = true;
                    this.filter.assgnLabel.$multilabeled = true;
                    for (let defect of this.defects) this.filter.assgnLabel[defect] = true;
                }
            } else { // Amy normal label 
                if (!selected) this.filter.assgnLabel.$all = false; // Set All off if any label is unset
            }      
        },
        onFilterInferLabelClk(event, label, subLabel) {
            let selected = event.target.checked;
            console.log(`onFilterInferLabelClk. Label: ${label}, SubLabel: ${subLabel}, Selected: ${selected}`);
            // Apply 'all' if needed
            if (label === '$all' && subLabel === null) {
                this.filter.inferLabel.$all = !this.filter.inferLabel.$all;
                ['SF', 'DF', 'DT', 'ST'].forEach((sl) => {this.filter.inferLabel[`$all${sl}`] = selected}); 
                for (let defect of this.defects) {
                    this.filter.inferLabel[defect].$all = selected;
                    ['SF', 'DF', 'DT', 'ST'].forEach((sl) => {this.filter.inferLabel[defect][sl] = selected}); 
                }
            } else if (label === '$allSF' && subLabel === null) for (let defect of this.defects) this.filter.inferLabel[defect].SF = selected;
            else if (label === '$allDF' && subLabel === null) for (let defect of this.defects) this.filter.inferLabel[defect].DF = selected;
            else if (label === '$allDT' && subLabel === null) for (let defect of this.defects) this.filter.inferLabel[defect].DT = selected;
            else if (label === '$allST' && subLabel === null) for (let defect of this.defects) this.filter.inferLabel[defect].ST = selected;
            // Any normal label 
            else if (subLabel === '$all') 
                ['SF', 'DF', 'DT', 'ST'].forEach((sl) => {this.filter.inferLabel[label][sl] = selected});   
            else if (['SF', 'DF', 'DT', 'ST'].includes(subLabel))
                this.filter.inferLabel[label][subLabel] = selected;
            // Conver to list of sub-labels
            for (let defect of this.defects) {
                this.filter.inferLabel[defect].$asList = []; // Empty list
                ['SF', 'DF', 'DT', 'ST'].forEach((sl) => { if (this.filter.inferLabel[defect][sl]) this.filter.inferLabel[defect].$asList.push(sl)}); 
            }  
            console.log(`New Infer FFFFilter: ${JSON.stringify(this.filter.inferLabel)}`);
        },
        onFilterAccuracyClk(event, label) {
            let selected = event.target.checked;
            console.log(`onFilterAccuracyClk. Label: ${label}, Selected: ${selected}`);
            // Apply 'all' if needed
            if (label === '$all') {
                if (this.filter.accuracy.$all) { // Already all
                    this.filter.accuracy.$all = false;
                    this.filter.accuracy.correctinference = false;
                    this.filter.accuracy.incorrectinference = false;
                } else { // None
                    this.filter.accuracy.$all = true;
                    this.filter.accuracy.correctinference = true;
                    this.filter.accuracy.incorrectinference = true;
                }
            } else { // Amy other label 
                if (!selected) this.filter.accuracy.$all = false; // Set All off if any label is unset
            }      
        },
        onFilterInferSelected(value) {
            console.log(`onFilterInferSelected: ${value}`);
        },
        onFilterDeviceClk(event, devName) {
            let selected = event.target.checked;
            console.log(`onFilterDeviceClk. Device Name: ${devName}, Selected: ${selected}`);
            // Apply 'all' if needed
            if (devName === '$all') {
                if (this.filter.devName.$all) { // Already all
                    this.filter.devName.$all = false;
                    for (let devName of this.imgDevNames) this.filter.devName[devName] = false;
                } else { // None
                    this.filter.devName.$all = true;
                    for (let devName of this.imgDevNames) this.filter.devName[devName] = true;
                }
            } else { // Amy normal label 
                if (!selected) this.filter.devName.$all = false; // Set All off if any label is unset
            }        
        },
        onApplyFilter() {
            console.log(`onApplyFilter`);
            this.filterModalShow=false;
            this.lastAppliedFilter = JSON.parse(JSON.stringify(this.filter)); // Save it
            this.organizeInColumns(true);
        },
        onCancelFilter() {
            console.log(`onCancelFilter`);
            this.filterModalShow=false;
            this.filter = JSON.parse(JSON.stringify(this.lastAppliedFilter)); // Restore last applied filters
        },
        onSortClk(event, section, direction) {
            let selected = event.target.checked;
            console.log(`onSortClk. Section: ${section}, Direction: ${direction}, Selected: ${selected}`);
            if (selected) {
                // Initailize all to false
                this.sort = {time: {asc: false, dsc: false}, assgnLabel: {asc: false, dsc: false}, inferLabel: {asc: false, dsc: false}, devName: {asc: false, dsc: false}},
                // set the selected section.direction
                this.sort[section][direction] = true;
            }
        },
        onApplySort() {
            console.log(`onApplySort`);
            this.sortModalShow=false;
            this.lastAppliedSort = JSON.parse(JSON.stringify(this.sort));
            this.sortImages();
            this.organizeInColumns(false);
        },
        onCancelSort() {
            console.log(`onCancelSort`);
            this.sortModalShow=false;
            this.sort = JSON.parse(JSON.stringify(this.lastAppliedSort)); // Restore last applied sort
        },
        // selectAll() {
        //     console.log(`selectAll`);
        //     if (this.allSelected) { // All selected already
        //         for (let img of this.imagesToShow) {
        //             img.selected = false; // Unselect
        //         }
        //     } else { // Not All selected
        //         for (let img of this.imagesToShow) {
        //             if (img.showing) img.selected = true;
        //             else img.selected = false;
        //         }
        //     }
        //     this.allSelected = ! this.allSelected;
        // },
        selectImages() {
            console.log(`selectImages. selectImagesPercent: ${this.selectImagesPercent}`);
            this.selectImagesModalShow = false;
            let NumImgsToSelect = Math.round(this.numImagesFiltered * this.selectImagesPercent / 100);
            console.log(`NumImgsToSelect: ${NumImgsToSelect}`);
            // If all images selected
            if (NumImgsToSelect === this.numImagesFiltered) {
                for (let img of this.imagesFiltered)  {
                    img.selected = true; // Select all
                }
            } else {
                // Select NumImgsToSelect from this.imagesFiltered randomly
                let selectedImgs = [];
                let numImgs = this.imagesFiltered.length;
                while (selectedImgs.length < NumImgsToSelect) {
                    let idx = Math.floor(Math.random() * numImgs);
                    if (!selectedImgs.includes(idx)) selectedImgs.push(idx);
                }
                console.log(`selectedImgs: ${JSON.stringify(selectedImgs)}`);
                for (let img of this.imagesFiltered) img.selected = false; // Unselect
                for (let idx of selectedImgs) {
                    let img = this.imagesFiltered[idx];
                    if (img.showing) img.selected = true; // Select
                }
            }
            this.countSelectedImages();
        },
        unselectAllImages() {
            console.log(`unselectAllImages`);
            this.selectImagesModalShow = false;
            for (let img of this.imagesToShow) {
                img.selected = false; // Unselect
            }
            this.countSelectedImages();
        },
        onCancelSelect() {
            console.log(`onCancelSelect`);
            this.selectImagesModalShow = false;
            for (let img of this.imagesToShow) {
                img.selected = false; // Unselect
            }
            this.countSelectedImages();
        },
        onCopyClick() {
            // Count selected images
            this.countSelectedImages();
            if (this.numSelectedImgs === 0) {
                console.error(`No images selected to copy/move`);
                alert(`Nothing to copy/move. No images selected`);
                return;
            }
            if (!this.anyModalShowing) {
                this.copyModalShow = ! this.copyModalShow;
            }
        },
        copyImages() {
            console.log(`copyImages`);
            this.copyModalShow=false;
            if (!this.copyImgsDstId) {
                console.error(`No destination dataset provided`);
                return;
            }
            // Count selected images
            this.countSelectedImages();
            if (this.numSelectedImgs === 0) {
                console.error(`No images selected to copy/move`);
                alert(`Nothing to copy/move. No images selected`);
                return;
            }
            if (this.copyoperation === 'Move')  this.loadingOverlay = {show: true, text: `Moving image(s)`};
            else this.loadingOverlay = {show: true, text: `Copying image(s)`};
            // Get selected images
            let imageIdList = []
            for (let img of this.imagesToShow) {
                if(img.selected) {
                    img.hideImg = true;
                    imageIdList.push(img.md.ImageId);
                }
            }  
            // Define callback function
            let callBackFunction = null;
            if (this.copyoperation === 'Move') {
                callBackFunction = (result) => {
                    this.refreshImages(); // Refresh images if some images has been deleted
                    this.loadingOverlay = {show: false};
                    if (result === 'Error') alert(`Error copying images`);
                    else alert(`Move operation is taking place in backend. It can take from few seconds to few minutes depending on the number of images.`);
                }
            } else {
                callBackFunction = (result) => {
                    this.loadingOverlay = {show: false};
                    if (result === 'Error') alert(`Error copying images`);
                    else alert(`Copy operation is taking place in backend. It can take from few seconds to few minutes depending on the number of images.`);
                }
            }
            //
            copyImagesBtwDatasets(this.userId, this.datasetId, this.copyImgsDstId, imageIdList, this.copyoperation, callBackFunction)

        },
        removeImages() {
            console.log(`removeImages`);
            // Count selected images
            this.countSelectedImages();
            // Ask for confirmation
            if (this.numSelectedImgs === 0) alert(`Nothing to remove. No images selected`);
            else {
                let text = `Do you really want to remove ${this.numSelectedImgs} images? \nThis action can not be recovered`;
                if (confirm(text) === true) {
                    console.log(`Removing ${this.numSelectedImgs} Images.`)
                    //let imgsToRemove = [];
                    let imageIdList = [];
                    for (let img of this.imagesToShow) {
                        if(img.selected) {
                            img.hideImg = true;
                            imageIdList.push(img.md.ImageId);
                            //imgsToRemove.push({ImageId: img.md.ImageId, FileName: img.md.FileName});
                        }
                    }                   
                    // // Remove images. Separate last one
                    // for (let i=0; i <  imgsToRemove.length; i++) {
                    //     let imgToRemove = imgsToRemove[i];
                    //     console.log(`Removing Image: ${imgToRemove.ImageId} from Dataset: ${this.datasetId}`);
                    //     if (i < imgsToRemove.length - 1) { // Not last one
                    //         removeImageFromDataset(this.userId, this.datasetId, imgToRemove.ImageId, imgToRemove.FileName);
                    //     } else { // Last one
                    //         removeImageFromDataset(this.userId, this.datasetId, imgToRemove.ImageId, imgToRemove.FileName, this.refreshImages); // Refresh images when finished
                    //     }
                    // }
                    // Remove images
                    removeImagesFromDataset(this.userId, this.datasetId, imageIdList, this.refreshImages);

                    this.loadingOverlay = {show: true, text: `Removing image(s)`};
                } else {
                    console.log(`Remove Images was cancelled`)
                }   
            }
        },
        countSelectedImages() {
            console.log(`countSelectedImages`);
            this.numSelectedImgs = 0;
            for (let img of this.imagesToShow) if(img.selected) this.numSelectedImgs ++;
            console.log(`numSelectedImgs: ${this.numSelectedImgs}`);
        },
        // Upload images Modal
        onUploadLabelClk(event, label) {
            let selected = event.target.checked;
            console.log(`onUploadLabelClk. Label: ${label}, Selected: ${selected}`);
            // If label == 'Ok' and selected --> remove the rest of labels
            if (label === 'Ok' &&  selected) {
                for (let defect of this.defects) if (defect !== 'Ok') this.upload.assign[defect] = false;
            } else if (label !== 'Ok' &&  selected) this.upload.assign['Ok'] = false; // --> remove 'Ok' if set
            // If label == 'Bg' and selected --> remove the rest of labels
            if (label === 'Bg' &&  selected) {
                for (let defect of this.defects) if (defect !== 'Bg') this.upload.assign[defect] = false;
            } else if (label !== 'Bg' &&  selected) this.upload.assign['Bg'] = false; // --> remove 'Bg' if set
        },
        onUploadChange(event) {
            //console.log(`onUploadChange: ${event}`);
            this.filesToUpload = event.target.files;   
            console.log(`filesToUpload: ${JSON.stringify(this.filesToUpload)}`);
        },
        async uploadFiles() {
            console.log(`uploadFiles. Source: ${this.upLdSource}`);
            if (this.upLdSource==='filesNLabel') {
                this.uploadFilesFromLilesNLabel();
            } else { // Assume zip
                this.uploadFilesFromZip();
            }
        },
        async uploadImage(predictAll, predictBBoxes, assignLabels, assignBBoxes, imgBlob, fileName) {
            // Upload the image                    
            let numTry = 1
            let result = await addImageToDataset(this.userId, this.datasetId, predictAll, predictBBoxes, assignLabels, assignBBoxes, imgBlob, fileName);
            while (result === 'Failed' && numTry < appConfig.MaxRequestRetry) {
                let delay = 1.2 ** numTry * 50;   // Exponential delay
                console.error(`Image: ${fileName} failed to add to dataset. Retrying in ${delay} ms`);
                await new Promise(r => setTimeout(r, delay));
                console.log(`Retrying Image: ${fileName}`);
                result = await addImageToDataset(this.userId, this.datasetId, predictAll, predictBBoxes, assignLabels, assignBBoxes, imgBlob, fileName);
                numTry ++;
            }                
            if (result === 'Ok') {
                console.log(`Image uploaded: ${fileName}`)
            }
        },
        async uploadFilesFromZip() {
            console.log(`uploadFilesFromZip`);
            this.uploadModalShow = false;
            let zipFile = this.filesToUpload[0];
            console.log(`File: ${zipFile.name}`);
            if (this.filesToUpload.length > 0) this.loadingOverlay = {show: true, text: `Uploading images from: ${zipFile.name}`};

            const zip = await JSZip.loadAsync(zipFile) // Load zip file
            // Get list of imageFiles and csvFile
            let imageFiles = {}
            let csvFile = null;
            zip.forEach((relativePath, file) => {
                if (!file.dir) {
                    let fileName = relativePath.split('/').pop();
                    console.log(`File Path: ${relativePath}. File Name: ${fileName}. Type: ${typeof(file)}`);
                    let extension = fileName.split('.').pop();
                    if (extension.toLowerCase() === 'csv') csvFile = file;
                    else if (extension.toLowerCase() === 'jpg') {
                        imageFiles[fileName] = file;
                    }                    
                }
            })
            console.log(`Zip File loaded`);
            if (csvFile) { // Csv file found
                // Read CSV content
                const csvText = await csvFile.async('text');
                console.log(`CSV: ${csvText}`);
                let csvParsed = parseCSV(csvText, CSV_SEPARATOR); // Output {Headers: headers, Objects: objects};
                const headers = csvParsed.Headers;
                const rows = csvParsed.Objects; 
                console.log(`Headers: ${JSON.stringify(headers)}. Num Rows: ${rows.length}`);
                // Get list  of defects from csv header
                let defects = []          
                for (let columnName of headers) {
                    if (columnName.includes('%')) defects.push(columnName.split('%')[0].trim()); // Get the names before %
                }
                console.info(`Defects: ${JSON.stringify(defects)}`)
                // Build a dictionary indexed by file name
                let rowByFileName = {};
                for (let i=0; i<rows.length; i++) {
                    let row = rows[i];
                    if (row['file']) {
                        rowByFileName[row['file']] = row;
                    } else console.error(`Row does not contain 'file' column: ${row}`);                    
                }
                // Loop over all image files 
                let numProcesingImages = 0;
                let numImagesToUpload = Object.keys(imageFiles).length;
                let numImagesUploaded = 0;
                this.loadingOverlay = {show: true, text: `Uploading image: ${numImagesUploaded + 1}/${numImagesToUpload}`};
                for (let fileName in imageFiles) {

                    while (numProcesingImages >= appConfig.NumParallelRequests) {
                        console.log(`Too many (${numProcesingImages}) parallel processing images. Delaying`)
                        await new Promise(r => setTimeout(r, 200));
                    }
    
                    if (numImagesUploaded % 1 === 0) this.loadingOverlay = {show: true, text: `Uploading image: ${numImagesUploaded + 1}/${numImagesToUpload}`};
                    let imgFile = imageFiles[fileName];
                    let imgBlob = await imgFile.async('blob');
                    let csvRow = rowByFileName[fileName];
                    let assignLabels = [];
                    let predictAll = {};
                    if (csvRow) { // File name found in csv
                        // Get the assignLabels and predictAll                        
                        for (let defect of defects) {
                            if (csvRow[defect] === '1') assignLabels.push(defect); // Add defect to assignLabels if 1 is in that column
                            predictAll[defect] = Number(csvRow[`${defect}%`])/100.0; // Add probability of defect to predictAll
                        }
                        console.log(`Image: ${JSON.stringify(csvRow)}. AssignLabels: ${assignLabels}, PredictAll: ${predictAll}`);
                    } else {
                        console.info(`Image file not found in csv: ${fileName}`);     
                    }
                    const predictBBoxes = csvRow.predict_bboxes ? JSON.parse(csvRow.predict_bboxes) : null;
                    const assignBBoxes = csvRow.assign_bboxes ? JSON.parse(csvRow.assign_bboxes) : null;
                    // Upload the image   
                    numProcesingImages ++;                 
                    this.uploadImage(predictAll, predictBBoxes, assignLabels, assignBBoxes, imgBlob, fileName)
                    .then(() => {
                        numProcesingImages --;
                        numImagesUploaded ++;
                    })
                    .catch(error => {
                        console.log('uploadImage error:' + error.message);
                        numProcesingImages --;
                        numImagesUploaded ++;
                    });
                }
                // Wait for all images upladed
                while (numImagesUploaded < numImagesToUpload) {
                    console.log(`Still some images to upload: ${numImagesToUpload - numImagesUploaded}. Delaying`)
                    await new Promise(r => setTimeout(r, 200));
                }   
                this.refreshImages();
            } else { // CSV file not found
                console.error(`CSV file not found`);
                this.loadingOverlay = {show: false};     
            }       
            // Reset memory
            this.filesToUpload = [];             
        },
        async uploadFilesFromLilesNLabel() {
            console.log(`uploadFilesFromLilesNLabel`);
            this.uploadModalShow = false;
            if (this.filesToUpload.length > 0) this.loadingOverlay = {show: true, text: `Uploading images`};
            console.log(`Files: ${JSON.stringify(this.filesToUpload)}`);
            // Get AssignLabels
            let assignLabels = [];
            for (let defect of this.defects) if (this.upload.assign[defect]) assignLabels.push(defect);
            let numProcesingImages = 0;
            let numImagesToUpload = this.filesToUpload.length;
            let numImagesUploaded = 0;
            for (let file of this.filesToUpload) {
                console.log(`Uplading file: ${file.name}, Type: ${file.type}`);
                // Check type
                if(file.type.indexOf("image") == -1) {
                    alert(`File: ${file.name} is not an image. Skiping it.`);
                    numImagesToUpload --;
                }
                else { // Type is correct
                    // Delay if too many files being processed in parallel
                    while (numProcesingImages >= appConfig.NumParallelRequests) {
                        console.log(`Too many (${numProcesingImages}) parallel processing images. Delaying`)
                        await new Promise(r => setTimeout(r, 200));
                    }
                    numProcesingImages ++;
                    // Calculate Image Size
                    let img = new Image();
                    let objectUrl = window.URL.createObjectURL(file);
                    let thisThis = this;
                    img.onload = async function () {
                        //alert(this.width + " " + this.height);
                        window.URL.revokeObjectURL(objectUrl);
                        console.log(`Image: ${file.name} has Width: ${this.width} and Height: ${this.height}`);
                        // TODO: Check that size is ok
                        // Add image to dataset with retry
                        let numTry = 1
                        const predictAll = null;
                        const predictBBoxes = null;
                        const assignBBoxes = null;
                        let result = await addImageToDataset(thisThis.userId, thisThis.datasetId, predictAll, predictBBoxes, assignLabels, assignBBoxes, file, file.name);
                        while (result === 'Failed' && numTry < appConfig.MaxRequestRetry) {
                            let delay = 1.2 ** numTry * 50;   // Exponential delay
                            console.error(`Image: ${file.name} failed to add to dataset. Retrying in ${delay} ms`);
                            await new Promise(r => setTimeout(r, delay));
                            console.log(`Retrying Image: ${file.name}`);
                            result = await addImageToDataset(thisThis.userId, thisThis.datasetId, predictAll, predictBBoxes, assignLabels, assignBBoxes, file, file.name);
                            numTry ++;
                        }                
                        if (result === 'Ok') {
                            numImagesUploaded ++;
                            numProcesingImages --;
                            if (numProcesingImages === 0) {
                                thisThis.loadingOverlay = {show: false};
                                thisThis.refreshImages();
                            }
                            thisThis.loadingOverlay = {show: true, text: `Uploading image: ${numImagesUploaded}/${numImagesToUpload}`};
                        }
                    };
                    img.onerror = () => {
                        console.log(`Getting image error`);
                        numImagesToUpload --;
                        numProcesingImages --;
                        if (numProcesingImages === 0) {
                            thisThis.loadingOverlay = {show: false, text: `Uploading images`};
                            thisThis.refreshImages();
                        }
                    }
                    img.src = objectUrl;
                    await new Promise(r => setTimeout(r, 1)); // Allow execute waiting parts
                }
            }          
            // Reset memory
            this.filesToUpload = [];
            for (let defect of this.defects) this.upload.assign[defect] = false;
        },
        urlToPromise(url) {
            return new Promise(function(resolve, reject) {
                JSZipUtils.getBinaryContent(url, function (err, data) {
                    if(err) {
                        reject(err);
                    } else {
                        resolve(data);
                    }
                });
            });
        },
        async downloadFiles() {
            console.log(`downloadFiles. dwldZipStructure: ${this.dwldZipStructure}`);
            console.time('downloadFiles');
            let numImgs = this.allImagesOfDataset.length;
            let remaining = numImgs;
            this.loadingOverlay = {show: true, text: `Downloading dataset images. Remaining: ${remaining}/${numImgs}`}; 
            this.downloadModalShow = false;
            // Create zip object
            let zip = new JSZip();
            // Create a folder with name of the dataset
            let root = zip.folder(this.datasetName);
            // Create subfolder if this.dwldZipStructure = 'structuredZip'
            let subfolders = {}
            if (this.dwldZipStructure === 'structuredZip') {
                for (let defect of this.defects) subfolders[defect] = root.folder(defect)
                // Add 'Unlabeled' subfolder
                subfolders['Unlabeled'] = root.folder('Unlabeled')
            }
            // Create csv content with header
            let csv = `sep=${CSV_SEPARATOR}\n`; // Add separator
            csv += `imageid${CSV_SEPARATOR} file`;
            for (let defect of this.defects) {csv += `${CSV_SEPARATOR} ${defect}`} // Assign Label column headers
            csv += `${CSV_SEPARATOR} assign_bboxes`;
            for (let defect of this.defects) {csv += `${CSV_SEPARATOR} ${defect}%`} // Inferred probability Label column headers
            csv += `${CSV_SEPARATOR} predict_bboxes`;
            csv += '\n';
            // Loop over all images in dataset
            let numParallelRequest = 0;
            console.log(`Downloading: ${numImgs} images`);
            for (let img of this.allImagesOfDataset) {
                //console.log(`IMG: ${JSON.stringify(img)}`)                
                let imgId = img.ImageId;
                let imgFileName = img.FileName;
                let assignLabels = img.AssignLabels;
                let assignBBoxes = img.AssignBBoxes;
                let predictAll = img.PredictAll;
                let predictBBoxes = img.PredictBBoxes;
                // console.log(`>>>>>>>IMG: ${imgFileName}`)
                // Append to CSV
                csv += `${imgId}${CSV_SEPARATOR} ${imgFileName}`; // First 2 columns
                for (let defect of this.defects) csv += `${CSV_SEPARATOR} ${(assignLabels.includes(defect)) ? 1 : 0}`; // Assign Label columns
                if (assignBBoxes) csv += `${CSV_SEPARATOR} ${JSON.stringify(assignBBoxes)}`; // Assign BBoxes column
                else csv += `${CSV_SEPARATOR} {}`;
                for (let defect of this.defects) csv += `${CSV_SEPARATOR} ${(predictAll[defect] * 100).toFixed(1)}`; // Inferred probability Label columns
                if (predictBBoxes) csv += `${CSV_SEPARATOR} ${JSON.stringify(predictBBoxes)}`; // Predict BBoxes column
                else csv += `${CSV_SEPARATOR} {}`;
                csv += '\n'; // Line Break
                // Save image in zip
                // Re-get the signed url in case it has expired
                img['S3URL'] = await auth.getS3Url(this.s3Client, appConfig.ProjectsS3Bucket, img['FileKey']);
                let imgUrl = img['S3URL'];
                // console.log(`URL: ${imgUrl}`);
                // if (this.dwldZipStructure === 'structuredZip') {
                //     for (let defect of assignLabels) { // Save one copy in each defect subfolder
                //         let folder = subfolders[defect];
                //         folder.file(imgFileName, this.urlToPromise(imgUrl), {binary:true});
                //     }
                    
                // } else root.file(imgFileName, this.urlToPromise(imgUrl), {binary:true}); // save in root folder if not structuredZip   
                while (numParallelRequest > appConfig.NumParallelRequests) {
                    // console.log(`Waiting`)
                    await new Promise(r => setTimeout(r, 200));
                }
                numParallelRequest ++;
                fetch(imgUrl)
                    .then(res => res.blob())
                    .then(blob => {
                        if (this.dwldZipStructure === 'structuredZip') {
                            if (assignLabels.length === 0) { // Save one copy in Unlabeled subfolder
                                subfolders['Unlabeled'].file(imgFileName, blob, {binary:true});
                                console.log(`Saving image: ${imgFileName} in folder: Unlabeled`);
                            } else { // Save one copy in each defect subfolder
                                for (let defect of assignLabels) { // Save one copy in each defect subfolder
                                    let folder = subfolders[defect];
                                    folder.file(imgFileName, blob, {binary:true});
                                    console.log(`Saving image: ${imgFileName} in folder: ${defect}`);
                                }
                            }                        
                        } else {
                            console.log(`Saving image: ${imgFileName} in root folder`);
                            root.file(imgFileName, blob, {binary:true}); // save in root folder if not structuredZip
                        }
                        numParallelRequest --;
                        remaining --;
                        if (remaining % 100 == 0) this.loadingOverlay = {show: true, text: `Downloading dataset images. Remaining: ${remaining}/${numImgs}`};
                    })
                    .catch(function(error) {
                        console.log('Fetch error:' + error.message);
                        numParallelRequest --;
                        remaining --;
                });                
            }
            // Wait untill all request has finished
            while (numParallelRequest > 0) {
                console.log(`Waiting for end`)
                await new Promise(r => setTimeout(r, 200));
            }
            this.loadingOverlay = {show: true, text: `Downloading dataset images. Remaining: ${remaining}/${numImgs}`};
            // Add csv file
            root.file(`${this.datasetName}.csv`, csv);
            console.log(`Starting saving zip file`);
            this.loadingOverlay = {show: true, text: `Creating dataset zip.`};
            await zip.generateAsync({type:"blob", streamFiles: true})
                .then((blob) => {
                    // Save zip
                    saveAs(blob, `${this.datasetName}@${getDateAsISOStrWoMs()}.zip`);
                })
                .catch((error) => {
                    console.log('generateAsync error:' + error.message);
                    alert(`Error creating dataset zip for: ${this.datasetName}. Error: ${error.message}`)
                });
            this.loadingOverlay = {show: false}; 
            console.log(`downloadFiles finished`);  
            console.timeEnd('downloadFiles');
        },
        onCamImgClick(row, col) {
            console.log(`onCamImgClick. Row: ${row.id}, col: ${col}`);
            this.rowIdSelected = row.id;
            this.colIdSelected = col;
            // this.imgHeightInModal = Math.min(this.windowHeight - 200, this.windowWidth * this.imgOriginalHeight / this.imgOriginalWidth - 5);
            // this.imgWidthtInModal = this.windowWidth;
            // console.log(`Nav Bar Height: ${this.$refs.navBar.clientHeight}`);
            // this.navBarHeight = this.$refs.navBar.clientHeight;

            console.log(`imgOriginalHeight: ${this.imgOriginalHeight}, imgOriginalWidth: ${this.imgOriginalWidth}`);
            let nvBarHeight = this.$refs.navBar.clientHeight;
            console.log(`nvBarHeight: ${nvBarHeight}`);
            let factor1 = window.innerHeight - 2 * nvBarHeight - 5;
            let factor2 = window.innerWidth * this.imgOriginalHeight / this.imgOriginalWidth - 5;
            console.log(`Factor1: ${factor1}, Factor2: ${factor2}`);
            this.imgHeightInModal = Math.min(factor1, factor2);
            this.imgWidthtInModal = window.innerWidth;            
            console.log(`innerHeight: ${window.innerHeight}, innerWidth: ${window.innerWidth}`);
            console.log(`imgHeightInModal: ${this.imgHeightInModal}, imgWidthtInModal: ${this.imgWidthtInModal}`);
            
            this.initialScrollYModal = window.scrollY;
            this.initialRowSelectedModal = this.rowIdSelected;
            this.showImgModal();
        },
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //
        //      Filters Functions
        //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        initializeFilters () {
            console.log(`initializeFilters`);
            this.filter = {assgnLabel: {}, inferLabel: {}, accuracy: {}, devName: {}};
            // Initialize assgnLabel
            this.filter.assgnLabel.$all = true;
            this.filter.assgnLabel.$unbboxed = true;
            this.filter.assgnLabel.$unlabeled = true;
            this.filter.assgnLabel.$multilabeled = true;
            for (let defect of this.defects) this.filter.assgnLabel[defect] = true;
            // Initialize inferLabel // SF = 'SureFalse' DF = 'DoubtFalse' DT = 'DoubtTrue' ST = 'SureTrue' DA = 'DoubtAll'
            this.filter.inferLabel.$predefinedOptions = [{id: 'selectable', name: 'Selectable'}, {id: 'noLabel', name: 'Inconsistency: No label'}, {id: 'okAndDefect', name: 'Inconsistency: Ok and defect simultaneously'}]
            this.filter.inferLabel.$predefinedSelected = 'selectable'
            this.filter.inferLabel.$all = true;
            this.filter.inferLabel.$allSF = true;
            this.filter.inferLabel.$allDF = true;
            this.filter.inferLabel.$allDT = true;
            this.filter.inferLabel.$allST = true;
            this.filter.inferLabel.$singlelabeled = true;
            this.filter.inferLabel.$multilabeled = true;
            for (let defect of this.defects) {
                this.filter.inferLabel[defect] = {};
                this.filter.inferLabel[defect].$all = true;
                this.filter.inferLabel[defect].SF = true;
                this.filter.inferLabel[defect].DF = true;
                this.filter.inferLabel[defect].DT = true;
                this.filter.inferLabel[defect].ST = true;
                this.filter.inferLabel[defect].$asList = ['SF', 'DF', 'DT', 'ST'];
            }            
            // Initialize Accuracy filters
            this.filter.accuracy.$all = true;
            this.filter.accuracy.correctinference = true;
            this.filter.accuracy.incorrectinference = true;
            // Initialize Device
            this.filter.devName.$all = true;
            for (let devName of this.imgDevNames) this.filter.devName[devName] = true;
            // Store as lastAppliedFilter
            this.lastAppliedFilter = JSON.parse(JSON.stringify(this.filter)); // Clone filter
            console.log(`LAST FILTER: ${JSON.stringify(this.lastAppliedFilter)}`);

        },
        redefineDevicesInFilter() {
            console.log(`redefineDevicesInFilter`);
            // Add mising devices to filter
            for (let devName of this.imgDevNames) 
                if (this.filter.devName[devName] === undefined) this.filter.devName[devName] = true; // Add as True (not filtered) if not there
            // Remove unseen devices from filter
            for (let devName in this.filter.devName) {
                if (devName !== '$all' && !this.imgDevNames.includes(devName)) {
                    console.log(`Removing device filter: ${devName}`);
                    delete this.filter.devName[devName]; // Remove from filter
                }
            }
            console.log(`redefineDevicesInFilter: ${JSON.stringify(this.filter)}`);
        },
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //
        //      Image Modal Window Functions
        //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // showImgModal () {
        //     console.log(`Showing Image modal window`);
        //     this.imgModalIsShow = true;
        //     this.helpInfo = 'Use the arrows:\n ◄ Zoom out \n ► Zoom in \n ▲ Previous image \n ▼ Next image';
        // },
        // closeImgModal () {
        //     console.log(`Closing Image modal window. Row Selected: ${this.rowIdSelected0}, Column: ${this.colIdSelected}`);
        //     this.imgModalIsShow = false;
        //     this.helpInfo = 'Click on any image to enlarge';
        //     //window.scroll(0, this.imgHeight * this.rowIdSelected + 100);
        // },
        onKeyUpInImgModal (event) {
            console.log('onKeyUpInImgModal');
            if (this.rowIdSelected > 0 || this.colIdSelected > 0) {
                if (this.colIdSelected > 0) this.colIdSelected --; // Decrease column
                else { // Row exausted --> decrease onne row
                    this.rowIdSelected --;
                    this.colIdSelected = this.numColumns-1;
                    window.scroll(window.scrollX, this.initialScrollYModal + (this.rowIdSelected - this.initialRowSelectedModal) * this.imgHeight);
                }               
            }
            event.stopPropagation();
            event.preventDefault();
        },
        onKeyDownInImgModal (event) {
            console.log('onKeyDownInImgModal');
            let lastRowNumColumns = this.numImagesFiltered % this.numColumns;
            if (lastRowNumColumns == 0) lastRowNumColumns = this.numColumns;
            console.log(`lastRowNumColumns: ${lastRowNumColumns}`)
            if (this.rowIdSelected < this.camRowList.length-1 || this.colIdSelected < lastRowNumColumns-1) {
                if (this.colIdSelected < this.numColumns-1) this.colIdSelected ++;
                else {
                    this.rowIdSelected ++;
                    this.colIdSelected = 0;
                    window.scroll(window.scrollX, this.initialScrollYModal + (this.rowIdSelected - this.initialRowSelectedModal) * this.imgHeight);
                }
            }
            event.stopPropagation();
            event.preventDefault();
        },
        // onKeyRightInImgModal () {
        //     console.log('onKeyRightInImgModal');
        //     this.imgHeightInModal = this.imgHeightInModal * 1.2;
        //     this.imgWidthtInModal = this.imgWidthtInModal * 1.2;
        // },
        // onKeyLeftInImgModal () {
        //     console.log('onKeyLeftInImgModal');
        //     this.imgHeightInModal = this.imgHeightInModal / 1.2;
        //     this.imgWidthtInModal = this.imgWidthtInModal / 1.2;
        // },
        showImgModal() {
            console.log(`showImgModal`);
            this.helpInfo = 'Use the arrows:\n ◄ Zoom out \n ► Zoom in \n ▲ Previous image \n ▼ Next image';
            this.imgModalIsShow = true;
            // this.modalPos = pos;
            // this.modalImgClass = {...imgClass}; // Clone imgClass to get static image
            document.getElementById("ImgModal").style.display = "block";
            // Start WZoom object
            setTimeout(() => {                
                this.modalWZoom = WZoom.create('#zoompan', {
                    type: 'html',
                    speed: 5,
                    minScale: 1,
                    maxScale: 10,
                    zoomOnClick: false,
                    zoomOnDblClick: true,
                    dragScrollable: false,
                });
            }, 10);
        },
        closeImgModal() {
            console.log(`closeImgModal`);
            this.imgModalIsShow = false;
            this.rowIdSelected = null;
            this.colIdSelected = null;
            document.getElementById("ImgModal").style.display = "none";
            this.countSelectedImages();
        },
        onScroll() {
            console.log(`onScroll`);
            // Recreate modalWZoom if needed
            if (this.imgModalIsShow && this.modalWZoom) {
                this.modalWZoom.prepare();
            }
        },
        onWsConnected() {
            console.log(`onWsConnected`);
            let customeAuthorized = this.isCustomerAuthorized(this.customer);
            if (customeAuthorized) {
                console.log(`Customer authorized: ${this.customer}`);                
                this.onUserAuthorized();
            } else { // Redirect to login
                console.log(`Customer not authorized: ${this.customer}`);
                this.loadingOverlay.show = false;
                // Redirect to login page
                window.location.href = '/login';
            }
        },
        onUserAuthorized() {
            console.log(`onUserAuthorized`);
            this.refreshDatasetsData();
            console.log(`Getting images`);
            this.refreshImages();
        },
    },
    computed: {   
        userId: function () { return store.state.login.email },
        numCamRows: function () { return this.camRowList.length; },
        datasetList: function() { if (store.state.projects.datasetsOfProject) {return store.state.projects.datasetsOfProject[this.projectId]} else {return []}},
        copyDatasetList: function() { return this.datasetList.filter(ds => ds.DatasetName[0]!='$' && ds.DatasetName != this.datasetName)},
        datasetData: function () { return store.state.projects.datasetData[this.datasetId] },
        allImagesOfDataset: function () { return store.state.projects.imgsOfDataset[this.datasetId] },
        defects: function () { return this.datasetData.Defects },
        defectsWithDetection: function () { if (this.datasetData.DefectsWithDetection != undefined) return this.datasetData.DefectsWithDetection; else return []},
        anyModalShowing: function () {return this.filterModalShow || this.sortModalShow || this.uploadModalShow || this.downloadModalShow || this.copyModalShow}, 
        infoTooltip: function () {                    
            if (this.datasetData) {
                let numImages = this.datasetData.NImgs;
                let text = `Num. Images: ${numImages}`;
                text += `\nNum. Labeled: ${this.datasetData.NLbs}`;
                text += (numImages > 0) ? ` (${(this.datasetData.NLbs/numImages*100).toFixed(1)}%)` : '';
                for (let defect of this.defects) {
                    let varName = `N${defect}`;
                    text += `\nNum ${defect}: ${this.datasetData[varName]}`
                    text += (numImages > 0) ? ` (${(this.datasetData[varName]/numImages*100).toFixed(1)}%)` : '';
                }
                return text;
            } else return 'Dataset info';
        },
        scrollMaxY: function () { return Math.max( document.body.scrollHeight, document.body.offsetHeight, 
                   document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );},
        decryptionPassword: function () { if (this.projectId) return store.state.projects.passwordPerProject[this.projectId] },
        ...mapGetters('login', ['isCustomerAuthorized']),
    },
    // Lifecycle hooks
    mounted() {
        console.log(`Dataset Editor View Mounting: ${this.datasetId}`);
        // Connect to AWS signalling server through Web Socket to get authorization data
        this.loadingOverlay = {show: true, text: 'Authorizing...'};
        connectWs(this.onWsConnected);
        this.helpInfo = 'Click on any image to enlarge';
        this.showBackButton = this.$router.options.history.state.back != null ? true : false;
        this.datasetName = getDatasetNameFromDatasetId(this.datasetId);
        this.projectId = getProjectIdFromDatasetId(this.datasetId);
        this.projectName = getProjectNameFromProjectId(this.projectId);
        document.title = `Dataset-${this.datasetName}`; // Set Page title
        // this.refreshDatasetsData();
        // Open spinner overlay
        //this.loadingOverlay = {show: true, text: `Getting images`}; 

        window.addEventListener("resize", this.onWindowResize);        
          
        // Join and Leave Room when visibility change
        document.onvisibilitychange = () => {
            if (document.visibilityState === "visible") {
                console.log('Dataset Editor page being visible');
                
            } else { //Hiden
                console.log('Dataset Editor page being hidden');                
            }
        };
        // Prevent scroll being driven by keys
        let thisis = this;
        this.handleKeyDown = (event) => { 
            // console.log('Key Pressed: ' + event.code);           
            console.log(`Image Modal visibility: ${this.imgModalIsShow}`);
            
            if (thisis.imgModalIsShow) {
                if (event.code === "ArrowUp") this.onKeyUpInImgModal(event);
                else if (event.code === "ArrowDown") this.onKeyDownInImgModal(event);
                // else if (event.code === "ArrowRight") this.onKeyRightInImgModal(event);
                // else if (event.code === "ArrowLeft") this.onKeyLeftInImgModal(event);
                else if(event.code === "Escape") this.closeImgModal();    // Close modal windows if 'Escape' key is pressed
            } else if (thisis.filterModalShow) { // Filter modal open
                if (event.code === "Enter") this.onApplyFilter();
                else if (event.code === "Escape") this.onCancelFilter();      
            } else if (thisis.sortModalShow) { // Sort modal open
                if (event.code === "Enter") this.onApplySort();
                else if (event.code === "Escape") this.onCancelSort();                          
            } else if (thisis.uploadModalShow) { // Upload files modal open
                if (event.code === "Enter") this.uploadFiles();
                else if (event.code === "Escape") thisis.uploadModalShow = false;                          
            } else if (thisis.downloadModalShow) { // Download files modal open
                if (event.code === "Enter") this.downloadFiles();
                else if (event.code === "Escape") thisis.downloadModalShow = false;                          
            } else if (thisis.copyModalShow) { // Download files modal open
                if (event.code === "Enter") this.copyImages();
                else if (event.code === "Escape") thisis.copyModalShow = false;                          
            } else { // No modal window shown
                if (event.code === "ArrowRight") this.onKeyRight(event);
                else if (event.code === "ArrowLeft") this.onKeyLeft(event);
            }      
        };
        window.addEventListener("keydown", this.handleKeyDown, false);
        // Start getting data
        this.cleanFiltersSorts = true; // force cleand filters and sort
        // this.refreshImages();
        // Scroll event
        addEventListener("scroll", this.onScroll);
    },
    unmounted() {
        console.log('Dataset Editor View Unmounted')
        window.removeEventListener("resize", this.onWindowResize);
        window.removeEventListener("scroll", this.onScroll);  
        window.removeEventListener("keydown", this.handleKeyDown, false);      
        removeOnConnectedCB(this.onWsConnected); // Remove callback for onWsConnected
    },   
})
export default class DatasetEditor extends Vue {}
</script>

<style>

</style>

<style scoped>
    .scroller {
    height: 100%;
    }

    .cam_row {
    display: flex;
    align-items: center;
    }

    .hide {
        display: none;
    }

    .filterModalContent {
        width: 25em;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px;
        margin-top: 100px;
        border-radius: 20px;
    }

    .sortModalContent, .copyModalContent, .uploadModalContent, .downloadModalContent{
        width: 25em;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px;
        margin-top: 100px;
        border-radius: 20px;
    }
    .selectImagesModalContent {
        width: 25em;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px;
        margin-top: 100px;
        border-radius: 20px;
    }

    /* button {
        padding: 10px 20px;
        margin-top: 10px;
        border: none;
        color: white;
    }} */

    .imgModal {
        z-index: 2000;
    }

    /* Center down content */
    .center-down {
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>


