
// Store module for Projects related info

const state = {
    projectList: null, // Dict with key = projectId and value the project data
    projectData: {}, // Dict with key = projectId and value the project data
    datasetsOfProject: null, // Dict with key = projectId and value the dataset object list
    enginesOfProject: null, // Dict with key = projectId and value the engine object list 
    machinesOfProject: null, // Dict with key = projectId and value the machine object list
    datasetData: {}, // Dict with key = datasetId  and value the data for dataset
    imgsOfDataset: {}, // Dict with key = datasetId and value the list of ImgData
    historyBlock:{}, // Block of images from project history
    passwordPerProject: {}, // Password per projectId
}

const getters = {
}

const actions = {    
}

const mutations = {
    setProjectList(state, data) {
        state.projectList = data;
        for (let prjData of data) {
            let projectId = prjData.ProjectId;
            state.projectData[projectId] = prjData;
        }
    },
    setProjectPassword(state, params) {
        let projectId = params.ProjectId;
        state.passwordPerProject[projectId] = params.Password;
    },
    setDatasetsOfProject(state, params) {
        let projectId = params.ProjectId;
        let data = params.Data;
        if (state.datasetsOfProject === null) state.datasetsOfProject = {}; // Create empty if does not exist
        console.log(`setDatasetsOfProject. projectId: ${projectId}, Data: ${JSON.stringify(data)}`);
        state.datasetsOfProject[projectId] = data;
    },
    setEnginesOfProject(state, params) {
        let projectId = params.ProjectId;
        let data = params.Data;
        if (state.enginesOfProject === null) state.enginesOfProject = {}; // Create empty if does not exist
        console.log(`Data: ${JSON.stringify(data)}`)
        // Sort 
        data.sort(function(a, b) {
            var keyA = `${a.EngineVer}`;
            var keyB = `${b.EngineVer}`;
            //console.log(`keyA: ${keyA}, keyB: ${keyB}`)
            return keyA.localeCompare(keyB, undefined, {numeric: true, sensitivity: 'base'});
        });
        state.enginesOfProject[projectId] = {'List': [], 'Dict': {}}
        state.enginesOfProject[projectId]['List'] = data;
        // Dict
        for (let engData of data) {
            state.enginesOfProject[projectId]['Dict'][engData.EngineId] = engData;
        }
    },
    setMachinesOfProject(state, params) {
        let projectId = params.ProjectId;
        let data = params.Data;
        if (state.machinesOfProject === null) state.machinesOfProject = {}; // Create empty if does not exist
        state.machinesOfProject[projectId] = data;
    },
    setImgsOfDataset(state, params) {
        let datasetId = params.DatasetId;
        let data = params.Data;
        console.log(`setImgsOfDataset. datasetId: ${datasetId}, Data: ${JSON.stringify(data)}`);
        state.datasetData[datasetId] = data.DstData;
        state.imgsOfDataset[datasetId] = data.ImgList;
    },
    setDatasetData(state, params) {
        let datasetId = params.DatasetId;
        let data = params.Data;
        console.log(`setDatasetData. datasetId: ${datasetId}, Data: ${JSON.stringify(data)}`);
        state.datasetData[datasetId] = data.DstData;
    },
    setHistoryBlockData(state, params) {
        let projectId = params.ProjectId;
        let data = params.Data;
        state.projectData[projectId] = data.PrjData;
        state.historyBlock[projectId] = data.ImgList;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};