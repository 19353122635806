// Created by SPe on 06/10/23
// Landing page for Root Management
<template>
  <div>
    <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
    <div class="min-h-screen flex flex-col">
      <!-- Header -->
      <header class="bg-red-50">
        <NavBar 
          :servConnected="servConnected" 
        />
      </header>

      <div class="flex-1 flex flex-col sm:flex-row">
        <!-- Main content -->
        <main class="flex-1 bg-gray-200 py-1 px-1">
          <!-- Customers Table -->
          <div v-if="showCustomers">
            <div class="px-0"> 
              <vue-table-lite
                :title= "'Customers'"
                :is-static-mode="true"
                :columns="customersTable.columns"
                :rows="customersTable.rows"
                :total="customersTable.totalRecordCount"
                @is-finished="customersTableLoadingFinish"
              ></vue-table-lite>
            </div>
          </div> 
          <!-- Users Table -->
          <div v-if="showUsers">
            <div class="px-0"> 
              <vue-table-lite
                :title= "'Users'"
                :is-static-mode="true"
                :columns="usersTable.columns"
                :rows="usersTable.rows"
                :total="usersTable.totalRecordCount"
                @is-finished="usersTableLoadingFinish"
              ></vue-table-lite>
            </div>
          </div>         
          <!-- Projects Table -->
          <div v-if="showProjects">
            <div class="px-0"> 
              <vue-table-lite
                :title= "'Projects'"
                :is-static-mode="true"
                :columns="projectsTable.columns"
                :rows="projectsTable.rows"
                :total="projectsTable.totalRecordCount"
                @is-finished="projectsTableLoadingFinish"
              ></vue-table-lite>
            </div>
          </div>         
          <!-- Devices Table -->
          <div v-if="showDevices">
            <div class="px-0"> 
              <vue-table-lite
                :title= "'Devices'"
                :is-static-mode="true"
                :columns="devicesTable.columns"
                :rows="devicesTable.rows"
                :total="devicesTable.totalRecordCount"
                :page-size="50"
                @is-finished="devicesTableLoadingFinish"
              ></vue-table-lite>
            </div>
          </div>
        </main>
        <!-- Left Side bar -->
        <nav class="order-first sm:w-25 md:w-25 bg-gray-100">
          <!-- MENU -->
          <ul class="text-left text-sm  ml-1 my-2">
            <li>
              <ul class="ml-3">
                  <hr class="my-2">
                  <li class=" hover:text-blue-500 cursor-pointer" @click="()=>{menuSelected='Customers'; cleanContent(); showCustomers=true;}" :class="{'text-blue-600 font-bold' : showCustomers}">Customers</li>
                  <hr class="my-2">
                  <li class=" hover:text-blue-500 cursor-pointer" @click="()=>{menuSelected='Users'; cleanContent(); showUsers=true;}" :class="{'text-blue-600 font-bold' : showUsers}">Users</li>
                  <hr class="my-2">
                  <li class=" hover:text-blue-500 cursor-pointer" @click="()=>{menuSelected='Projects'; cleanContent(); showProjects=true;}" :class="{'text-blue-600 font-bold' : showProjects}">Projects</li>
                  <hr class="my-2">
                  <li class=" hover:text-blue-500 cursor-pointer" @click="()=>{menuSelected='Devices'; cleanContent(); showDevices=true;}" :class="{'text-blue-600 font-bold' : showDevices}">Devices</li>
                  <hr class="my-2">
              </ul>
            </li>            
          </ul>
        </nav>
      </div>
      <!-- Footernbar -->
      <Footer />
    </div>

    <!-- Modal Window for request DevId for User registration -->
    <Modal
        v-model="requestDevIdModalShow"
        ref="modal"
    >

    <GetDevIdForm :userId="userId" @getDevIdFormSubmitted="getDevIdFormSubmitted"></GetDevIdForm>

    </Modal>
  </div>  
</template>

<script>
import { Options, Vue } from 'vue-class-component'; 
import { mapState } from 'vuex'
import DeviceRKPSummary from '@/components/DeviceRKPSummary.vue';
import ProjectSummary from '@/components/ProjectSummary.vue';  
import LoadingOverlay from '@/components/LoadingOverlay.vue'; 
import NewProjectForm from '@/components/NewProjectForm.vue';
import GetDevIdForm from '@/components/GetDevIdForm.vue';
import VueTableLite from "vue3-table-lite";
import store from '@/store/index.js';
import { buildEngineId } from '@/library/utils.js';
import { connect as connectWs, close as closeWs, doKeepWsConnected, registerWsMessageCallBack, sendWsMessage, removeOnConnectedCB } from '@/library/websocket.js';
import { sendMessageUnified } from '@/library/client-unified-send'
//import { subscribe }from '@/library/notification-subscription.js';
//import { connect as connectWrtc, close as closeWrtc } from '@/library/webrtc'
import { getRootData, getCustomersData, getUsersData, getProjectsData, getDevicesData, removeProject, removeHistory, cloneProject, cloneEngine, unregisterDevice } from '@/library/http-api-client'
//import { registerUnifiedMessageCallBack } from '@/library/client-unified-receive'
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import device from '@/library/detect-device';

@Options({
  components: {
    LoadingOverlay,
    NavBar,
    Footer,
    DeviceRKPSummary,
    ProjectSummary,
    NewProjectForm,
    GetDevIdForm,
    VueTableLite,
  },
  data: function(){
    return {
        wsConnected: false, // Whether WebSocket to signalling server is conneced or not
        loadingOverlay: {show: false, text: 'Loading'},
        
        showCustomers: false,
        showUsers: false,
        showProjects: false,
        showDevices: false,  

        menuSelected: null,

        requestDevIdModalShow: false,
  
        cloneProjectIconPath: require('@/assets/pics/clone-project.png'),
        removeHistoryIconPath: require('@/assets/pics/history-clean.png'),
        cloneEngineIconPath: require('@/assets/pics/duplicate.png'),
        openTerminalIconPath: require('@/assets/pics/terminal.png'),

        customersTable: {
          columns: [
            { label: "Customer", field: "Customer", width: "3%", sortable: true, isKey: true, },
            { label: "Creation", field: "CreationTime", width: "3%", sortable: true, isKey: false, },
            { label: "# Users", field: "NumUsers", width: "3%", sortable: true, isKey: false, },
            { label: "# Projects", field: "NumProjects", width: "3%", sortable: true, isKey: false, },
            { label: "# Devices", field: "NumDevices", width: "3%", sortable: true, isKey: false, },     
            { label: 'Actions', width: '3%', display: row => {
              return  `<table><tr>` + 
                      `<td><i id="RemoveCustomer-${row.Customer}" class=" is-rows-el bi bi-x-square text-2xl px-1 float-none cursor-pointer"  title="Remove Customer"></i></td>` + 
                      `</tr></table>`;
            }},          
          ],
          rows: [],
          totalRecordCount: 0,
        },
        usersTable: {
          columns: [
            { label: "Customer", field: "Customer", width: "3%", sortable: true, isKey: true, },
            { label: "User", field: "UserId", width: "3%", sortable: true, isKey: true, },
            { label: "Creation", field: "CreationTime", width: "3%", sortable: true, isKey: false, },
            { label: "Last connection", field: "LastConnectionTime", width: "3%", sortable: true, isKey: false, },
            { label: "# Projects", field: "NumProjects", width: "3%", sortable: true, isKey: false, },
            { label: 'Role', field: 'Role', width: '3%', sortable: true, isKey: false, }, 
            { label: 'Credits', field: 'TrainingCredits', width: '3%', sortable: true, isKey: false, },   
            { label: 'Actions', width: '3%', display: row => {
              return `<table><tr>` + 
                     `<td><i id="RemoveUser->${row.UserId}" class="is-rows-el bi bi-x-square text-2xl px-1 float-none cursor-pointer"  title="Remove User"></i></td>` + 
                     `<td><i id="ChangeUserRole->${row.UserId}" class="is-rows-el bi bi-person-fill-gear text-2xl px-1 float-none cursor-pointer"  title="Chnage User Role"></i></td>` + 
                     `<td><i id="AddCredits->${row.UserId}" class="is-rows-el bi bi-coin text-2xl px-1 float-none cursor-pointer" title="Add Credits"></i></td>` + 
                     `</tr></table>`;
            }},        
          ],
          rows: [],
          totalRecordCount: 0,
        },
        projectsTable: {
          columns: [
            { label: "Customer", field: "Customer", width: "3%", sortable: true, isKey: true, },
            { label: "ProjectId", field: "ProjectId", width: "3%", sortable: true, isKey: true, },
            { label: "Creator", field: "Creator", width: "3%", sortable: true, isKey: false, },
            { label: "Defects", field: "Defects", width: "3%", sortable: true, isKey: false, },
            { label: "Creation", field: "CreationTime", width: "3%", sortable: true, isKey: false, },
            { label: "Last Eng. Ver.", field: "LastEngineVer", width: "3%", sortable: true, isKey: false, },
            { label: 'Actions', width: '3%', display: row => {
              return `<table><tr>` + 
                     `<td><i id="RemoveProject->${row.ProjectId}" class="is-rows-el bi bi-x-square text-2xl px-1 float-left cursor-pointer"  title="Remove Project"></i></td>` + 
                     `<td><img id="RemoveHistory->${row.ProjectId}" class="is-rows-el icon h-6 float-none px-2 cursor-pointer" src="${this.removeHistoryIconPath}" title="Remove all history images for the Project"/></td>` +
                     `<td><img id="CloneProject->${row.ProjectId}" class="is-rows-el icon h-6 float-none px-2 cursor-pointer" src="${this.cloneProjectIconPath}" title="Clone Project to other User"/></td>` +
                     `<td><img id="CloneEngine->${row.ProjectId}" class="is-rows-el icon h-6 float-right px-2 cursor-pointer" src="${this.cloneEngineIconPath}" title="Clone Engine to other Project"/></td>` +
                     `</tr></table>`;
            }},
          ],
          rows: [],
          totalRecordCount: 0,
        },
        devicesTable: {
          columns: [
            { label: "Distributor", field: "Distributor", width: "3%", sortable: true, isKey: false, },
            { label: "Customer", field: "Customer", width: "3%", sortable: true, isKey: false, },
            { label: "Name", field: "Name", width: "3%", sortable: true, isKey: true, },
            { label: "DevId", field: "DevId", width: "3%", sortable: true, isKey: true, },
            { label: "Creation", field: "CreationTime", width: "3%", sortable: true, isKey: false, },
            { label: "IP", field: "DeviceIp", width: "3%", sortable: true, isKey: false, },
            { label: "Last connection", field: "LastConnectionTime", width: "3%", sortable: true, isKey: false, },
            { label: "Hw Type", field: "HwType", width: "3%", sortable: true, isKey: false, },
            { label: "# Cams.", field: "NumCameras", width: "1%", sortable: true, isKey: false, },
            { label: "Sw Package", field: "SwPackage", width: "3%", sortable: true, isKey: false, },
            { label: "Engine", field: "EngineId", width: "3%", sortable: true, isKey: false, },
            { label: "License", field: "LicenseExpDate", width: "3%", sortable: true, isKey: false, },      
            { label: 'Actions', width: '3%', display: row => {
              return `<table><tr>` + 
                     `<td><i id="UnregisterDevice->${row.DevId}" class="is-rows-el bi bi-x-square text-2xl px-1 float-none cursor-pointer" title="Unregister Device"></i></td>` + 
                     `<td><i id="ChangeLicense->${row.DevId}" class="is-rows-el bi bi-calendar2-plus-fill text-2xl px-1 float-none cursor-pointer" title="Change License Expiration Date"></i></td>` + 
                     `<td><i id="CheckSwUpgrade->${row.DevId}" class="is-rows-el bi bi-gift text-2xl px-1 float-none cursor-pointer" title="Check for Software Upgrade"></i></td>` + 
                     `<td><i id="OpenTerminal->${row.DevId}" class="is-rows-el bi bi-terminal text-2xl px-1 float-none cursor-pointer" title="Open Terminal"></i></td>` + 
                     `</tr></table>`;
            }},      
          ],
          rows: [],
          totalRecordCount: 0,
        },
        RemoveCustomerEventListener: {}, // Event listener for RemoveCustomer
        RemoveUserEventListener: {}, // Event listener for RemoveUser
        ChangeUserRoleEventListener: {}, // Event listener for ChangeUserRole
        AddCreditsEventListener: {}, // Event listener for AddCredits
        RemoveProjectEventListener: {}, // Event listener for RemoveProject
        RemoveHistoryEventListener: {}, // Event listener for RemoveHistory
        CloneProjectEventListener: {}, // Event listener for CloneProject
        CloneEngineEventListener: {}, // Event listener for CloneEngine
        UnregisterDeviceEventListener: {}, // Event listener for UnregisterDevice
        ChangeLicenseEventListener: {}, // Event listener for ChangeLicense
        CheckSwUpgradeEventListener: {}, // Event listener for CheckSwUpgrade
        OpenTerminalEventListener: {}, // Event listener for OpenTerminal

    }
  },
  watch: {
    menuSelected(newValue, oldValue) {
      console.log(`menuSelected changed from ${oldValue} to ${newValue}`);
      store.commit('login/setRootMenuSelected', newValue);
    }
  },
  props: [],
  methods: {
    onWsConnected() {
      console.log(`WebSocket connected`);
      this.wsConnected = true;
      doKeepWsConnected();
      this.refreshRootData();
    },
    onSetAuthorizationCallBack() {
      let authorized = store.getters['login/isAuthorized'];
      if (authorized) {
        console.log(`onSetAuthorizationCallBack. User is authorized: ${authorized}`);
        this.loadingOverlay = {show: false};
        this.requestDevIdModalShow = false;
      } else {
        console.log(`onSetAuthorizationCallBack. User is not authorized: ${authorized}`);
        this.loadingOverlay = {show: false};
        this.requestDevIdModalShow = true;
      }
    },
    onWsClosed() {
      console.log(`WebSocket closed`);
    },
    onNewCustomersData() {
      console.log(`onNewCustomerData`);
      this.loadingOverlay.show = false;
      // Compute additional data
      for (const customer of this.customersList) {
        customer.CreationTime = this.formatDateString(new Date(customer.CreationTime))
        customer.NumUsers = customer.Users.length;
        customer.NumProjects = customer.Projects.length;
        customer.NumDevices = customer.Devices.length;
      }
      this.customersTable.rows = this.customersList;
      this.customersTable.totalRecordCount = this.customersList.length;
    },
    onNewUsersData() {
      console.log(`onNewUsersData`);
      this.loadingOverlay.show = false;
      // Compute additional data
      for (const user of this.usersList) {
        user.CreationTime = this.formatDateString(new Date(user.CreationTime));
        user.LastConnectionTime = this.formatDateString(new Date(user.LastConnectionTime));
        user.NumProjects = (user.Projects && user.Projects.length) || 0;
      }
      this.usersTable.rows = this.usersList;
      this.usersTable.totalRecordCount = this.usersList.length;
    },
    onNewProjectsData() {
      console.log(`onNewProjectsData`);
      this.loadingOverlay.show = false;
      this.projectsTable.rows = this.projectsList;
      // Compute additional data
      for (const project of this.projectsList) {
        project.CreationTime = this.formatDateString(new Date(project.CreationTime || project.CreaDate));
        project.LastEngineVer = project.LastEngineVer || 'V?';
      }
      this.projectsTable.totalRecordCount = this.projectsList.length;
    },
    onNewDevicesData() {
      console.log(`onNewDevicesData`);
      this.loadingOverlay.show = false;
      // Compute additional data
      for (const device of this.devicesList) {
        device.CreationTime = this.formatDateString(new Date(device.CreationTime));
        device.LastConnectionTime = this.formatDateString(new Date(device.LastConnectionTime));
      }
      this.devicesTable.rows = this.devicesList;
      this.devicesTable.totalRecordCount = this.devicesList.length;
    },
    cleanContent() {
      console.log(`cleanContent`);
      this.showCustomers = false;
      this.showUsers = false;
      this.showProjects = false;
      this.showDevices = false;

      this.loadingOverlay = {show: true, text: 'Loading ...'};

      if (this.menuSelected === 'Customers') {
        getCustomersData(this.userId, store.state.root.rootData.Customers, this.onNewCustomersData);
      } else if (this.menuSelected === 'Users') {
        getUsersData(this.userId, store.state.root.rootData.Users, this.onNewUsersData);
      } else if (this.menuSelected === 'Projects') {
        getProjectsData(this.userId, store.state.root.rootData.Projects, this.onNewProjectsData);
        getUsersData(this.userId, store.state.root.rootData.Users, this.onNewUsersData);
      } else if (this.menuSelected === 'Devices') {
        getDevicesData(this.userId, store.state.root.rootData.Devices, this.onNewDevicesData);
      }

    },
    onOpenProjects() {
      console.log(`onOpenProjects`);
      let routeData = this.$router.resolve({path: `/projects`});
      window.open(routeData.href, '_blank');
    },    

    async getDevIdFormSubmitted(devId) {
      console.log(`getDevIdFormSubmitted: ${devId}`);

      if (devId) {
        console.log(`getDevIdFormSubmitted: ${devId}`);
        this.loadingOverlay = {show: true, text: 'Connecting ...'};
        this.requestDevIdModalShow = false;
        // Store devId in login store
        store.commit('login/setRegDevId', devId);
        closeWs(); // Close Websocket connection
        // Small sleep to allow websocket to close and to prevent too many tries of DevId
        // await new Promise(r => setTimeout(r, 10000));
        connectWs(this.onWsConnected, null, this.onWsClosed); // Reconnect websocket
      } else {
        // Redirect to logout page
        this.$router.push({path: `/logout`});
      }
      
    },

    refreshRootData() {
      console.log(`refreshRootData`);
      //this.loadingOverlay = {show: true, text: 'Loading Projects'};
      this.projectsInfoMsg = 'Getting Projects'
      // Get Projects Data for the user
      getRootData(this.userId, this.onRootData)
    },

    onRootData() {
      console.log(`onRootData`);
      this.loadingOverlay = {show: false};
      this.projectsInfoMsg = '';

      this.loadingOverlay = {show: true, text: 'Loading ...'};

      // Get data for selected menu
      if (this.showCustomers) getCustomersData(this.userId, store.state.root.rootData.Customers, this.onNewCustomersData);
      else if (this.showUsers) getUsersData(this.userId, store.state.root.rootData.Users, this.onNewUsersData);
      else if (this.showProjects) {
        getProjectsData(this.userId, store.state.root.rootData.Projects, this.onNewProjectsData);
        getUsersData(this.userId, store.state.root.rootData.Users, this.onNewUsersData);
      }
      else if (this.showDevices) getDevicesData(this.userId, store.state.root.rootData.Devices, this.onNewDevicesData);
    },

    customersTableLoadingFinish(elements) {
      console.log(`customersTableLoadingFinish: ${elements} (${elements.length}) elements)`);
      Array.prototype.forEach.call(elements, element => {
        console.log(`element id: ${JSON.stringify(element.id)}`);
        if (element.id.startsWith('RemoveCustomer-')) {
          console.log(`Adding addEventListener to element: ${JSON.stringify(element.id)}`);
          const customer = element.id.split('->')[1];
          element.removeEventListener('click', this.RemoveCustomerEventListener[customer]); // Remove previous event listener
          if(!this.RemoveCustomerEventListener[customer])  this.RemoveCustomerEventListener[customer] = () => {
            this.removeCustomer(customer);
          }
          element.addEventListener('click', this.RemoveCustomerEventListener[customer]);
        }
      });
    },
    removeCustomer(customer) {
      console.log(`removeCustomer: ${customer}`);
      const numUsers = this.customerData(customer).NumUsers;
      const numProjects = this.customerData(customer).NumProjects;
      const numDevices = this.customerData(customer).NumDevices;
      if (numUsers > 0 || numProjects > 0 || numDevices > 0) {
        let message = ''
        if (numUsers > 0) message += `Customer ${customer} has ${numUsers} User(s). Remove User(s) first\n`;
        if (numProjects > 0) message += `Customer ${customer} has ${numProjects} Project(s). Remove Project(s) first\n`;
        if (numDevices > 0) message += `Customer ${customer} has ${numDevices} Device(s). Remove Device(s) first\n`;
        alert(message);
        return;
      }
      // No users, projects or devices. Remove customer
      if (confirm(`Are you sure you want to remove customer ${customer}?`)) {
        // send RemoveCustomer message to backend
        sendWsMessage('RemoveCustomer', {UserId: this.userId, Customer: customer});
        setTimeout(() => { this.refreshRootData(); }, 1000);
      }
    },

    usersTableLoadingFinish(elements) {
      console.log(`usersTableLoadingFinish: ${elements} (${elements.length}) elements)`);
      Array.prototype.forEach.call(elements, element => {
        console.log(`element id: ${JSON.stringify(element.id)}`);
        if (element.id.startsWith('RemoveUser-')) {
          console.log(`Adding addEventListener to element: ${JSON.stringify(element.id)}`);
          const tgtUserId = element.id.split('->')[1];
          element.removeEventListener('click', this.RemoveUserEventListener[tgtUserId]); // Remove previous event listener
          if(!this.RemoveUserEventListener[tgtUserId])  this.RemoveUserEventListener[tgtUserId] = () => {
            this.removeUser(tgtUserId);
          }
          element.addEventListener('click', this.RemoveUserEventListener[tgtUserId]);
        }
        if (element.id.startsWith('ChangeUserRole-')) {
          console.log(`Adding addEventListener to element: ${JSON.stringify(element.id)}`);
          const tgtUserId = element.id.split('->')[1];
          element.removeEventListener('click', this.ChangeUserRoleEventListener[tgtUserId]); // Remove previous event listener
          if(!this.ChangeUserRoleEventListener[tgtUserId])  this.ChangeUserRoleEventListener[tgtUserId] = () => {
            this.changeUserRole(tgtUserId);
          }
          element.addEventListener('click', this.ChangeUserRoleEventListener[tgtUserId]);
        }
        if (element.id.startsWith('AddCredits-')) {
          console.log(`Adding addEventListener to element: ${JSON.stringify(element.id)}`);
          const tgtUserId = element.id.split('->')[1];
          element.removeEventListener('click', this.AddCreditsEventListener[tgtUserId]); // Remove previous event listener
          if(!this.AddCreditsEventListener[tgtUserId])  this.AddCreditsEventListener[tgtUserId] = () => {
            this.addCredits(tgtUserId);
          }
          element.addEventListener('click', this.AddCreditsEventListener[tgtUserId]);
        }
      });
    },
    removeUser(tgtUserId) {
      console.log(`removeUser: ${tgtUserId}`);
      const numProjects = this.usersData(tgtUserId).NumProjects;
      if (numProjects > 0) {
        const message = `User ${tgtUserId} has ${numProjects} projects. Remove projects first\n`;
        alert(message);
        return;
      }
      // No projects. Remove user
      if (confirm(`Are you sure you want to remove User ${tgtUserId}?`)) {
        // send RemoveUser message to backend
        sendWsMessage('RemoveUser', {UserId: this.userId, TgtUserId: tgtUserId});
        setTimeout(() => { this.refreshRootData(); }, 1000);
      }
    },
    changeUserRole(tgtUserId) {
      console.log(`changeUserRole: ${tgtUserId}`);
      // Open modal window to get new role
      const newRole = prompt(`New role for user ${tgtUserId}? (Admin, User, Viewer)`);
      if (newRole === null) return;
      // Check that role is valid
      if (! ['Admin', 'User', 'Viewer'].includes(newRole)) {
        alert(`Role must be Admin, User or Viewer`);
        return;
      }
      // send ChangeUserRole message to backend
      console.log(`Changing role of user ${tgtUserId} to ${newRole}`);
      sendWsMessage('ChangeUserRole', {UserId: this.userId, TgtUserId: tgtUserId, Role: newRole});
      setTimeout(() => { getUsersData(this.userId, store.state.root.rootData.Users, this.onNewUsersData); }, 1000);
    },
    addCredits(tgtUserId) {
      console.log(`addCredits: ${tgtUserId}`);
      // Open modal window to get credits to add
      let credits = prompt(`How many credits do you want to add to user ${tgtUserId}?`);
      if (credits === null) return;
      // Check that is a number
      if (isNaN(credits) || isNaN(parseFloat(credits))) {
        alert(`Credits must be a number`);
        return;
      }
      // Convert to number
      credits = Number(credits);
      // send AddCredits message to backend
      console.log(`Adding ${credits} credits to user ${tgtUserId}`);
      sendWsMessage('AddCredits', {UserId: this.userId, TgtUserId: tgtUserId, Credits: credits});
      // Refresh user data in one second
      setTimeout(() => { getUsersData(this.userId, store.state.root.rootData.Users, this.onNewUsersData); }, 1000);
    },

    projectsTableLoadingFinish(elements) {
      console.log(`projectsTableLoadingFinish: ${elements} (${elements.length}) elements)`);
      Array.prototype.forEach.call(elements, element => {
        console.log(`element id: ${JSON.stringify(element.id)}`);
        if (element.id.startsWith('RemoveProject-')) {
          console.log(`Adding addEventListener to element: ${JSON.stringify(element.id)}`);
          const projectId = element.id.split('->')[1];
          element.removeEventListener('click', this.RemoveProjectEventListener[projectId]); // Remove previous event listener
          if(!this.RemoveProjectEventListener[projectId])  this.RemoveProjectEventListener[projectId] = () => {
            this.removeProject(projectId);
          }
          element.addEventListener('click', this.RemoveProjectEventListener[projectId]);
        }
        if (element.id.startsWith('RemoveHistory-')) {
          console.log(`Adding addEventListener to element: ${JSON.stringify(element.id)}`);
          const projectId = element.id.split('->')[1];
          element.removeEventListener('click', this.RemoveHistoryEventListener[projectId]); // Remove previous event listener
          if(!this.RemoveHistoryEventListener[projectId])  this.RemoveHistoryEventListener[projectId] = () => {
            this.removeHistory(projectId);
          }
          element.addEventListener('click', this.RemoveHistoryEventListener[projectId]);
        }
        if (element.id.startsWith('CloneProject-')) {
          console.log(`Adding addEventListener to element: ${JSON.stringify(element.id)}`);
          const projectId = element.id.split('->')[1];
          element.removeEventListener('click', this.CloneProjectEventListener[projectId]); // Remove previous event listener
          if(!this.CloneProjectEventListener[projectId])  this.CloneProjectEventListener[projectId] = () => {
            this.cloneProject(projectId);
          }
          element.addEventListener('click', this.CloneProjectEventListener[projectId]);
        }
        if (element.id.startsWith('CloneEngine-')) {
          console.log(`Adding addEventListener to element: ${JSON.stringify(element.id)}`);
          const projectId = element.id.split('->')[1];
          element.removeEventListener('click', this.CloneEngineEventListener[projectId]); // Remove previous event listener
          if(!this.CloneEngineEventListener[projectId])  this.CloneEngineEventListener[projectId] = () => {
            this.cloneEngine(projectId);
          }
          element.addEventListener('click', this.CloneEngineEventListener[projectId]);
        }
      });
    },
    onRequestResult(result) {
      console.log(`onRequestResult: ${JSON.stringify(result)}`);
      
    },
    removeProject(projectId) {
      console.log(`removeProject: ${projectId}`);
      // Remove project if confirmed
      if (confirm(`Are you sure you want to remove Project ${projectId}?`)) {
        // Call http-api-client to remove project
        removeProject(this.userId, projectId);
        setTimeout(() => { this.refreshRootData(); }, 3000);
      }
    },
    removeHistory(projectId) {
      console.log(`removeHistory: ${projectId}`);
      // Remove project if confirmed
      if (confirm(`Are you sure you want to remove all history images for Project ${projectId}?`)) {
        // Call http-api-client to remove history
        removeHistory(this.userId, projectId);
      }
    },
    cloneProject(projectId) {
      console.log(`cloneProject: ${projectId}`);
      // Open modal window to get new customer
      const tgtUserId = prompt(`New User for Project ${projectId}?`);
      if (tgtUserId === null) return;
      // Check that customer exists
      if (! this.usersList.find(c => c.UserId === tgtUserId)) {
        alert(`User ${tgtUserId} does not exist in list: ${JSON.stringify(this.usersList)}`);
        return;
      }
      // send CloneProject request to backend
      console.log(`Cloning Project ${projectId} to User ${tgtUserId}`);
      cloneProject(this.userId, projectId, tgtUserId, this.onRequestResult);
      // Refresh project data in one second
      setTimeout(() => { this.refreshRootData(); }, 3000);
    },
    cloneEngine(projectId) {
      console.log(`cloneEngine: ${projectId}`);
      // OPen modal window to get Engine Version
      const engineVer = prompt(`Engine Version of Project ${projectId} to clone? (e.g. V5)`);
      if (engineVer === null) return;
      // Check that engine version is valid. 'V' + number
      const engineVerRegex = /^V\d+$/;
      if (! engineVerRegex.test(engineVer)) {
        alert(`Engine Version must be V + number`);
        return;
      }
      // Open modal window to get target project
      const tgtProjectId = prompt(`Target Project Id for Engine ${projectId}-${engineVer} to clone?`);
      if (tgtProjectId === null) return;
      // Check that project exists
      if (! this.projectsList.find(p => p.ProjectId === tgtProjectId)) {
        alert(`Project ${tgtProjectId} does not exist`);
        return;
      }
      // send CloneEngine request to backend
      let engineId = buildEngineId(projectId, engineVer);
      console.log(`Cloning Engine ${projectId}-${engineVer} (${engineId}) to Project ${tgtProjectId}`);
      cloneEngine(this.userId, projectId, engineId, tgtProjectId);
    },

    devicesTableLoadingFinish(elements) {
      console.log(`devicesTableLoadingFinish: ${elements} (${elements.length}) elements)`);
      Array.prototype.forEach.call(elements, element => {
        console.log(`element id: ${JSON.stringify(element.id)}`);
        if (element.id.startsWith('UnregisterDevice-')) {
          console.log(`Adding addEventListener to element: ${JSON.stringify(element.id)}`);
          const devId = element.id.split('->')[1];
          element.removeEventListener('click', this.UnregisterDeviceEventListener[devId]); // Remove previous event listener
          if (!this.UnregisterDeviceEventListener[devId]) this.UnregisterDeviceEventListener[devId] = () => {
            this.unregisterDevice(devId);
          }
          element.addEventListener('click', this.UnregisterDeviceEventListener[devId]);
        }
        if (element.id.startsWith('ChangeLicense-')) {
          console.log(`Adding addEventListener to element: ${JSON.stringify(element.id)}`);
          const devId = element.id.split('->')[1];
          element.removeEventListener('click', this.ChangeLicenseEventListener[devId]); // Remove previous event listener
          if (!this.ChangeLicenseEventListener[devId]) this.ChangeLicenseEventListener[devId] = () => {
            this.changeLicense(devId);
          }
          element.addEventListener('click', this.ChangeLicenseEventListener[devId]);
        }
        if (element.id.startsWith('CheckSwUpgrade-')) {
          console.log(`Adding addEventListener to element: ${JSON.stringify(element.id)}`);
          const devId = element.id.split('->')[1];
          element.removeEventListener('click', this.CheckSwUpgradeEventListener[devId]); // Remove previous event listener
          if (!this.CheckSwUpgradeEventListener[devId]) this.CheckSwUpgradeEventListener[devId] = () => {
            this.checkSoftwareUpgrade(devId);
          }
          element.addEventListener('click', this.CheckSwUpgradeEventListener[devId]);
        }
        if (element.id.startsWith('OpenTerminal-')) {
          console.log(`Adding addEventListener to element: ${JSON.stringify(element.id)}`);
          const devId = element.id.split('->')[1];
          element.removeEventListener('mouseup', this.OpenTerminalEventListener[devId]); // Remove previous event listener
          if (!this.OpenTerminalEventListener[devId]) this.OpenTerminalEventListener[devId] = (e) => {
            this.openTerminal(devId);
            e.preventDefault();
          }
          element.addEventListener('mouseup', this.OpenTerminalEventListener[devId]);
        }
      });
    },
    unregisterDevice(devId) {
      console.log(`unregisterDevice: ${devId}`);
      // Remove project if confirmed
      if (confirm(`Are you sure you want to unregister Device ${devId}?`)) {
        // Call http-api-client to unregister device
        unregisterDevice(devId)
        setTimeout(() => { this.refreshRootData(); }, 3000);
      }
    },
    changeLicense(devId) {
      console.log(`changeLicense: ${devId}`);
      // Open modal window to get new license expiration date
      const licenseExpDate = prompt(`New license expiration date for device ${devId}? ((DD-MM-YYYY)`);
      if (licenseExpDate === null) return;
      // Check date format
      const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
      if (! dateRegex.test(licenseExpDate)) {
        alert(`Date format must be DD-MM-YYYY`);
        return;
      }
      // Ask if device should be restarted after changing license
      const restart = confirm(`Do you want to restart Device ${devId} after changing license expiration date?`);
      // Send setUserConfig message to device
      let config = {
        'System.Device.LicenseExpDate': licenseExpDate
      }
      // To restart app after config settings
      if (restart) {
        config['System.Execute.Action'] = 'RestartApp';
      }
      sendMessageUnified(devId, 'setUserConfig', config, 'WebSocket');
      setTimeout(() => { this.refreshRootData(); }, 3000);
    },

    checkSoftwareUpgrade(devId) {
      console.log(`checkSoftwareUpgrade: ${devId}`);
      // Ask for confirmation
      if (! confirm(`Are you sure you want to check for Software Upgrade for Device ${devId}?`)) return;

      let config = {
        'System.Execute.Action': 'CheckForSwUpgrade'
      };       
      sendMessageUnified(devId, 'setUserConfig', config, 'WebSocket');
    },

    openTerminal(devId) {
      console.log(`openTerminal: ${devId}`);
      // Open terminal page
      let routeData = this.$router.resolve({path: `/terminal/RSSH-${devId}`});
      window.open(routeData.href, '_blank');       
    },

    customerData(customer) {
      return store.state.root.customersList.find(c => c.Customer === customer);
    },
    usersData(user) {
      return store.state.root.usersList.find(u => u.UserId === user);
    },
    projectsData(project) {
      return store.state.root.projectsList.find(p => p.ProjectId === project);
    },
    devicesData(device) {
      return store.state.root.devicesList.find(d => d.DevId === device);
    },
    formatDateString(inputDate) {
      // If inputDate is a string, convert it to a Date object
      const dateObject = typeof inputDate === 'string' ? new Date(inputDate) : inputDate;

      // Get the individual components of the date
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(dateObject.getDate()).padStart(2, '0');
      const hours = String(dateObject.getHours()).padStart(2, '0');
      const minutes = String(dateObject.getMinutes()).padStart(2, '0');
      const seconds = String(dateObject.getSeconds()).padStart(2, '0');

      // Format the date string
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      return formattedDate;
    },
  },
  computed: {
    ...mapState({
      servConnected: state => state.connection.servConnected, // Whether WebSocket to signalling server is conneced or not
    }),
    userId: function () { return store.state.login.email },
    customersList: function () { return store.state.root.customersList},
    usersList: function () { return store.state.root.usersList},
    projectsList: function () { return store.state.root.projectsList},
    devicesList: function () { return store.state.root.devicesList},   

    anyDeviceVisible: function () {
      return this.showCustomers || this.showUsers || this.showProjects || this.showDevices;
    }
    
  },
  // Lifecycle hooks
  mounted() {
    console.log('Root View Mounted');
    console.log(`Client Device Info: ${JSON.stringify(device)}`)
    document.title = `Pro-Inspector`; // Set Page title
    // Open spinner overlay if not devices received yet
    if (! this.wsConnected) {
      this.loadingOverlay = {show: true, text: 'Connecting....'};
    }   

    this.infoMsg = ''

    // Page visibility control
    document.onvisibilitychange = () => {
        if (document.visibilityState === "visible") {
            console.log('Root page being visible');
        } else { //Hidden
            console.log('Root page being hidden');
        }
    };
    
    // Register callback for SetAuthorization message
    registerWsMessageCallBack('SetAuthorization', this.onSetAuthorizationCallBack);
    // Connect to AWS signalling server through Web Socket
    connectWs(this.onWsConnected, null, this.onWsClosed);

    
    // Start showing Global Pro-Inspector devices
    const menuPreviouslySelected = store.state.login.portalMenuSelected;
    console.log(`MenuPreviouslySelected: ${menuPreviouslySelected}`);
    if (menuPreviouslySelected === 'Customers') {
      this.showCustomers = true;
    } else if (menuPreviouslySelected === 'Users') {
      this.showUsers = true;
    } else if (menuPreviouslySelected === 'Projects') {
      this.showProjects = true;
    } else if (menuPreviouslySelected === 'Devices') {
      this.showDevices = true;
    } else {
      this.showCustomers = true;
    }

    // Force check visibility
    if (document.visibilityState === "visible") {
      console.log('Root page being visible');
    }

  },
  unmounted() {
    console.log('Root View Unmounted');
    this.loadingOverlay.show = false;
    removeOnConnectedCB(this.onWsConnected); // Remove callback for onWsConnected
  },

})
export default class Root extends Vue {}
</script>

<style scoped>

</style>


