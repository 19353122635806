// Created by SPe on 03/01/23
// Landing page for proinspector app

// "subject": "mailto: salva@ainspect.io>",
// "publicKey": "BHbgFYCrUQ0xkf59sz2hobve5jfVFuWlmuXRRjtfdmPqLK82S0-sR6_v-xvF2NcIpZ01q2jU1q9JkUwrxev9Jag",
// "privateKey": "SHGTTDwU8B4bU7EhKc_AcCfMFe_hsHRaCObna50RflI"

<template>
  <div>
    <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
    <div class="min-h-screen flex flex-col">
      <!-- Header -->
      <header class="bg-red-50">
        <NavBar 
          :servConnected="servConnected" 
          :showSubscribeButton="showSubscribeButton"
          :showUnSubscribeButton="showUnSubscribeButton"
          @subscribe="subscribe"
          @unsubscribe="unsubscribe"
        />
      </header>
      <div class="flex-1 flex flex-col sm:flex-row">
        <!-- Main content -->
        <main class="flex-1 bg-gray-200 py-3 px-2">
          <div v-if="showPipGlobalDevs">
            <p v-if="pipDeviceForUserList.length==0 && !this.loadingOverlay.show" class="notificationBanner">No Pro-Inspector Devices found</p>  
            <div class="grid gap-2 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 px-2"> 
              <DeviceSummary v-for="dev in pipDeviceForUserList"  :key="dev" :customer="$store.state.devices.deviceInfo[dev].Customer" :deviceInfo="devInfo(dev)" :deviceOperatingState="$store.state.devices.deviceOperatingState[dev]" />
            </div>
          </div>
          
          <div v-if="showRkpGlobalDevs">
            <p v-if="rkpDeviceForUserList.length==0 && !this.loadingOverlay.show" class="notificationBanner">No Remote Keypad Devices found</p> 
            <div class="grid gap-2 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 px-2"> 
              <DeviceRKPSummary v-for="dev in rkpDeviceForUserList"  :key="dev" :deviceInfo="devInfo(dev)" />
            </div>
          </div>

          <div v-if="showPipLocalDevs">
            <p v-if="pipDeviceForPubliIpList.length==0 && !this.loadingOverlay.show" class="notificationBanner">No Pro-Inspector Devices found in local network</p>  
            <div class="grid gap-2 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 px-2"> 
              <DeviceSummary v-for="dev in pipDeviceForPubliIpList" :customer="$store.state.devices.deviceInfo[dev].Customer" :key="dev" :deviceInfo="devInfo(dev)" :deviceOperatingState="$store.state.devices.deviceOperatingState[dev]" />
            </div>
          </div>
          
          <div v-if="showRkpLocalDevs">
            <p v-if="rkpDeviceForPubliIpList.length==0 && !this.loadingOverlay.show" class="notificationBanner">No Remote Keypad Devices found in local network</p>  
            <div class="grid gap-2 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 px-2"> 
              <DeviceRKPSummary v-for="dev in rkpDeviceForPubliIpList"  :key="dev" :deviceInfo="devInfo(dev)" />
            </div>
          </div>

          <div v-if="showProjectList" class="">
            <p v-if="projectsInfoMsg" class="notificationBanner">{{projectsInfoMsg}}</p>
            <i class="bi bi-arrow-clockwise inline-block border-2  border-gray-600 px-2 mx-3 my-2 cursor-pointer"  title="Refresh Projects" @click="refreshProjectList()"></i>
            <p class="text-lg font-bold bg-yellow-500 rounded-lg px-5 mx-3 inline-block cursor-pointer" @click="createNewProject">Create new Project</p>            
            <div v-if="projectList">
              <ProjectSummary  
                v-for="prjIndex in Object.keys(projectList)" :key="projectList[prjIndex].ProjectName" 
                :projectData="projectList[prjIndex]" 
              />
            </div>
          </div>
        </main>
        <!-- Left Side bar -->
        <nav class="order-first sm:w-32 md:w-40 bg-gray-100">
          <!-- MENU -->
          <!-- Link to User Manual PDF -->
          <ul class="text-left text-sm  ml-1 my-2">
            <li>
              <span class="font-bold">Devices</span>
              <ul class="ml-3">
                <li><span class="font-semibold">Pro-Inspector</span>
                  <ul class="ml-3">
                    <li class=" hover:text-blue-500 cursor-pointer" @click="()=>{cleanContent(); menuSelected='PIPGlobal'; showPipGlobalDevs=true; startReceivingDevStatus();}" :class="{'text-blue-600 font-bold' : showPipGlobalDevs}">Global</li>
                    <li class=" hover:text-blue-500 cursor-pointer" @click="()=>{cleanContent(); menuSelected='PIPLocal'; showPipLocalDevs=true; startReceivingDevStatus();}" :class="{'text-blue-600 font-bold' : showPipLocalDevs}">Local Network</li>
                  </ul>
                </li>
                <li><span  class="font-semibold">Remote Key Pad</span>
                  <ul class="ml-3">
                    <li class=" hover:text-blue-500 cursor-pointer" @click="()=>{cleanContent(); menuSelected='RKPGlobal'; showRkpGlobalDevs=true; startReceivingDevStatus();}" :class="{'text-blue-600 font-bold' : showRkpGlobalDevs}">Global</li>
                    <li class=" hover:text-blue-500 cursor-pointer" @click="()=>{cleanContent(); menuSelected='RKPLocal'; showRkpLocalDevs=true; startReceivingDevStatus();}" :class="{'text-blue-600 font-bold' : showRkpLocalDevs}">Local Network</li>
                  </ul>
                </li>
              </ul>
            </li>
            <hr class="my-2">
            <li class=" hover:text-blue-500 cursor-pointer" @click="()=>{cleanContent(); menuSelected='Projects'; showProjectList=true; stopReceivingDevStatus();}" :class="{'text-blue-600 font-bold' : showProjectList}">
              <i class="bi bi-lightbulb px-2"></i> 
              <span class="font-bold">Projects</span>
            </li>
          </ul>
        </nav>
        <!-- Right Side bar -->
        <aside class="sm:w-48 bg-gray-100 px-2">
          <i class="bi bi-info-circle px-2"></i>
          <!-- Empty content -->
          <div class=" text-left" v-if="!showPipGlobalDevs&&!showPipLocalDevs&&!showRkpGlobalDevs&&!showRkpLocalDevs&&!showProjectList">            
            <h2 class="text-base font-bold">Pro-Inspect Portal</h2>
            <hr class="mx-2">
            <p class="text-sm py-2">
              Manage all your Pro-Inspect Devices and Projects.
            </p>
            <p class="text-sm py-2">
              Select in left menu:
            </p>
            <i class="bi bi-hdd-network"></i>
            <span class="font-bold"> Devices:</span>
            <ul class="py-2">
              <li class="py-2"><span class="font-bold">Global: </span><span>List all devices that this user has permission. Manage user remote access in devide Remote Settings.</span></li>
              <li class="py-2"><span class="font-bold">Local Network: </span><span>List all devices connected in same lacal network as this web browser host.</span></li>
            </ul>
            <hr  class="mx-4 mb-2">
            <i class="bi bi-lightbulb px-2"></i> 
            <span class="font-bold">Projects: </span><span>List all Projects this user can manage: either created by or hared with.</span>
          </div>
          <!-- Devices content -->
          <div class=" text-left" v-if="showPipGlobalDevs||showPipLocalDevs||showRkpGlobalDevs||showRkpLocalDevs">
            <div class="py-4"><button><a href="files/Print-Inspector Manual de usuario V2.pdf" target="_blank">User Manual</a></button></div>      
            
            <i class="bi bi-hdd-network px-2"></i>
            <span class="font-bold"> Devices:</span>
            <ul class="py-2">
              <li class="py-2"><span class="font-bold">Global: </span><span>List all devices that this user has permission. Manage user remote access in devide Remote Settings.</span></li>
              <li class="py-2"><span class="font-bold">Local Network: </span><span>List all devices connected in same lacal network as this web browser host.</span></li>
            </ul>
            <hr  class="mx-4 mb-2">
            <ul class="py-2">
              <li class="py-2">
                <img class="icon h-6 float-left pr-2" :src="require('@/assets/pics/panel.png')" />
                <span><b>Panel:</b> Show device real time images and inferences.</span>
              </li>
              <li class="py-2">
                <img class="icon h-6 float-left pr-2" :src="require('@/assets/pics/history.png')" />
                <span><b>History:</b> Show history images captured by device.</span>
              </li>
              <li class="py-2">
                <img class="icon h-6 float-left pr-2" :src="require('@/assets/pics/settings.png')" />
                <span><b>Settings:</b> Review or change device settings.</span>
              </li>
            </ul>
          </div>
          <!-- Projects content -->
          <div class=" text-left" v-if="showProjectList">            
            <i class="bi bi-hdd-lightbulb px-2"></i>
            <span class="font-bold"> Projects</span>
            <p>List all projects manageble by this user. You can create new projects.</p>
            <hr  class="mx-4 my-3">
            <i class="bi bi-arrow-clockwise border-2 border-gray-600 px-2 py-1 mr-3 my-2"></i>
            <span><b>Reload:</b> Reload all available projects.</span>
            <ul class="py-2">
              <li class="py-2">
                <i class="bi bi-pencil-square text-3xl pr-3 float-none"></i>
                <span><b>Edit Project: </b>Manage the project: create/edit Datasets, train new Engines and download Engine to Devices</span>
              </li>
              <li class="py-2">
                <i class="bi bi-share text-3xl pr-3 float-none"></i>
                <span><b>Share Project: </b>Share the Project with other users so they can manage it.</span>
              </li>
              <li class="py-2">
                <i class="bi bi-x-square text-3xl pr-3 float-none"></i>
                <span><b>Remove Project: </b>Remove the project. Note that all history images, Datasets and Engines will be elimnated.</span>
              </li>
            </ul>
          </div>
        </aside>
      </div>
      <!-- Footernbar -->
      <Footer />
    </div>

    <!-- Modal Window for Create New Project Form -->
    <Modal
        v-model="createNewProjectModalShow"
        :close="closeCreateNewProjectModal"
        ref="modal"
    >
      <NewProjectForm @newProjectSubmitted="newProjectSubmitted" :currProjectNames="projectNameList"></NewProjectForm>
        
    </Modal>

    <!-- Modal Window for request DevId for User registration -->
    <Modal
        v-model="requestDevIdModalShow"
        ref="modal"
    >

    <GetDevIdForm :userId="userId" @getDevIdFormSubmitted="getDevIdFormSubmitted"></GetDevIdForm>

    </Modal>

  </div>  
</template>

<script>
import { Options, Vue } from 'vue-class-component'; 
import { mapState } from 'vuex'
import DeviceSummary from '@/components/DeviceSummary.vue'; 
import DeviceRKPSummary from '@/components/DeviceRKPSummary.vue';
import ProjectSummary from '@/components/ProjectSummary.vue';  
import LoadingOverlay from '@/components/LoadingOverlay.vue'; 
import NewProjectForm from '@/components/NewProjectForm.vue';
import GetDevIdForm from '@/components/GetDevIdForm.vue';
import store from '@/store/index.js';
import { formatDateTimeForHistory } from '@/library/utils';
import { connect as connectWs, close as closeWs, doKeepWsConnected, dontKeepWsConnected, registerWsMessageCallBack, unregisterWsMessageCallBack, sendWsMessage, removeOnConnectedCB } from '@/library/websocket.js';
//import { subscribe }from '@/library/notification-subscription.js';
//import { connect as connectWrtc, close as closeWrtc } from '@/library/webrtc'
import { getProjectsOfUser, createProject } from '@/library/http-api-client'; 
// import { joinRoomUnified, leaveRoomUnified, checkDeviceConnection } from '@/library/client-unified-send'
import { joinRoomUnified, leaveRoomUnified } from '@/library/client-unified-send'
//import { registerUnifiedMessageCallBack } from '@/library/client-unified-receive'
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import device from '@/library/detect-device';

@Options({
  components: {
    LoadingOverlay,
    NavBar,
    Footer,
    DeviceSummary,
    DeviceRKPSummary,
    ProjectSummary,
    NewProjectForm,
    GetDevIdForm,
  },
  data: function(){
    return {
        loadingOverlay: {show: false, text: 'Loading'},
        devicesReceived: store.state.devices.devicesForUserList != null,
        devConnected: null, // Whether WebRTC/SocketIO to device is conneced or not
        showSubscribeButton: true,
        showUnSubscribeButton: true,
        showPipGlobalDevs: false,
        showPipLocalDevs: false,
        showRkpGlobalDevs: false,
        showRkpLocalDevs: false,
        showProjectList: false,

        menuSelected: null,

        createNewProjectModalShow: false,

        requestDevIdModalShow: false,

        notificationConfig:{
          pushKey:
            "BHbgFYCrUQ0xkf59sz2hobve5jfVFuWlmuXRRjtfdmPqLK82S0-sR6_v-xvF2NcIpZ01q2jU1q9JkUwrxev9Jag",
        },

        firstTimeDevicesInfoReceived: true,

        resubscrived : false,
    }
  },
  watch: {
    menuSelected(newValue, oldValue) {
      console.log(`menuSelected changed from ${oldValue} to ${newValue}`);
      store.commit('login/setPortalMenuSelected', newValue);
    }
  },
  props: [],
  methods: {
    onWsConnected() {
      let authorized = store.getters['login/isAuthorized'];
      console.log(`WebSocket connected. User is authorized: ${authorized}`);
      // If user is authorized, then get devices info for user
      if (authorized) {
        console.log(`User is authorized. Sending GetDevicesInfoForUser`);
        const getDevicesInfoForUserData = {ClientId: store.state.login.clientId, UserId: store.state.login.email}
        sendWsMessage('GetDevicesInfoForUser', getDevicesInfoForUserData);
        this.requestDevIdModalShow = false;
        this.refreshProjectList();
        // Check push subscription and resibscribe if needed
        this.checkSubscription();
      } else {
        console.log(`User is not authorized`);
        this.loadingOverlay = {show: false};
        this.requestDevIdModalShow = true;
      }
    },
    // onWsDataReceived(data) {
    //   console.log(`WebSocket message (${data.Type}) received: ${JSON.stringify(data)}`);
    //   const msgType = data.Type;
    //   if (msgType === 'SetDevicesInfo') {
    //     console.log(`WebSocket SetDevicesInfo message received`);
    //     this.devicesReceived = true;
    //     this.loadingOverlay.show = false;
    //   }
    // },
    onWsClosed() {
      console.log(`WebSocket closed`);
    },
    cleanContent() {
      console.log(`cleanContent`);
      this.showPipGlobalDevs = false;
      this.showPipLocalDevs = false;
      this.showRkpGlobalDevs = false;
      this.showRkpLocalDevs = false;
      this.showProjectList = false;
    },
    startReceivingDevStatus() {
      console.log(`startReceivingDevStatus`);
      doKeepWsConnected();
      for (const devId of this.allDevicesList) {
        joinRoomUnified(devId, 'OperatingStatus', 'WebSocket');
      }
    },
    stopReceivingDevStatus() {
      console.log(`Portal page start being hidden`);
      dontKeepWsConnected();
      for (const devId of this.allDevicesList) {
        leaveRoomUnified(devId, 'OperatingStatus', 'WebSocket');
      }
    },
    createNewProject() {
      console.log(`createNewProject`);
      this.createNewProjectModalShow = true;
    },
    closeCreateNewProjectModal() {
      console.log(`closeCreateNewProjectModal`);
      this.createNewProjectModalShow = false;
    },
    // Check whether user is subscripted
    async checkSubscription() {
      console.log(`Checking subscription`);
      navigator.serviceWorker.register("./service-worker.js") // Register service worker
        .then(swReg => {
          console.log("Service worker registration succeeded:", swReg);
          swReg.pushManager.getSubscription() // Get subscription (if any)
          .then(subscription => {
            if (subscription) {
              console.log(`Checking subscription. Is subscribed`);
              this.showSubscribeButton = false;
              this.showUnSubscribeButton = true;
              // Resubscribe (if not already done) to ensure the subscription is not expired
              if (!this.resubscrived) {
                console.log(`Resubscribing`);
                this.resubscrived = true;
                this.unsubscribe().then(() => {
                  // Small delay to allow the unsubscribe to complete
                  setTimeout(() => { this.subscribe(); }, 1000);
                });
              }
            } else {
              console.log(`Checking subscription. Is unsubscribed`);
              this.showSubscribeButton = true;
              this.showUnSubscribeButton = false;
            }
          })
          .catch(err => {
            console.log('Error Getting Subscription', err);
          })
        })
        .catch(err => {
          console.log('Error Registering Service Worker', err);
        })
    },
    // Offer user to subscribe to push notification
    async subscribe() {
      console.log(`Subscribe`);
      navigator.serviceWorker.register("./service-worker.js") // Register service worker
        .then(swReg => { 
            console.log("Service worker registration succeeded:", swReg);
            swReg.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: this.urlB64ToUint8Array(this.notificationConfig.pushKey),
            }).then(subscription => {
              console.log('User is subscribed:', subscription);
              // Send subscription data to backend
              const clientInfo = {model: device.model, brand: device.brand, type: device.type, os: device.os_name, browser_name: device.browser_name, browser_version: device.browser_version}
              const data = {Time: formatDateTimeForHistory(new Date()), Topic: 'Alerts', UserId: this.userId, Subscription: subscription, ClientInfo: clientInfo};
              console.log(`Sending AddPushSubscription with data: ${JSON.stringify(data)}`);
              sendWsMessage('AddPushSubscription', data);
              this.checkSubscription();
            })
            .catch(err => {
              console.error('Failed to subscribe the user: ', err);
              let errStr = err.toString();
              if (errStr.includes('denied')) {
                console.warn('Permission for notifications was denied');
                alert('Permission for notifications was denied');
              }
            });
        })
        .catch(err => {
          console.log('Error Getting Subscription', err);
        });
    },
    async unsubscribe() {
      console.log(`Unsubscribing`);
      navigator.serviceWorker.register("./service-worker.js") // Register service worker
        .then(swReg => { swReg.pushManager.getSubscription().then(subscription => {
            if (subscription) {
              subscription.unsubscribe().then(successful => { // Successfully unsubscribed
                console.log(`Unsubscribed successfully: ${successful}`);
                // Remove subscription on backend
                const data = {Time: formatDateTimeForHistory(new Date()), Topic: 'Alerts', UserId: this.userId, Subscription: subscription};
                console.log(`Sending  RemovePushSubscription with data: ${JSON.stringify(data)}`);
                sendWsMessage('RemovePushSubscription', data);
                this.showSubscribeButton = true;
                this.showUnSubscribeButton = false;
              }).catch(function(e) { // Unsubscribing failed                
                console.error(`Unsubscribed failed with error: ${e}`);
                this.checkSubscription();
              }) 
            } else {
              console.error(`No subscription found to unsubscribe`);
              this.checkSubscription();
            }
          })
        })
    },
    urlB64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");
    
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
    
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },

    onOpenProjects() {
      console.log(`onOpenProjects`);
      let routeData = this.$router.resolve({path: `/projects`});
      window.open(routeData.href, '_blank');
    },

    async onSetDevicesInfoCallBack() {
      // SetDevicesInfo mesaage received --> join OperatingStatus room for all devices
      console.log(`onSetDevicesInfoCallBack`);
      console.log(`onSetDevicesInfoCallBack. All devices (${this.allDevicesList.length}): ${JSON.stringify(this.allDevicesList)}`);
      for (const devId of this.allDevicesList) {
        console.log(`Device: ${devId} --> ${this.devName(devId)}`);
      }
      this.devicesReceived = true;
      this.loadingOverlay.show = false;
      if (document.visibilityState === "visible" && this.anyDeviceVisible) {
        this.startReceivingDevStatus();
        if (this.firstTimeDevicesInfoReceived) {
          this.firstTimeDevicesInfoReceived = false;
          // Check if devices are connected, otherwise remove the connection
          // for (const devId of this.allPipDeviceList) {
          //   // Delay for 400 ms
          //   // await new Promise(r => setTimeout(r, 900));
          //   checkDeviceConnection(devId); // Check if PAI device is connected, otherwise remove the connection
          // }
          
          // If local devices exists and is the first time devices info is received, then show local devices
          if (this.pipDeviceForPubliIpList.length > 0) {
            this.cleanContent();
            this.showPipLocalDevs = true;
          }

        }
      } else console.log(`SetDevicesInfo received. But Portal page is not visible --> not joining OperatingStatus room`);
      // SetDevicesInfo mesaage received --> join OperatingStatus room for all devices
      console.log(`onSetDevicesInfoCallBack`);
      console.log(`onSetDevicesInfoCallBack. All devices (${this.allDevicesList.length}): ${JSON.stringify(this.allDevicesList)}`);
      for (const devId of this.allDevicesList) {
        console.log(`Device: ${devId} --> ${this.devName(devId)}`);
      }
      this.devicesReceived = true;
      this.loadingOverlay.show = false;
      if (document.visibilityState === "visible" && this.anyDeviceVisible) {
        this.startReceivingDevStatus();
        if (this.firstTimeDevicesInfoReceived) {
          this.firstTimeDevicesInfoReceived = false;
          // Check if devices are connected, otherwise remove the connection
          // for (const devId of this.allPipDeviceList) {
          //   // Delay for 400 ms
          //   // await new Promise(r => setTimeout(r, 900));
          //   checkDeviceConnection(devId); // Check if PIP device is connected, otherwise remove the connection
          // }
          
          // If local devices exists and is the first time devices info is received, then show local devices
          if (this.pipDeviceForPubliIpList.length > 0) {
            this.cleanContent();
            this.showPipLocalDevs = true;
          }

        }
      } else console.log(`SetDevicesInfo received. But Portal page is not visible --> not joining OperatingStatus room`);
    },

    // onSetAuthorizationCallBack() {
    //   let authorized = store.getters['login/isAuthorized'];
    //   if (authorized) {
    //     console.log(`onSetAuthorizationCallBack. User is authorized: ${authorized}`);
    //     // this.loadingOverlay = {show: false};
    //     this.requestDevIdModalShow = false;
    //   } else {
    //     console.log(`onSetAuthorizationCallBack. User is not authorized: ${authorized}`);
    //     this.loadingOverlay = {show: false};
    //     this.requestDevIdModalShow = true;
    //   }
    // },

    newProjectSubmitted(projectData) {
      console.log(`newProjectSubmitted: ${JSON.stringify(projectData)}`);
      this.createNewProjectModalShow = false;
      if (projectData) {
        this.loadingOverlay = {show: true, text: 'Creating Project'};
        // Send request to BackEnd
        let projectName = projectData['ProjectName'];
        let defects = projectData['Defects'];
        let defectsWithDetection = projectData['DefectsWithDetection'] || [];
        let camConfig = projectData['CamConfig'];
        let roiConfig = projectData['RoiConfig'];
        if (projectName && defects) {
          createProject(this.userId, projectName, defects, defectsWithDetection, camConfig, roiConfig, this.refreshProjectList);
        }
      }
    },

    async getDevIdFormSubmitted(devId) {
      console.log(`getDevIdFormSubmitted: ${devId}`);

      if (devId) {
        console.log(`getDevIdFormSubmitted: ${devId}`);
        this.loadingOverlay = {show: true, text: 'Connecting ...'};
        this.requestDevIdModalShow = false;
        // Store devId in login store
        store.commit('login/setRegDevId', devId);
        closeWs(); // Close Websocket connection
        // Small sleep to allow websocket to close and to prevent too many tries of DevId
        // await new Promise(r => setTimeout(r, 10000));
        connectWs(this.onWsConnected, null, this.onWsClosed); // Reconnect websocket
      } else {
        // Redirect to logout page
        this.$router.push({path: `/logout`});
      }
      
    },

    refreshProjectList() {
      console.log(`refreshProjectList`);
      //this.loadingOverlay = {show: true, text: 'Loading Projects'};
      this.projectsInfoMsg = 'Getting Projects'
      // Get Projects Data for the user
      getProjectsOfUser(this.userId, this.onProjectList)
    },

    onProjectList() {
      console.log(`onProjectList`);
      this.loadingOverlay = {show: false};
      this.projectsInfoMsg = '';
    },

    devInfo(devId) {return store.state.devices.deviceInfo[devId]},

    devName(devId) {return store.state.devices.deviceInfo[devId].Name},

  },
  computed: {
    ...mapState({
      servConnected: state => state.connection.servConnected, // Whether WebSocket to signalling server is conneced or not
    }),
    ...mapState('devices', {
      allDevicesList: state => state.allDevicesList,
    }),
    userId: function () { return store.state.login.email },
    projectList: function () { if (store.state.projects != null) {return store.state.projects.projectList;} else {return {}} },
    projectNameList: function () { if (store.state.projects != null) {
      let nameList = [];
      for (let project of store.state.projects.projectList) nameList.push(project.ProjectName);
      return nameList;
    } else {return []} },
    allPipDeviceList: function() {
      if (this.devicesReceived) {
        let list = [];
        for (let dev of store.state.devices.allDevicesList) {
          if (this.devInfo(dev).AppName === 'PAI' || this.devInfo(dev).AppName === 'PIP') list.push(dev); // Borrar the PAI part when all devices are updated 
        }
        return list
      }
      else return [];
    },
    allDeviceForUserList: function() {
      if (this.devicesReceived) return store.state.devices.devicesForUserList;
      else return [];
    },
    pipDeviceForUserList: function () {
      if (this.devicesReceived) {
        let list = [];
        for (let dev of store.state.devices.devicesForUserList) {
          if (this.devInfo(dev).AppName === 'PAI' || this.devInfo(dev).AppName === 'PIP') list.push(dev); // Borrar the PAI part when all devices are updated
        }
        return list
      }
      else return [];
    },
    rkpDeviceForUserList: function () {
      if (this.devicesReceived) {
        let list = [];
        for (let dev of store.state.devices.devicesForUserList) {
          if (this.devInfo(dev).AppName === 'RKP') list.push(dev);
        }
        return list
      }
      else return [];
    },
    allDeviceForPubliIpList() {
      if (this.devicesReceived) return store.state.devices.devicesForPublicIpList;
      else return [];
    },
    pipDeviceForPubliIpList: function () {
      if (this.devicesReceived) {
        let list = [];
        for (let dev of store.state.devices.devicesForPublicIpList) {
          if (this.devInfo(dev).AppName !== 'RKP') list.push(dev);
        }
        return list
      }
      else return [];
    },
    rkpDeviceForPubliIpList: function () {
      if (this.devicesReceived) {
        let list = [];
        for (let dev of store.state.devices.devicesForPublicIpList) {
          if (this.devInfo(dev).AppName === 'RKP') list.push(dev);
        }
        return list
      }
      else return [];
    },
    anyDeviceVisible: function () {
      return this.showPipGlobalDevs || this.showPipLocalDevs || this.showRkpGlobalDevs || this.showRkpLocalDevs;
    }
    
  },
  // Lifecycle hooks
  mounted() {
    console.log(`Portal View Mounted`);
    console.log(`Client Device Info: ${JSON.stringify(device)}`)
    document.title = `Pro-Inspector`; // Set Page title
    // Open spinner overlay if not devices received yet
    if (! this.devicesReceived) {
      this.loadingOverlay = {show: true, text: 'Connecting....'};
    }   

    this.infoMsg = ''

    // Page visibility control
    document.onvisibilitychange = () => {
        if (document.visibilityState === "visible") {
            console.log('Portal page being visible');
            this.firstTimeDevicesInfoReceived = true;
            if (this.anyDeviceVisible) this.startReceivingDevStatus();
        } else { //Hidden
            console.log('Portal page being hidden');
            this.stopReceivingDevStatus();
        }
    };
    // Register callback for SetDevicesInfo message
    registerWsMessageCallBack('SetDevicesInfo', this.onSetDevicesInfoCallBack);
    // Register callback for SetAuthorization message
    // registerWsMessageCallBack('SetAuthorization', this.onSetAuthorizationCallBack);

    // Connect to AWS signalling server through Web Socket
    connectWs(this.onWsConnected, null, this.onWsClosed);

    
    // Start showing Global Pro-Inspector devices
    const menuPreviouslySelected = store.state.login.portalMenuSelected;
    console.log(`MenuPreviouslySelected: ${menuPreviouslySelected}`);
    if (menuPreviouslySelected === 'PIPGlobal') {
      this.showPipGlobalDevs = true;
    } else if (menuPreviouslySelected === 'PIPLocal') {
      this.showPipLocalDevs = true;
    } else if (menuPreviouslySelected === 'RKPGlobal') {
      this.showRkpGlobalDevs = true;
    } else if (menuPreviouslySelected === 'RKPLocal') {
      this.showRkpLocalDevs = true;
    } else if (menuPreviouslySelected === 'Projects') {
      this.showProjectList = true;
    } else {
      this.showPipGlobalDevs = true;
    }

    // Force check visibility
    if (document.visibilityState === "visible") {
      console.log('Portal page being visible');
      if (this.anyDeviceVisible) this.startReceivingDevStatus();
    }

    /////////////////////////// Push Notifications //////////////////////////////////////////
    if (!('Notification' in window)) console.error('This browser does not support notifications!');
    if (!('PushManager' in window)) console.error('This browser does not support push!');
      
    Notification.requestPermission(status => {
      console.log('Notification permission status:', status);
    });

    // if (Notification.permission == 'granted') {
    //   navigator.serviceWorker.getRegistration().then(reg => {

    //     const options = {
    //     body: 'First notification!',
    //     icon: require('@/assets/pics/logo.png'),
    //     vibrate: [100, 50, 100],
    //     data: {
    //       dateOfArrival: Date.now(),
    //       primaryKey: 1
    //     },

    //     actions: [
    //       {action: 'explore', title: 'Go to the site',
    //         icon: require('@/assets/pics/history.png')},
    //       {action: 'close', title: 'Close the notification',
    //         icon: require('@/assets/pics/logo.png')},
    //     ]
    //   };

    //     reg.showNotification('Hello world!', options);
    //   });
    // }

    // this.checkSubscription();
  },
  unmounted() {
    console.log('Portal View Unmounted');
    this.loadingOverlay.show = false;
    unregisterWsMessageCallBack('SetDevicesInfo', this.onSetDevicesInfoCallBack);
    this.stopReceivingDevStatus();
    removeOnConnectedCB(this.onWsConnected); // Remove callback for onWsConnected
  },

})
export default class Portal extends Vue {}
</script>

<style scoped>

</style>


