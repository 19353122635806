// Created by SPe on 22/02/22
// Page to calibrate cameras
<template>
    <div class="panel">
        <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
        <NavBar 
            :devName="devName" 
            :showBackButton="showBackButton" 
            :showSpinner="showSpinner"
            :servConnected="servConnected" 
            :devConnected="devConnected"
        />

        <div v-for="(imgView, pos) in devAllimgViews" v-bind:id="'cam_image_cmp' + pos" class="container_cam_img" :key="pos">
            <img  v-bind:src="'data:image/jpeg;base64, ' + imgView.image_jpg_base64"  v-bind:alt="'Camera '+ pos" class="image">               
            <div class="bottom-left">{{relativeDateTime(imgView.time_stamp * 1000, true)}}</div>
            <div class="top-left">
                <table>
                    <tr><h3>Expoure Time: {{imgView.exposure_time}}</h3></tr>
                    <tr><td><button type="button" @click="onCamChangeExpTime(parseInt(pos), 1000)"> Exposure Time +1000 us</button></td></tr>
                    <tr><td><button type="button" @click="onCamChangeExpTime(parseInt(pos), 100)"> Exposure Time +100 us</button></td></tr>
                    <tr><td><button type="button" @click="onCamChangeExpTime(parseInt(pos), 10)"> Exposure Time +10 us</button></td></tr>
                    <tr><td><button type="button" @click="onCamChangeExpTime(parseInt(pos), 1)"> Exposure Time +1 us</button></td></tr>
                    <tr><td><button type="button" @click="onCamChangeExpTime(parseInt(pos), -1)"> Exposure Time -1 us</button></td></tr>
                    <tr><td><button type="button" @click="onCamChangeExpTime(parseInt(pos), -10)"> Exposure Time -10 us</button></td></tr>
                    <tr><td><button type="button" @click="onCamChangeExpTime(parseInt(pos), -100)"> Exposure Time -100 us</button></td></tr>
                    <tr><td><button type="button" @click="onCamChangeExpTime(parseInt(pos), -1000)"> Exposure Time -1000 us</button></td></tr>                                                
                </table>
            </div>
            <div class="top-right">
                <table>
                    <tr><h3>Focus  Distance: {{imgView.focus_distance}}</h3></tr>
                    <tr><td><button type="button" @click="onCamChangeFocus(parseInt(pos), 10)"> Focus Distance +10</button></td></tr>
                    <tr><td><button type="button" @click="onCamChangeFocus(parseInt(pos), 5)"> Focus Distance +5</button></td></tr>
                    <tr><td><button type="button" @click="onCamChangeFocus(parseInt(pos), 1)"> Focus Distance +1</button></td></tr>
                    <tr><td><button type="button" @click="onCamChangeFocus(parseInt(pos), -1)"> Focus Distance -1</button></td></tr>
                    <tr><td><button type="button" @click="onCamChangeFocus(parseInt(pos), -5)"> Focus Distance -5</button></td></tr>
                    <tr><td><button type="button" @click="onCamChangeFocus(parseInt(pos), -10)"> Focus Distance -10</button></td></tr>                                                
                </table>
            </div>
            <div class="bottom-right"></div>
            <div class="centered"></div>      
        </div> 
        <!-- Footernbar -->
        <Footer />       
    </div>  
</template>

<script>
//import { useLoading } from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import { Options, Vue } from 'vue-class-component';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import store from '@/store/index.js';
import appConfig from '@/config.js';
import { relativeDateTime } from '@/library/utils'
import { registerUnifiedMessageCallBack} from '@/library/client-unified-receive'
import { openUnifiedChannel, sendMessageUnified, closeChannel, joinRoomUnified, leaveRoomUnified } from '@/library/client-unified-send'

@Options({
    components: {
        LoadingOverlay,
        NavBar,
        Footer,
    },
    data: function(){
        return {
            loadingOverlay: {show: false, text: 'Loading'},
            showBackButton: false,
            showSpinner: false,
            devId: this.$route.params.DevId, // DevId collected from Path Parameters   
            loaderTask: null,
        }
    },
    props: [],
    methods: {
        onDevConnected(devId) {
            console.log(`Calibration. Device: ${devId} is connected through WebSocket`);
            this.loadingOverlay = {show: true, text: `Loading data from ${this.devName}`};
            // Register Call-Backs
            registerUnifiedMessageCallBack('new_image_class', this.onNewImage);
            registerUnifiedMessageCallBack('new_image_view', this.onNewImage);
            // Join CameraViews room
            joinRoomUnified(this.devId, 'CameraViews');  // Master
            for (let devId of this.slaveDevIds) {
                joinRoomUnified(devId, 'CameraViews');  // Slave
            }
        },
        onNewImage(devId) {
            console.log(`onNewImage Received fron device: ${devId}`);
            if (this.loaderTask) clearTimeout(this.loaderTask); // Cancel task if any
            this.loaderTask = setTimeout(() => {
                console.error(`No data received from device: ${devId} in last 5 seconds`);
                //this.showSpinner = true;
            }, 5000);
            this.loadingOverlay.show = false;
        },
        onCamChangeExpTime(pos, incTime) {
            console.log(`onCamChangeExpTime. Camera position: ${pos}, Change: ${incTime}`);
            const content = {pos: pos, incTime: incTime, timestamp: Date.now()};
            sendMessageUnified(this.devId, 'changeExposureTime', content);    
        },
        onCamChangeFocus(pos, incFocus) {
            console.log(`onCamChangeFocus. Camera position: ${pos}, Change: ${incFocus}`);
            const content = {pos: pos, incFocus: incFocus, timestamp: Date.now()};
            sendMessageUnified(this.devId, 'changeFocusDistance', content);    
        },
        relativeDateTime(TimeStampMs, seconds) {
            return relativeDateTime(TimeStampMs, seconds);
        },
        round(number, digits) {
            if (number) return number.toFixed(digits);
            else return 0;
        },
        joinAllRooms() {
            if (this.devId) {
                // Master/Single
                joinRoomUnified(this.devId, 'CameraViews');
                // Slave devices if any
                for (let devId of this.slaveDevIds) {
                    joinRoomUnified(devId, 'CameraViews');
                }
            }
        },
        leaveAllRooms() {
            if (this.devId) {
                // Master/Single
                leaveRoomUnified(this.devId, 'CameraViews');
                // Slave devices if any
                for (let devId of this.slaveDevIds) {
                    leaveRoomUnified(devId, 'CameraViews');
                }
            }
        }
    },
    computed: {
        slaveDevIds:  function () { if (store.state.devices.deviceInfo[this.devId]) return store.getters['devices/getSlaveDevIds'](this.$route.params.DevId); else return [];},
        allDevIds:  function () { if (store.state.devices.deviceInfo[this.devId]) return [this.$route.params.DevId].concat(store.getters['devices/getSlaveDevIds'](this.$route.params.DevId)); else return []; },
        devName: function () {
            if (this.devId && this.devId in store.state.devices.deviceInfo) {
                return store.state.devices.deviceInfo[this.devId].GUIInfo.DeviceName;
            } else null;
        },
        devAllimgViews: function () { 
            if (this.devId && this.devId in store.state.devices.imgView) {
                return store.state.devices.imgView[this.devId];
            } else return {};
        },
        servConnected: function () { return store.getters['connection/isWscConnected']}, // Whether WebSocket to signalling server is conneced or not
        devConnected: function () { return store.getters['connection/isDevWrtcConnected'](this.devId) || store.getters['connection/isDevSioConnected'](this.devId)},  // Whether WebRTC or SocketIO to device is conneced or not
    },
    // Lifecycle hooks
    mounted() {
        console.log('Panel View Created');
        document.title = `Calibration-${this.devName}`; // Set Page title
        // Open spinner overlay
        if (this.devName) this.loadingOverlay = {show: true, text: `Connecting to ${this.devName}`};   
        else this.loadingOverlay = {show: true, text: `Connecting to Device`};    
        // Connect to Master/Single and Slaves devices if any
        if (this.devId) {
            console.log(`Connecting via WebRTC to Master/Single device: ${this.devId}`)
            openUnifiedChannel(this.devId, this.onDevConnected);
            // Slaves
            for (let devId of this.slaveDevIds) {
                console.log(`Connecting via WebRTC to slave device: ${devId}`);
                openUnifiedChannel(devId, this.onDevConnected);
            }
        }    
        // Join and Leave Room when visibility change
        document.onvisibilitychange = () => {
            if (document.visibilityState === "visible") {
                console.log('Inspection page being visible');
                // Join All rooms
                this.joinAllRooms();
            } else { //Hiden
                console.log('Inspection page being hidden');
                // Leave room Cameras
                this.leaveAllRooms();
            }
        };
    },
    unmounted() {
        console.log('Panel View Unmounted')
        if (this.devId) {
            // Leave all rooms for all devices
            this.leaveAllRooms();
            // Close all WebRTC conections
            console.log(`Closing WebRTC connection to master device: ${this.devId}`);
            closeChannel('WebRTC', this.devId, appConfig.WebRTCCloseDelaySeconds);  // Master
            for (let devId of this.slaveDevIds) {
                console.log(`Closing WebRTC connection to slave device: ${devId}`);
                closeChannel('WebRTC', devId, appConfig.WebRTCCloseDelaySeconds);  // Slave
            }
            // Cancel loaderTask if any
            if (this.loaderTask) clearTimeout(this.loaderTask); 
        }
    },
})
export default class Calibration extends Vue {}
</script>

<style scoped>
    * {
    box-sizing: border-box;
    }
    button {
        @apply border-2 border-black bg-gray-800
    }
    .column {
        float: left;
        padding: 10px;
    }
    .left {
        width: 70%;
    }
    .right {
        width: 30%;
    }
    /* Clear floats after the columns */
    .row:after {
        content: "";
        display: table;
        clear: both;
    }
    table, th, td {
        border: 0px;
        vertical-align: middle;
    }

    .image {
        width: 100%;
    }

    .container_cam_img {
        position: relative;
        text-align: center;
        color: white;
        margin: 0 10 0 10;
        border-style: solid;
        border-width: 1px;
        border-color:rgb(127, 218, 150);
    }

    /* Bottom left text */
    .bottom-left {
        position: absolute;
        bottom: 8px;
        left: 16px;
    }
    
    /* Top left text */
    .top-left {
        position: absolute;
        top: 8px;
        left: 16px;
    }
    
    /* Top right text */
    .top-right {
        position: absolute;
        top: 8px;
        right: 16px;
    }
    
    /* Bottom right text */
    .bottom-right {
        position: absolute;
        bottom: 8px;
        right: 16px;
    }
    
    /* Centered text */
    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    table {
        text-align: left;
        position: relative;
        border-collapse: collapse; 
        text-indent: initial;
        white-space: normal;
        line-height: normal;
        font-weight: normal;
        font-size: medium;
        font-style: normal;
        color: -internal-quirk-inherit;
        text-align: start;
        border-spacing: 2px;
        font-variant: normal;
    }

    th, td {
        padding: 0.25rem;
    }

    .panelAllCam {
        background: rgb(182, 203, 235);
        z-index: 1;
        position: sticky;
        top: 0; /* Don't forget this, required for the stickiness */
        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
        padding: 0.25rem;
        /*width: 100%;*/
    }

</style>


