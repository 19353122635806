<!--
  Created by SPe on 27/11/2022
 -->
<template>
  <div class="DeviceRKPSummary">
    <h1 class="text-xl" :class="devNameClass">{{deviceInfo.Name}}</h1>

    <div class="grid xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 p-[10%]">   
      <img class="icon cursor-pointer" v-if="deviceInfo.AllowedPages.Panel" :src="require('@/assets/pics/panel.png')" @click="openPanel(false)" @click.middle="openPanel(true)" tittle="Settings"/>
      <img class="icon cursor-pointer" v-if="deviceInfo.AllowedPages.Settings" :src="require('@/assets/pics/settings.png')" @click="openSettings(false)" @click.middle="openSettings(true)" tittle="Settings"/>
      <img class="icon cursor-pointer" v-if="deviceInfo.AllowedPages.Inspection" :src="require('@/assets/pics/status.png')" @click="openInspection(false)" @click.middle="openInspection(true)" tittle="Status"/>
      <img class="icon cursor-pointer" v-if="deviceInfo.AllowedPages.Logs" :src="require('@/assets/pics/log.png')" @click="openLogs(false)" @click.middle="openLogs(true)" tittle="Logs"/>
      <img class="icon cursor-pointer" v-if="deviceInfo.AllowedPages.Terminal" :src="require('@/assets/pics/terminal.png')" @click="openTerminal(false)" @click.middle="openTerminal(true)" tittle="Terminal"/>
    </div>    

     <div class="grid sm:grid-cols-1 xl:grid-cols-1"> 
      <p class="text-xs text-left">Id: {{deviceInfo.DevId}}</p>
      <p class="text-xs text-left">IP: {{deviceInfo.HostIp+' @'+deviceInfo.PublicIp}}</p>
    </div>
    
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import store from '@/store/index.js';
import '@/assets/css/global.css';

@Options({
  components: {
  },
  props: {
    deviceInfo: Object,
    deviceOperatingState: Object,
  },
  data: function(){
      return {
          isLocalDevice: this.deviceInfo.PublicIp === store.state.login.clientPublicIp,
          publicIp: store.state.login.clientPublicIp,
          hostIp: ((this.deviceInfo.PublicIp === store.state.login.clientPublicIp) ? this.deviceInfo.HostIp : 'remote'),
      }
  },
  methods: {
    openPanel: function(openNew=false) {
      let devId = this.deviceInfo.DevId;
      if (this.deviceInfo.HwType === 'rgb') {
        console.log(`Opening RGB Panel for device: ${devId}`);
        let path = `/panel_rkp_rgb/${devId}/${this.hostIp}`;
        if (openNew) {
          let routeData = this.$router.resolve({path: path});
          window.open(routeData.href, '_blank');
        } else this.$router.push({path: path});
      } else {
        console.log(`Opening CHR Panel for device: ${devId}`);
        let path = `/panel_rkp_chr/${devId}/${this.hostIp}`;
        if (openNew) {
          let routeData = this.$router.resolve({path: path});
          window.open(routeData.href, '_blank');
        } else this.$router.push({path: path});
      }
    },
    openSettings: function(openNew=false) {
      let devId = this.deviceInfo.DevId;
      let devName = this.deviceInfo.Name;
      console.log(`Opening RKP Settings for device: ${devId}. OpenNew: ${openNew}`);
      let path = `/settings_rkp/${devName}/${devId}`;
      if (openNew) {
        let routeData = this.$router.resolve({path: path});
        window.open(routeData.href, '_blank');
      } else this.$router.push({path: path});      
    },
    openInspection: function(openNew=false) {
      let devId = this.deviceInfo.DevId;
      console.log(`Opening Inspection for device: ${devId}`);
      let path = `/inspection_rkp/${devId}`;
      if (openNew) {
        let routeData = this.$router.resolve({path: path});
        window.open(routeData.href, '_blank');
      } else this.$router.push({path: path});      
    },
    openTerminal: function(openNew=false) {
      let devId = this.deviceInfo.DevId;
      console.log(`Opening terminal for device: ${devId}`);
      let path = `/terminal/${devId}`;
      if (openNew) {
        let routeData = this.$router.resolve({path: path});
        window.open(routeData.href, '_blank');
      } else this.$router.push({path: path}); 
    },
    openLogs: function(openNew=false) {
      let devId = this.deviceInfo.DevId;
      console.log(`Opening logs for device: ${devId}`);
      let path = `/logs/${devId}`;
      if (openNew) {
        let routeData = this.$router.resolve({path: path});
        window.open(routeData.href, '_blank');
      } else this.$router.push({path: path}); 
    },
  },
  computed: {
    // Class for device name 
    devNameClass() {
      return {
        GreyFont: (!this.deviceOperatingState || this.deviceOperatingState.hostMode == 'Stop'),
        GreenFont: (this.deviceOperatingState && this.deviceOperatingState.hostMode == 'Inspecting' && ! this.deviceOperatingState.alarm),
        RedFont: (this.deviceOperatingState && this.deviceOperatingState.hostMode == 'Inspecting' && this.deviceOperatingState.alarm),
        PurpleFont: (this.deviceOperatingState && this.deviceOperatingState.hostMode == 'Viewing'),
        blink: (this.deviceOperatingState && this.deviceOperatingState.alarm),
      }
    },
    allDevIds() {
      let devIds = [this.deviceInfo.DevId]
      if (this.deviceInfo.GUIInfo.ClusterRole === 'Master') devIds = devIds.concat(this.deviceInfo.GUIInfo.ClusterSlaveIDs);
      return devIds;
    },
  },
})
export default class DeviceRKPSummary extends Vue {
  msg
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.icon {
  padding: 10%;
}

button {
  border-color: black;
  border-width: 2px;
  @apply inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0  transition duration-150 ease-in-out;
}

.DeviceRKPSummary {
  border-width:2px;
  border-style:solid;
  border-color:rgb(209, 133, 19);
  background-color: rgb(221, 206, 184);
}


h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
