// Created by SPe on 28/07/2022
// Page to visualize an inferenced image (for labeling) supporting Multi Label
<template>
  <div class="inferImage cursor-pointer" ref="inferImage" 
    :style="{'border': '2px solid ' + outerBorderColor}" 
    :class="{ hide: hideImg }" @click.ctrl.exact="onCtrlClickSelection($event, imgObj)"  
    @mousedown.left="onMouseDown" @touchstart="onMouseDown" @mouseup.left="onMouseUp" @touchend="onMouseUp">                                  
                
        <div v-if="showImg" :style="{'box-sizing': 'border-box', 'border': '2px solid ' + innerBorderColor}">
            <v-stage ref="stage" :width="imgRealWidth" :height="imgRealHeight" @mousemove="drawRectangle" @touchmove="drawRectangle">
                <v-layer ref="layer">
                    <v-image  ref="image" :config="imageConfig"/>              
                    
                    <v-rect ref="rect" stroke="blue" :config="newRect" draggable="false"/>
                </v-layer>
            </v-stage>
        </div>

        <div class="bottom-left" v-if="showMetadata">
            <p v-if="imgTimestamp" :style="{'font-size': textHeight + 'px'}" v-tooltip="relativeDateTime(imgTimestamp, true, true)">{{relativeDateTime(imgTimestamp, true, true)}}</p>       
        </div>
        <div class="top-left flex flex-column" v-if="showMetadata">
            <input v-if="showSelection"  class="w-5 h-4 cursor-pointer" type="checkbox" id="cbox" value="checkbox" v-model="imgObj.selected" @mousedown="onSelection($event, imgObj)" @touchstart="onSelection($event, imgObj)">
            <p v-if="devName && camPos" :style="{'font-size': textHeight + 'px'}" :title="imgOriginalWidth + 'X' + imgOriginalHeight">{{`${devName}-C${camPos}`}}</p>
            <p v-if="devName && !camPos" :style="{'font-size': textHeight + 'px'}" :title="imgOriginalWidth + 'X' + imgOriginalHeight">{{`${devName}`}}</p>
            <p v-if="!devName && camPos" :style="{'font-size': textHeight + 'px'}" :title="imgOriginalWidth + 'X' + imgOriginalHeight">{{`C${camPos}`}}</p>
        </div>
        <div class="top-right">
            <div v-if="allLabels && showMetadata">
                <div v-for="(label, labelIndex) of allLabels" :key="label" :style="{height: btnFtnSize*1.6 + 'px'}">                
                    <!-- Add 2 Buttons in same line -->
                    <div class="flex justify-left rounded-md" role="group">
                        <!-- Bounding Box annotation button -->
                        <button type="button" class="butto rounded-md mx-0 px-1" 
                        @mousedown="onBBoxButtonClicked(event, label)"
                        @touchstart="onBBoxButtonClicked(event, label)"
                        :style="[
                            (label=='Ok') ? { opacity: 0.0 } : (allPredictions && allPredictions[label] !== undefined && allPredictions[label] !== null) ? {opacity: 0.20 + (1-0.20) * allPredictions[label]} : {opacity: 0.20},
                            (label=='Ok') ? { disabled: true } : { disabled: false },
                            {'font-size': btnFtnSize + 'px'},
                            {'backgroundColor': labelColors[labelIndex]}, 
                            (drawingLabel == label) ? {border: '2px solid red', opacity: 0.99, fontColor: 'black'} : {'border': null},
                            (showBBoxButtons && labelsWithDetection.includes(label)) ? {'visibility': 'visible'} : {'visibility': 'hidden'},
                        ]"
                        >
                            <i class="bi bi-aspect-ratio"></i>
                        </button>
                        <!-- Label button -->
                        <button type="button" class="button rounded-md mx-0" @mousedown="onImgButtonCliked($event, label)" @touchstart="onImgButtonCliked($event, label)" v-bind:title="label"
                            :style="[
                                (allPredictions && allPredictions[label] !== undefined && allPredictions[label] !== null) ? {opacity: 0.20 + (1-0.20) * allPredictions[label]} : {opacity: 0.20},
                                {'font-size': btnFtnSize + 'px'},
                                {color: 'black'},
                                {'backgroundColor': labelColors[labelIndex]}, 
                                assignLabels.includes(label)? {border: '2px solid red', opacity: 0.99, fontColor: 'black'} : {'border': null}, 
                            ]"  
                        >
                            <span class="percentInButton" >{{label}}</span>
                            <span v-if="allPredictions[label] > -0.1" class="percentInButton" >{{"(" + Math.round(100 * allPredictions[label]) + "%)"}}</span>
                        </button>  
                    </div>                  
                </div>
            </div>
        </div>
        <div class="bottom-right">
            <i v-if="showComment" class="bi bi-chat-right-text text-2xl px-0 float-none cursor-pointer" @click="onOpenComment()" title="Comment"></i>
        </div>
        <div class="centered text-red-600">{{centreMessage}}</div>
        <!-- For image comment -->
        <div class="centered">
            <div v-if="openComment" class="bg-gray-100 border-2 border-gray-200 rounded-lg p-2">
                <textarea class="border-black" v-model="commentText" style="color: black; padding: 5px" type="text" cols="40" rows="10" ref="commentTextArea" @click="onClickComment()"></textarea>
                <!-- Accept / close buttons -->
                <div class="flex justify-center">
                    <table style="width:100%">
                        <tr style="width:100%">
                            <td style="text-align: center"><button @click="onSaveComment()" class="bg-button-green rounded-lg">Save</button></td>
                            <td style="text-align: center"><button @click="onCancelComment()" class="bg-button-red rounded-lg">Close</button></td>
                        </tr>
                    </table>   
                </div>
            </div>
        </div>
  </div>  
</template>

<script>
import { Options, Vue } from 'vue-class-component'; 
import { relativeDateTime } from '@/library/utils'
import { decryptImgUrl, decryptImageB64 } from '@/library/crypto'
import Konva from 'konva';

const SEND_ANNOTAION_DELAY_MS = 5 * 1000; // Delay to send annotation to server

@Options({
    components: {
    },
    data: function(){
        return {
            labelColors: ['#55a868', '#4c72b0', '#dd8452', '#c44e52', '#8172b3', '#937860', '#da8bc3', '#8c8c8c', '#ccb974', '#64b5cd', '#4c72b0', '#dd8452', '#55a868', '#c44e52', '#8172b3', '#937860'],
            imgRealHeight: null,
            imgRealWidth: null,
            imgOpacity: 0,
            imgSource: null,
            imgOriginalWidth: null,
            imgOriginalHeight: null,
            showImg: true,
            isEncrypted: false,
            mosuseDown: false,
            showMetadata: true,
            centreMessage: null,

            newAssignLabels: [],      // Array of assigned labels. Initially opied from assignLabels
            newAssignBBoxes : {},     // Array of assigned BBoxes. Initially copied from assignBBoxes

            pendingAnnotation : {},   // Pending annotation to send to server

            stage: null,           // Konva stage object
            layer: null,           // Konva layer object
            transformer: null,     // Konva transformer object
            elementSelected: false, // Flag to indicate if an element is selected
            justUnselected: false, // Flag to indicate if just unselected to avoid propagation of click event

            image: null,           // Image object
            imageDownloading: false, // Flag to indicate if image is downloading
            bBoxes: [],            // Array of bounding boxes

            drawing: false,        // Track if drawing is active
            drawingLabel: null,    // Label of the drawing
            newRect: {             // Rectangle object
                x: 0,               // X-coordinate
                y: 0,               // Y-coordinate
                width: 0,           // Rectangle width
                height: 0,          // Rectangle height
                strokeWidth: 1,     // Stroke width
            },
            imageConfig: {image: this.image, name: 'image', rotation: 0, x: 0, y:0}, // Image configuration

            justSelected: false,  // Flag to indicate if just selected to avoid propagation of click event
            justButtonClicked: false,  // Flag to indicate if just selected to avoid propagation of click event

            annotateImageTask : null, // Task to annotate image
            lastImgSrc: null,         // Last image source

            openComment: false,       // Flag to show comment
            commentText: '',          // Comment text. First taken from imgMetaData.Comment

            showPixelCoordinates: false, // Flag to show pixel coordinates
        }
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        imgObj(newValue, oldValue) {
            // console.log(`InferImageML.imgObj changed from: ${oldValue} to: ${newValue} in image: ${this.imgFileKey}`);
        },
        // eslint-disable-next-line no-unused-vars
        imgHeight(newValue, oldValue) {
            console.log(`InferImageML.imgHeight changed from: ${oldValue} to: ${newValue}`);
            setTimeout(() => {this.recalculateImgHeight();}, 1);
        },
        // eslint-disable-next-line no-unused-vars
        numColumns(newValue, oldValue) {
            console.log(`InferImageML.numColumns changed from: ${oldValue} to: ${newValue}`);
            setTimeout(() => {this.recalculateImgHeight();}, 1);
        },
        // eslint-disable-next-line no-unused-vars
        imgFileKey(newValue, oldValue) {
            // console.log(`InferImageML(${this.rowNumber}:${this.colNumber}-${this.$.uid}) imgFileKey changed from: ${oldValue} to: ${newValue}`);
            setTimeout(() => {this.getImgSource('imgFileKey');}, 1);
        },
        // eslint-disable-next-line no-unused-vars
        imgSrc(newValue, oldValue) {
            // console.log(`imgSrc changed from: ${oldValue} to: ${newValue}`);
            setTimeout(() => {this.getImgSource('imgSrc');}, 1);
        },
        // eslint-disable-next-line no-unused-vars
        imgEnc(newValue, oldValue) {
            // console.log(`imgEnc changed from: ${oldValue} to: ${newValue}`);
            setTimeout(() => {this.getImgSource('imgEnc');}, 1);
        },
        // eslint-disable-next-line no-unused-vars
        imgMetaData(newValue, oldValue) {
            // console.log(`InferImageML(${this.rowNumber}:${this.colNumber}-${this.$.uid}).imgMetaData changed from: ${JSON.stringify(oldValue)} to: ${JSON.stringify(newValue)}`);
            this.commentText = newValue.Comment;
        },
        // eslint-disable-next-line no-unused-vars
        imgObjChanged(newValue, oldValue) {
            // console.log(`InferImageML(${this.rowNumber}:${this.colNumber}-${this.$.uid}).imgObjChanged changed from: ${oldValue} to: ${newValue} in image: ${this.imgFileKey}`);
            // console.log(`InferImageML(${this.rowNumber}:${this.colNumber}-${this.$.uid}).imgObjChanged Changed. assignLabels: ${JSON.stringify(this.assignLabels)}`);
            // Copy annotattions
            this.newAssignLabels = this.assignLabels.slice(); // Clone array
            // Draw rectangles
            if (this.assignBBoxes && this.assignBBoxes.length >= 0) this.drawBBoxes(this.assignBBoxes, 'assign');
        },
        // eslint-disable-next-line no-unused-vars
        allLabels(newValue, oldValue) {
            // console.log(`InferImageML.allLabels changed from: ${oldValue} to: ${newValue}`);
        },
    },
    props: {
        devName: {type: String, default: null},
        imgObj: {type: Object, default: {}},
        imgObjChanged: {type: Boolean, default: false},
        camPos: {type: Number, default: null},
        imgHeight: {type: Number, default: null},
        imgFileKey: {type: String, default: null},
        imgSrc: {type: String, default: null},
        imgEnc: {type: String, default: null},
        decryptPswd: {type: String, default: null},
        imgTimestamp: {type: Number, default: null},
        predictLabels: {type: Array, default: []},
        predictBBoxes: {type: Object, default: {}},
        assignLabels: {type: Array, default: []},
        assignBBoxes: {type: Object, default: {}},
        allLabels: {type: Array, default: []},
        labelsWithDetection: {type: Array, default: []},
        allPredictions: {type: Object, default: null},
        imgMetaData: {type: Object, default: null},
        rotate180: {type: Boolean, default: false},
        numColumns: {type: Number, default: null},
        hideImg: {type: Boolean, default: false},
        showSelection: {type: Boolean, default: false},
        selected: {type: Boolean, default: false},
        showBBoxButtons: {type: Boolean, default: false},
        datasetIdSelected: {type: String, default: null},
        hideWhenLoading: {type: Boolean, default: true},
        innerBorderColor: {type: String, default: 'white'},
        showComment: {type: Boolean, default: false},
        outerBorderColor: {type: String, default: 'black'},
        rowNumber: {type: Number, default: null},
        colNumber: {type: Number, default: null},
    },
    methods: {
        setImgUrl(imgUrl) {
            this.imgSource = imgUrl;
            this.centreMessage = null;
        },
        onDecryptUrlFailure(message) {
            console.log(`Decrypt image failure: ${message}`)
            this.centreMessage = message;
        },
        setImgBytesSrc(imgDecrypted) {
            this.imgSource = imgDecrypted;
            this.centreMessage = null;
        },
        onDecryptBytesFailure(message) {
            console.log(`Decrypt image failure: ${message}`);
            this.centreMessage = message;
        },
        // eslint-disable-next-line no-unused-vars
        getImgSource(reason=null) {
            let preSignExprTs = this.imgObj.PreSignExprTs;
            let timeToExpire = preSignExprTs - Date.now();
            // console.log(`InferImageML(${this.rowNumber}:${this.colNumber}-${this.$.uid}).getImgSource.Reason: ${reason}, imgFileKey: ${this.imgFileKey}, imgSrc: ${this.imgSrc}, imgEnc: ${this.imgEnc}, PreSignExprTs: ${preSignExprTs}, TimeToExpire: ${timeToExpire}`);
            // If image close to be expired --> notify parent
            if (timeToExpire < 3 * 1000) { // Less than 3 seconds
                this.$emit("onImgExpired", this.imgObj);
                return;
            }

            // If no schange in ingSrc, do nothing
            if (this.imgSrc === this.lastImgSrc) {
                // console.log(`InferImageML(${this.rowNumber}:${this.colNumber}-${this.$.uid}).getImgSource. No change in imgSrc`);
                return;
            } else {
                // console.log(`InferImageML(${this.rowNumber}:${this.colNumber}-${this.$.uid}).getImgSource. Change in imgSrc from ${this.lastImgSrc} to ${this.imgSrc}`);
            }
            this.lastImgSrc = this.imgSrc;
            // Notify parent that image is being loaeded
            this.$emit("onImgLoading", this.imgFileKey);
            // Hide image if required
            if (this.hideWhenLoading) this.showImg = false;
            if (this.imgEnc !== null || (this.imgFileKey && this.imgFileKey.endsWith('.enx'))) this.isEncrypted = true; 
            else this.isEncrypted = false;
            if (this.isEncrypted) { // Encrypted image
                //console.log(`Image is encrypted: ${this.imgEnc}`);
                if (this.imgEnc !== null) { // encrypted image is provided as encrypted jpg bytearray
                    decryptImageB64(this.imgEnc, this.decryptPswd, this.setImgBytesSrc, this.onDecryptBytesFailure)
                } else { // Assumed image is provided as an encrypted file URL
                    // Fecth the encrypted image
                    let imgUrl = this.imgSrc;
                    console.log(`Decrypting Image URL: ${imgUrl}`);
                    decryptImgUrl(imgUrl, this.decryptPswd, this.setImgUrl, this.onDecryptUrlFailure);
                }
            } else { // non-encrypted image
                // console.log(`Image is non-encrypted`)
                this.imgSource = this.imgSrc;
                // Update image object
                if (this.image) {
                    this.image.src = this.imgSource;
                    this.imageDownloading = true;
                    this.imageConfig.image = this.image;
                } else {
                    // console.error(`InferImageML(${this.rowNumber}:${this.colNumber}-${this.$.uid}).getImgSource. Image not yet loaded`);
                }
            }
        },
        annotateImageDelayed(newAssignLabels, newAssignBBoxes, imgObj, imgMetaData, imgFileKey, camPos) {
            // Save annotations to image object
            this.imgObj.asls = newAssignLabels;
            this.imgObj.md.AssignLabels = newAssignLabels;
            this.imgObj.asbbs = newAssignBBoxes;
            this.imgObj.md.AssignBBoxes = newAssignBBoxes;
            this.imgObj.chng = !this.imgObj.chng; // Toggle change flag to force re-render
            // Save annotations to pending annotation
            this.pendingAnnotation = {
                'assignLabels': newAssignLabels,
                'assignBBoxes': newAssignBBoxes,
                'imgObj': imgObj,
                'imgMetaData': imgMetaData,
                'imgFileKey': imgFileKey,
                'camPos': camPos,
            }
            console.log(`annotateImageDelayed. newAssignLabels: ${newAssignLabels}, newAssignBBoxes: ${JSON.stringify(newAssignBBoxes)}`);

            if (this.annotateImageTask) { clearTimeout(this.annotateImageTask); }
            this.annotateImageTask = setTimeout(() => {
                this.annotateImageTask = null;
                this.annotateImage(newAssignLabels, newAssignBBoxes, imgObj, imgMetaData, imgFileKey, camPos);
            }, SEND_ANNOTAION_DELAY_MS);
        },
        annotateImage(newAssignLabels, newAssignBBoxes, imgObj, imgMetaData, imgFileKey, camPos) {
            console.log(`annotateImage. newAssignLabels: ${newAssignLabels}, newAssignBBoxes: ${JSON.stringify(newAssignBBoxes)}`);
            this.$emit("onAnnotateImage", imgObj, imgMetaData, imgFileKey, camPos, newAssignLabels, newAssignBBoxes);
        },
        setImageComment(comment) {
            this.imgMetaData.Comment = comment;
            console.log(`setImageComment. FileKey: ${this.imgFileKey}, Comment: ${comment}`);
            this.$emit("onSetComment", this.imgObj, this.imgMetaData);
        },
        onOpenComment() {
            console.log(`onOpenComment`);
            this.openComment = true;
            // >Focus on text areara after object created
            setTimeout(() => {this.$refs.commentTextArea.focus();}, 10);
            
        },
        onClickComment() {
            console.log(`onClickComment`);
            // >Focus on text areara
            this.$refs.commentTextArea.focus();
        },
        onSaveComment() {
            console.log(`onSaveComment. Comment: ${this.commentText}`);
            this.setImageComment(this.commentText);
            this.openComment = false;
        },
        onCancelComment() {
            console.log(`onCancelComment`);
            this.openComment = false;
            this.commentText = this.imgMetaData.Comment; // Restore original comment
        },
        sendPendingAnnotation() {
            // Send pending annotattions to backend if any pending
            if (this.annotateImageTask) {
                console.log(`sendPendingAnnotation. newAssignLabels: ${this.pendingAnnotation.assignLabels}, newAssignBBoxes: ${JSON.stringify(this.pendingAnnotation.assignBBoxes)}`);   
                clearTimeout(this.annotateImageTask);
                this.annotateImageTask = null;
                this.annotateImage(this.pendingAnnotation.assignLabels, this.pendingAnnotation.assignBBoxes, this.pendingAnnotation.imgObj, this.pendingAnnotation.imgMetaData, this.pendingAnnotation.imgFileKey, this.pendingAnnotation.camPos);
            } else {
                // console.log(`sendPendingAnnotation. No pending annotation`);   
            }
        },
        onBBoxButtonClicked(event, label) {
            if (this.showBBoxButtons && this.labelsWithDetection.includes(label)) {
                (this.drawingLabel == label) ? this.drawingLabel = null : this.drawingLabel = label;
                if (!this.newAssignLabels.includes(label)) this.onImgButtonCliked(event, label)
                this.justButtonClicked = true;
            }         
        },
        onImgButtonCliked(event, label) {
            // console.log(`onImgButtonCliked. Key: ${imgFileKey}, Pos: ${pos}, Label: ${label}, Metadata: ${JSON.stringify(imgMetaData)}`);
            //this.$emit("onImgButtonCliked", imgObj, imgFileKey, pos, label, imgMetaData);

            // Check that any daatset is selected
            if (!this.datasetIdSelected) {
                alert(`To be able to label images you need to select first the Data Set`);
                return;
            }
            // If clicked any label already selected --> remove assign label
            if (this.newAssignLabels.includes(label)) {
                // Remove label and BBoxes
                console.log(`Label: ${label} clicked again. Unassigning label and removing all BBoxes of the label`);
                this.newAssignLabels.splice(this.newAssignLabels.indexOf(label), 1);
                // Remove all BBoxes of the label
                this.removeAllBBoxesOfLabel(label);
                
            } else { // Add label
                console.log(`Label: ${label} clicked. Assigning label`);
                this.newAssignLabels.push(label);
                // Ensure exclusivity between 'Ok' and rest of defects
                if (label === 'Ok') {
                    this.newAssignLabels = ['Ok']; // Remove the rest of labels
                    // Remove all BBoxes of all labels
                    for (const label in this.assignBBoxes) {
                        this.removeAllBBoxesOfLabel(label);
                    }
                }
                else if (this.newAssignLabels.includes('Ok')) this.newAssignLabels.splice(this.newAssignLabels.indexOf('Ok'), 1); // --> remove 'Ok if exist'
                // Ensure exclusivity between 'Bg' and rest of defects
                if (label === 'Bg') {
                    this.newAssignLabels = ['Bg']; // Remove the rest of labels
                    // Remove all BBoxes of all labels
                    for (const label in this.assignBBoxes) {
                        this.removeAllBBoxesOfLabel(label);
                    }
                }
                else if (this.newAssignLabels.includes('Bg')) this.newAssignLabels.splice(this.newAssignLabels.indexOf('Bg'), 1); // --> remove 'Bg if exist'
            }
            this.newAssignBBoxes = this.getAssignBoxesFromRectangles();
            this.annotateImageDelayed(this.newAssignLabels, this.newAssignBBoxes, this.imgObj, this.imgMetaData, this.imgFileKey, this.camPos);

            this.justButtonClicked = true;
        },
        onImgLoaded(event, fileKey) {
            // console.log(`InferImageML(${this.rowNumber}:${this.colNumber}-${this.$.uid}).onImgLoaded. FileKey: ${fileKey}`);


            // Check if need to load a new image
            if (this.image.src !== this.imgSource) {
                console.log(`InferImageML(${this.rowNumber}:${this.colNumber}-${this.$.uid}).onImgLoaded. Image source changed from: ${this.image.src} to: ${this.imgSource}`);
                this.image.src = this.imgSource;
                this.imageDownloading = true;
                this.imageConfig.image = this.image;
                return;
            }

            //console.log(`onImgLoaded. SRC: ${event.target.src}`);
            // this.imgOriginalWidth = this.$refs.image.naturalWidth;
            // this.imgOriginalHeight = this.$refs.image.naturalHeight;
            this.centreMessage = null;
            this.showImg = true;

            // Send pending annotattions to backend if any pending
            this.sendPendingAnnotation();

            // Copy annotattions
            this.newAssignLabels = this.assignLabels.slice(); // Clone array
            this.newAssignBBoxes = {...this.assignBBoxes}; // Clone object

            this.imgOriginalWidth = this.image.naturalWidth;
            this.imgOriginalHeight = this.image.naturalHeight;

            this.transformer.nodes([]); // Deselect all rectangles if any

            // console.log(`InferImageML.imgOriginalWidth: ${this.imgOriginalWidth} Height: ${this.imgOriginalHeight}`);

            this.recalculateImgHeight();
            // Draw predicted rectangles
            if (this.predictBBoxes && this.predictBBoxes.length >= 0) this.drawBBoxes(this.predictBBoxes, 'predict');
            // Draw assign rectangles
            if (this.assignBBoxe && this.assignBBoxes.length >= 0) this.drawBBoxes(this.assignBBoxes, 'assign');
            // Propagate event to parent
            const imgData = {fileKey: fileKey, imgOriginalWidth: this.imgOriginalWidth, imgOriginalHeight: this.imgOriginalHeight};
            this.$emit("onImgLoaded", imgData);
            // console.log(`InferImageML.onImgLoaded. AssignLabels: ${JSON.stringify(this.assignLabels)}, labelsWithDetection: ${JSON.stringify(this.labelsWithDetection)}`);
        },
        onImgLoadError(event, fileKey) {
            console.log(`InferImageML.onImgLoadError. FileKey: ${fileKey}`);
            this.centreMessage = 'Image load error';
            if (this.hideWhenLoading) this.showImg = false;
            // Propagate event to parent
            const imgData = {fileKey: fileKey};
            this.$emit("onImgLoadError", imgData);
        },
        recalculateImgHeight() {
            // this.imgRealHeight = this.$refs.inferImage.clientHeight;
            // this.imgRealWidth = this.$refs.inferImage.clientWidth;

            if (this.image) {
                if (this.imgRealHeight != this.imgHeight) {
                    // console.log(`InferImageML.recalculateImgHeight. imgHeight changed from: ${this.imgRealHeight} to: ${this.imgHeight}`);
                    this.imgRealHeight = this.imgHeight;

                    this.imgRealWidth = this.imgRealHeight * this.imgOriginalWidth / this.imgOriginalHeight;
                    this.image.height = this.imgRealHeight;
                    this.image.width = this.imgRealWidth;
                    // Draw predicted rectangles
                    if (this.predictBBoxes && this.predictBBoxes.length >= 0) this.drawBBoxes(this.predictBBoxes, 'predict');
                    // Draw assign rectangles
                    if (this.assignBBoxes && this.assignBBoxes.length >= 0) this.drawBBoxes(this.assignBBoxes, 'assign');
                    // console.log(`InferImageML.recalculateImgHeight. imgRealHeight: ${this.imgRealHeight}, imgRealWidth: ${this.imgRealWidth}`);
                    // Apply rotattion if needed
                    if (this.rotate180) {
                        this.imageConfig.rotation = 180;
                        this.imageConfig.x = this.imgRealWidth;
                        this.imageConfig.y = this.imgRealHeight;
                        this.imageConfig.image = this.image;
                    } else {
                        this.imageConfig.rotation = 0;
                        this.imageConfig.x = 0;
                        this.imageConfig.y = 0;
                        this.imageConfig.image = this.image;
                    }
                }            
            }
        },
        relativeDateTime(TimeStampMs, seconds, milliseconds) {
            return relativeDateTime(TimeStampMs, seconds, milliseconds);
        },
        onSelection(event, imgObj) {
            let selected = event.target.checked;
            console.log(`Selected: ${selected}`);
            imgObj.selected = selected;
            this.$emit("onSelection", imgObj, selected);
            this.justSelected = true;
        },
        onCtrlClickSelection(event, imgObj) {
            if (this.showSelection) {
                let selected = imgObj.selected;
                imgObj.selected = !selected;
                console.log(`onCtrlClickSelection. Selected: ${selected} changed to: ${imgObj.selected}`);                
                this.$emit("onSelection", imgObj, imgObj.selected);
                event.stopPropagation();
                //event.preventDefault();
            }
        },
        onMouseDown() {
            console.log(`onMouseDown`);
            this.mosuseDown = true;
            // Check delay if need to hide labels
            setTimeout(() => {if(this.mosuseDown) this.showMetadata = false}, 300);

            this.startDrawing();
        },
        onMouseUp() {
            console.log(`onMouseUp`);
            this.mosuseDown = false;
            this.showMetadata = true;

            const validRect = this.stopDrawing();
            // If not a valid rectangle, not just selected and not just clicked on a button, propagate imgClicked event to parent
            console.log(`onMouseUp. validRect: ${validRect}, justSelected: ${this.justSelected}, justButtonClicked: ${this.justButtonClicked}, elementSelected: ${this.elementSelected}, justUnselected: ${this.justUnselected}`)
            if (!validRect && !this.justSelected && !this.justButtonClicked && !this.elementSelected && !this.justUnselected) {
                console.log(`onMouseUp. Propagate imgClicked event to parent`)
                // Propagate imgClicked event to parent
                this.$emit("imgClicked");
            }
            this.justSelected = false;
            this.justButtonClicked = false;
            this.justUnselected = false; // Reset flag
        },        
        // Method to start drawing when the button is clicked
        startDrawing() {
            if (this.drawingLabel === null) return; // If no label selected, do nothing
            if (this.drawing) return; // If already drawing, do nothing
            if (this.elementSelected) return; // If an element is selected, do not start drawing
            console.log(`startDrawing`);

            this.drawing = true;
            // get mouse position relative to the stage
            const pos = this.stage.getPointerPosition();
            // save initial position and set width and height to zero
            this.newRect.x = pos.x;
            this.newRect.y = pos.y;
            this.newRect.width = 0;
            this.newRect.height = 0;
            this.newRect.stroke = this.colorOfLabel(this.drawingLabel);
            this.$refs.rect.getNode().setAttr('label', this.drawingLabel);
        },
        // Method to draw a rectangle while the mouse is moved
        drawRectangle(e) {
            // console.log(`drawRectangle: ${this.drawing}, elementSelected: ${this.elementSelected}`)
            if (!this.drawing) return; // If not drawing, do nothing
            if (this.elementSelected) return; // If an element is selected, do not start drawing
            // avoid event bubbling
            e.cancelBubble = true;            

           // get mouse position relative to the stage
            const pos = this.stage.getPointerPosition();
            // update width and height based on mouse position
            this.newRect.width = pos.x - this.newRect.x;
            this.newRect.height = pos.y - this.newRect.y;

            console.log(`drawRectangle. StartX: ${this.newRect.x}, StartY: ${this.newRect.y}, Width: ${this.newRect.width}, Height: ${this.newRect.height}`);            
        },
        // Method to stop drawing when the mouse button is released
        // Return true if a valid rectangle was drawn
        stopDrawing() {
            console.log(`stopDrawing: ${this.drawing}, elementSelected: ${this.elementSelected}`)
            if (!this.drawing) return false; // If not drawing, do nothing
            this.drawing = false;
            if (this.elementSelected) return false; // If an element is selected, do not start drawing            

            // Calculate the rectangle parameters
            const minX = Math.min(this.newRect.x, this.newRect.x + this.newRect.width);
            const maxX = Math.max(this.newRect.x, this.newRect.x + this.newRect.width);
            const minY = Math.min(this.newRect.y, this.newRect.y + this.newRect.height);
            const maxY = Math.max(this.newRect.y, this.newRect.y + this.newRect.height);
            const width = maxX - minX;
            const height = maxY - minY;
            const centerX = minX + width / 2;
            const centerXNorm = centerX / this.imgRealWidth;
            const centerY = minY + height / 2;
            const centerYNorm = centerY / this.imgRealHeight;
            const widthNorm = width / this.imgRealWidth;
            const heightNorm = height / this.imgRealHeight;

            console.log(`stopDrawing. centerXNorm: ${centerXNorm}, centerYNorm: ${centerYNorm}, widthNorm: ${widthNorm}, heightNorm: ${heightNorm}`);

            // Add the rectangle to the array of rectangles making a clone of the object
            // If area is too small, ignore
            const areaNorm = widthNorm * heightNorm;
            if (areaNorm > 0.0001) { // At least 0.01% of the image
                this.bBoxes.push(Object.assign({}, this.newRect));
                console.log('New Rectangle Coordinates:', { minX, minY, maxX, maxY }, ' Label: ', this.$refs.rect.getNode().getAttr('label'), ' Area: ', areaNorm);

                // Add to layer
                const rect = new Konva.Rect({
                    x: minX,
                    y: minY,
                    width: maxX - minX,
                    height: maxY - minY,
                    stroke: this.newRect.stroke,
                    strokeWidth: 1,
                    name: 'rect',
                    draggable: false,
                })
                rect.setAttr('label', this.$refs.rect.getNode().getAttr('label'));
                this.layer.add(rect);
                // Send new annotation to backend
                this.newAssignBBoxes = this.getAssignBoxesFromRectangles();
                this.annotateImageDelayed(this.newAssignLabels, this.newAssignBBoxes, this.imgObj, this.imgMetaData, this.imgFileKey, this.camPos);

                return true;
            }
            else {
                console.log(`Area too small: ${areaNorm}`);
                return false;
            }                   
        },
        getAssignBoxesFromRectangles() {
            // Get the bounding boxes from the rectangles
            const rects = this.layer.find('.rect');
            const bBoxes = {};
            rects.forEach((rect) => {
                // console.log(`getAssignBoxesFromRectangles. Rect: ${JSON.stringify(rect)}`);
                const minX = rect.x();
                const maxX = rect.x() + rect.width() * rect.scaleX();
                const minY = rect.y();
                const maxY = rect.y() + rect.height() * rect.scaleY();
                const width = maxX - minX;
                const height = maxY - minY;
                const centerX = minX + width / 2;
                const centerXNorm = centerX / this.imgRealWidth;
                const centerY = minY + height / 2;
                const centerYNorm = centerY / this.imgRealHeight;
                const widthNorm = width / this.imgRealWidth;
                const heightNorm = height / this.imgRealHeight;
                // Create bBoxes[label] if not exist
                console.log(`Label: ${rect.getAttr('label')}`);
                if (!bBoxes[rect.getAttr('label')]) bBoxes[rect.getAttr('label')] = [];
                // Add the rectangle to the array of rectangles making a clone of the object
                // With 5 decimals
                bBoxes[rect.getAttr('label')].push({
                    X: Math.round(centerXNorm * 100000) / 100000,
                    Y: Math.round(centerYNorm * 100000) / 100000,
                    W: Math.round(widthNorm * 100000) / 100000,
                    H: Math.round(heightNorm * 100000) / 100000,
                });
            });
            return bBoxes;
        },
        drawBBoxes(bBoxes, type='assign') {
            // console.log(`drawBBoxes. in image: ${this.imgFileKey}, Type: ${type} bBoxes: ${JSON.stringify(bBoxes)}`);
            // Define stroke type
            let strokeDash = type === 'assign' ? null: [10, 5];
            // Remove all BBoxes of the label
            const rects = this.layer.find('.rect');           
            // Remove new rectangle
            this.newRect.width = 0;
            this.newRect.height = 0;
            rects.forEach((rect) => { 
                console.log(`drawBBoxes. Removing rect: ${JSON.stringify(rect)}`);
                rect.destroy(); 
            });
            // Add all BBoxes
            for (const label in bBoxes) {
                const bBoxList = bBoxes[label];
                bBoxList.forEach((bBox) => {
                    const minX = (bBox.X - bBox.W / 2) * this.imgRealWidth;
                    const maxX = (bBox.X + bBox.W / 2) * this.imgRealWidth;
                    const minY = (bBox.Y - bBox.H / 2) * this.imgRealHeight;
                    const maxY = (bBox.Y + bBox.H / 2) * this.imgRealHeight;
                    // console.log(`drawBBoxes. Label: ${label}, imgRealWidth: ${this.imgRealWidth}, imgRealHeight: ${this.imgRealHeight}, X: ${bBox.X}, Y: ${bBox.Y}, W: ${bBox.W}, H: ${bBox.H}, minX: ${minX}, minY: ${minY}, maxX: ${maxX}, maxY: ${maxY}`)
                    // Add to layer
                    this.layer.add(new Konva.Rect({
                        x: minX,
                        y: minY,
                        width: maxX - minX,
                        height: maxY - minY,
                        stroke: this.colorOfLabel(label),
                        label: label,
                        strokeWidth: 1,
                        dash: strokeDash,
                        name: 'rect',
                        draggable: false,
                    }));
                });
            }
            this.layer.draw();
        },
        removeAllBBoxesOfLabel(label) {
            console.log(`removeAllBBoxesOfLabel: ${label}`);
            // Remove all BBoxes of the label from the layer
            const rects = this.layer.find('.rect');
            rects.forEach((rect) => {
                if (rect.getAttr('label') === label) rect.destroy();
            });
            this.layer.draw();
        },
        colorOfLabel(label) {
            const labelIndex = this.allLabels.indexOf(label);
            return this.labelColors[labelIndex];
        },
    },
    computed: {     
        textHeight: function() { return Math.min(Math.max(this.imgRealHeight/10, 10), 20); },
        btnFtnSize: function() { return Math.min(Math.max(this.imgRealHeight/this.allLabels.length/1.6, 10), 20); },
        posIdx: function() { return `${this.rowNumber}:${this.colNumber}-${this.$.uid}`; },
    },
    // Lifecycle hooks
    mounted() {
        // console.log(`InferImageML View Mounting.(${this.rowNumber}:${this.colNumber}-${this.$.uid})`);
        window.addEventListener("resize", this.recalculateImgHeight);
        //
        this.getImgSource('mounted');

        // console.log(`imgHeight: ${this.imgHeight}`);

        // Get image comment
        if (this.imgMetaData) {
            // console.log(`imgMetaData: ${JSON.stringify(this.imgMetaData)}`);
            // console.log(`Comment: ${this.imgMetaData.Comment}`);
            this.commentText = this.imgMetaData.Comment;
        }

        // Create image object for konva
        const image = new Image();
        image.src = this.imgSource;
        this.imageDownloading = true;
        // image.width = "100%";
        // image.height = "100%";
        image.ref = 'image';
        image.onload = (event) => {
            // console.log(`Image loaded. Event: ${JSON.stringify(event)}, Type: ${event.type}`);
            this.imageDownloading = false;
            this.image = image;
            this.onImgLoaded(event, this.imgFileKey);
            // console.log(`stage: ${JSON.stringify(this.stage)}, layer: ${JSON.stringify(this.layer)}`)
        };
        // On inmage load error
        image.onerror = (event) => {
            console.log(`Image load error. Event: ${JSON.stringify(event)}, Img: ${JSON.stringify(this.imgObj)}`);
            this.centreMessage = 'Image load error';
            this.onImgLoadError(event, this.imgFileKey);
        }
        // console.log(`imgRealWidth: ${this.imgRealWidth}, imgRealHeight: ${this.imgRealHeight}`);
        // Get Kova structure
        this.stage = this.$refs.stage.getNode();
        this.layer = this.$refs.layer.getNode();
        this.transformer = new Konva.Transformer({rotateEnabled: false});
        this.layer.add(this.transformer);
        this.transformer.on('transformend dragend', () => {
            console.log(`transformation finished`);
            // Send new annotation to backend
            this.newAssignBBoxes = this.getAssignBoxesFromRectangles();
            console.log(`newAssignBBoxes: ${JSON.stringify(this.newAssignBBoxes)}`);
            this.annotateImageDelayed(this.newAssignLabels, this.newAssignBBoxes, this.imgObj, this.imgMetaData, this.imgFileKey, this.camPos);
        });
        // Add event listener for mouse down to select rectangle
        this.stage.on('click tap', (e) => {
            // e.evt.preventDefault();
            console.log(`stage clicked: ${JSON.stringify(e.target)}`);
            // if click on empty area - remove all transformers
            if (e.target === this.stage || e.target.hasName('image')) {
                this.layer.find('.rect').forEach((rect) => { rect.draggable(false); }); // Set to all rectangles not draggable
                this.transformer.nodes([]); // Deselect all
                if (this.elementSelected) {
                    this.elementSelected = false;
                    this.justUnselected = true;
                    return;
                }
            }
            // do nothing if clicked NOT on our rectangles
            if (!e.target.hasName('rect')) {
                return;
            }
            //
            console.log(`Rectagle selected: ${JSON.stringify(e.target)}`);
            // Make it draggable
            e.target.draggable(true);
            // Add to transformer
            this.transformer.nodes([e.target]);
            this.elementSelected = true;
        });
        // Add event listener for keystroke 'delete'
        window.addEventListener('keydown', (e) => {
            if (e.code === "Delete") {
                console.log(`delete key pressed`);
                // remove selected nodes
                const selectedNodes = this.transformer.nodes();
                if (selectedNodes.length > 0) {
                    selectedNodes.forEach((node) => {
                        node.destroy();
                    });
                    this.transformer.nodes([]);
                    this.layer.draw();
                    this.elementSelected = false;
                    this.justUnselected = false;
                    // Send new annotation to backend
                    this.newAssignBBoxes = this.getAssignBoxesFromRectangles();
                    this.annotateImageDelayed(this.newAssignLabels, this.newAssignBBoxes, this.imgObj, this.imgMetaData, this.imgFileKey, this.camPos);
                }
            }
            // If 'p' key pressed --> Toggle showPixelCoordinates
            if (e.code === "KeyP") {
                this.showPixelCoordinates = !this.showPixelCoordinates;
            }
        });

        // Copy annotattions
        this.newAssignLabels = this.assignLabels.slice(); // Clone array
        this.newAssignBBoxes = {...this.assignBBoxes}; // Clone object

        // console.log(`InferImageML View Mounted.(${this.rowNumber}:${this.colNumber}-${this.$.uid})`);
    },
    unmounted() {
        //console.log('Terminal View Unmounted')
        window.removeEventListener("resize", this.recalculateImgHeight);
        this.sendPendingAnnotation();
    },
    errorCaptured() {console.log('Terminal View errorCaptured')},    
})
export default class Template extends Vue {}
</script>
// Style only for this component/view
<style scoped>

    .button {
        border: none;
        color: rgb(138, 174, 240);
        padding: 0px 0px;
        display:block;
    }

    .inferImage {
        position: relative;
        text-align: center;
        color: white;
        margin: 0 0 0 0;
        border-style: solid;
        border-width: 1px;
        border-color:#316327;
    }

    /* Bottom left text */
    .bottom-left {
        position: absolute;
        bottom: 4px;
        left: 8px;
    }

    /* Top left text */
    .top-left {
        position: absolute;
        top: 4px;
        left: 8px;
    }

    /* Top right text */
    .top-right {
        position: absolute;
        top: 0px;
        right: 8px;
    }

    /* Bottom right text */
    .bottom-right {
        position: absolute;
        bottom: 4px;
        right: 8px;
    }

    /* Centered text */
    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    table {
        text-align: left;
        position: relative;
        border-collapse: collapse; 
        text-indent: initial;
        white-space: normal;
        line-height: normal;
        font-weight: normal;
        font-size: medium;
        font-style: normal;
        color: -internal-quirk-inherit;
        text-align: start;
        border-spacing: 2px;
        font-variant: normal;
    }

    th, td {
        padding: 0.0rem;
    }

    .show {
        display: true;
    }

    .hide {
        display: none;
    }

    .button:focus {
        outline: none;
        box-shadow: none;
    }
    
</style>


