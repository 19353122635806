// Created by SPe on 08/07/22
// Page to review history
// Indentity pool and policies need to be configured: 
// Follow: https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/s3-example-photos-view.html


<template>
  <div class="history" ref="divContainer">
    <LoadingOverlay :show="loadingOverlay.show" :text="loadingOverlay.text"/>
    <div class="sticky-top" ref="navBar">        
        <NavBar 
            :devName="devName" 
            :showBackButton="showBackButton" 
            :showSpinner="showSpinner"
            :servConnected="servConnected" 
            :devConnected="devConnected"
            :showDatePicker="true"
            :selectTitle="'Dataset'"
            :selectPopUp="'Select a dataset to add labeled images'"
            :selectOptions="datasetOptions"
            :dateForDatePicker="date"
            :showInfo="helpInfo"
            @newOptionSelected="onNewOptionSelected"  
            @newDateSelected="onNewDateSelected"  
        />
    </div>    

    <div v-if="errorMsgPre !== ''">
        <h1 class="text-xl rounded-md px-3 mx-5 bg-red-300">{{errorMsgPre}}</h1>
    </div>
        
    <div id="mainScroller" v-if="camRowList.length" ref="mainScroller">
        <!-- :item-size="imgHeight+8" // +8 is for margin. Not sure why exactly 8 -->
        <RecycleScroller
            class="scroller"
            :items="camRowList"
            :item-size="imgHeight+8"
            key-field="id"
            :pageMode="true"            
            :buffer="500"
            :emitUpdate="true"
            @update="onScrollerUpdate"     
        >
            <template v-slot="{ item }">
                <div class="cam_row" 
                        :style="[item.id==rowIdSelected? {'border-top': '3px solid blue'}:item.id==rowIdSelected+1? {'border-top': '3px solid blue'}:{'border': null} ]">
                    <InferImageML v-for="pos in camPositions.slice().reverse().filter(pos => item[pos])" :key="pos"
                        :imgObj = "item[pos]"
                        :imgObjChanged = "item[pos].chng"
                        :camPos = pos
                        :imgHeight = "imgHeight"
                        :imgSrc = "item[pos].url"
                        :decryptPswd = "decryptionPassword"
                        :imgFileKey = "item[pos].fk"
                        :imgTimestamp = "getTimeIfExist(item[pos].dt)"
                        :predictLabels = "item[pos].pls"
                        :predictBBoxes = "item[pos].pbbs"
                        :assignLabels = "item[pos].asls"
                        :assignBBoxes = "item[pos].asbbs"
                        :allLabels = "item[pos].al"
                        :labelsWithDetection = "item[pos].lwd"
                        :allPredictions = "item[pos].ap"
                        :imgMetaData = "item[pos].md"
                        :rotate180 = "rotate180"
                        :showBBoxButtons = "true"
                        :datasetIdSelected = "datasetIdSelected"
                        :innerBorderColor = "imageInnerBorderColor(item[pos])"
                        @onImgLoading = "onImgLoading"
                        @onImgLoaded = "imgData => { onImgLoaded(item[pos], imgData)}"
                        @onImgLoadError = "imgData => { onImgLoadError(item[pos], imgData)}"
                        @onAnnotateImage = "onAnnotateImage"
                        @imgClicked = "onCamRowClick(item.id)"
                        @onImgExpired = "onImgExpired"
                    />                
                </div>
            </template>
        </RecycleScroller>
    </div>

    <div v-if="errorMsgPost !== ''" style="background-color: red;">
        <h1 style="font-size: 2em;">{{errorMsgPost}}</h1>
    </div>

    <!--Modal Overlay-->
    <div
        class="fixed hidden inset-0 bg-gray-600 bg-opacity-50 h-full w-full"                
        id="ImgModal"
    >
        <div v-if="imgModalIsShow && rowIdSelected != null" class="relative h-full w-full text-white flex items-center justify-center"> 
            <!-- Zoom Pan content  -->
            <div id="zoompan"> 
                <InferImageML v-for="pos in camPositions" :key="pos"
                    :imgObj = "camRowList[rowIdSelected][pos]"
                    :camPos = pos
                    :imgHeight = "imgHeightInModal"
                    :imgSrc = "camRowList[rowIdSelected][pos].url"
                    :decryptPswd = "decryptionPassword"
                    :imgFileKey = "camRowList[rowIdSelected][pos].fk"
                    :imgTimestamp = "getTimeIfExist(camRowList[rowIdSelected][pos].dt)"
                    :predictLabels = "camRowList[rowIdSelected][pos].pls"
                    :predictBBoxes = "camRowList[rowIdSelected][pos].pbbs"
                    :assignLabels = "camRowList[rowIdSelected][pos].asls"
                    :assignBBoxes = "camRowList[rowIdSelected][pos].asbbs"
                    :allLabels = "camRowList[rowIdSelected][pos].al"
                    :labelsWithDetection = "camRowList[rowIdSelected][pos].lwd"
                    :allPredictions = "camRowList[rowIdSelected][pos].ap"
                    :imgMetaData = "camRowList[rowIdSelected][pos].md"
                    :rotate180 = "! rotate180"
                    :showBBoxButtons = "true"
                    :datasetIdSelected = "datasetIdSelected"
                    :innerBorderColor = "imageInnerBorderColor(camRowList[rowIdSelected][pos])"
                    @onImgLoading = "onImgLoading"
                    @onImgLoaded = "imgData => { onImgLoaded(camRowList[rowIdSelected][pos], imgData)}"
                    @onImgLoadError = "imgData => { onImgLoadError(item[pos], imgData)}"
                    @onAnnotateImage = "onAnnotateImage"
                    @onImgExpired = "onImgExpired"
                />     
            </div>
            <div class="center-down">
                <button class="bg-red-500 bg-opacity-50 rounded-full w-10 h-10 text-red-500"
                    title="Close [X]"
                    @click="closeModal">X</button>
            </div>
        </div> 
    </div>

    <!-- Modal Window for Entering Password Form -->
      <Modal
          v-model="enterPasswwordModalShow"
          ref="modal"
      >
        <EnterPasswordForm entityType='Project' :entityName="projectName" @newPasswordSubmitted="newPasswordSubmitted"></EnterPasswordForm>        
      </Modal>
    <!-- Footernbar -->
    <Footer />
  </div>    
</template>

<script>
//import { EXIF } from 'exif-js';
import { RecycleScroller } from 'vue3-virtual-scroller';
import 'vue3-virtual-scroller/dist/vue3-virtual-scroller.css'
import { Options, Vue } from 'vue-class-component'; 
import { mapGetters } from 'vuex'
//import { sendMessageUnified } from '@/library/client-unified-send'
import { formatDateTimeForHistory, setDateFromShortStr, getProjectNameFromProjectId, getTimeIfExist, getProjectIdFromDatasetId } from '@/library/utils.js';
import store from '@/store/index.js';
import auth from '@/library/auth'; 
//import { sendWsMessage } from '@/library/websocket'
import LoadingOverlay from '@/components/LoadingOverlay.vue';
//import { connect as connectWrtc, close as closeWrtc } from '@/library/webrtc'
//import { sendMessageUnified } from '@/library/client-unified-send'
//import { registerUnifiedMessageCallBack } from '@/library/client-unified-receive'
import  WZoom from 'vanilla-js-wheel-zoom'
import { connect as connectWs, removeOnConnectedCB } from '@/library/websocket.js';
import { getDatasetsOfProject, annotateImage } from '@/library/http-api-client';
import EnterPasswordForm from '@/components/EnterPasswordForm.vue';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import Button from 'primevue/button';
import InferImageML from '@/components/InferImageML.vue';
import appConfig from '@/config.js';


// Constants
const NUM_IMAGES_TO_DOWNLOAD = 1000; // Numer of images to download each time

@Options({
    components: {
        LoadingOverlay,
        NavBar,
        Footer,
        Button,
        EnterPasswordForm,
        RecycleScroller,
        InferImageML,
    },
    data: function(){
        return {
            errorMsgPre: '',
            errorMsgPost: '',
            loadingOverlay: {show: false, text: 'Loading'},
            showBackButton: false,
            showRefreshButton: false,
            showSpinner: false,
            servConnected: null,
            devConnected: null,
           
            devName: this.$route.params.DevName,
            customer: this.$route.params.Customer,
            allDevIds: [], // List of all devIds
            
            gettingData: false,
            justGotData: false,
            lastScrollEndIndex: 0,
            projectId: null,
            projectName: null,
            startDateTime: '22-00-00T00_00_00_000', // Start DateTime. Read from path
            dataPerDev: {}, // Data per device (devId). List of objects {ps: pos, dt: date, fk: fileKey, url: fileUrl, al: [], ap: {}}
            lastTsPerDev: {}, // Latest time in data per device
            pendindDataDevices: [], // List of pending devices getting data    

            // Viewport size
            windowWidth: null,
            windowHeight: null,
            // Original Image Size
            imgOriginalWidth: 200, // Image natural width
            imgOriginalHeight: 400, // Image natural height
            // Image size so accomadate a row in window
            imgWidth: 200 / 10, // Initial value
            imgHeight: 400 / 10, // Initial value
            rotate180: true,

            // 
            s3Client: auth.getS3Client(appConfig.HistoryS3Bucket), // S3 client
            camPositions: [], // Sorted list of camera positions
            imgPerKey: {}, // Img object per file key
            camHeads: {}, // Dict with key camera position and value the head index for that camera. First camera row empty for this camera
            camRowHead: 0, // index of camera row head. First completely empty camera row
            camRowList: [], // List of camera rows
            // For modal window
            rowIdSelected: -2, // Row selected to show in modal window
            imgModalIsShow: false,
            initialScrollYModal: 0, // window scrollY when open modal
            initialRowSelectedModal: 0, // Initial row selected when open modal
            imgWidthtInModal: 400,
            imgHeightInModal: 400,

            helpInfo: '',
            // For datepicker
            date: new Date(),
            // For Datasets
            datasetOptions: null, // For select
            datasetIdSelected: null, // DatasetId selected
            defects: [], // List of defects. Taken from datasets of project
            defectsWithDetection: [], // List of defects with detection. Taken from datasets of project

            modalWZoom: null, // WZoom object in modal window

            // Encryption
            enterPasswwordModalShow: false,
            anyImgIsEncrypted: false, // Whether is detected that some images are encrypted

            handleKeyDown: null, // Function to handle key down

            appConfig: appConfig, // General config

            numImgsBeingLoaded: 0, // Number of images being loaded

            redirectinToLogin: false, // Flag to indicate that user is redirected to login page

            numAPIRetries: 0, // Number of retries to get data from API
        }
    },
    props: [],
    methods: {
        getTimeIfExist: getTimeIfExist,
        imageInnerBorderColor(imgData) {
            // console.log(`imageBorderColor. item: ${JSON.stringify(item)}, col: ${col}`);
            return imgData.acttrig ? 'red' : 'white';
        },
        // Extract the timestamp and camera position from filepath
        extractDateAndPosition(filePath) {
            //console.log(`KEY: ${filePath}`);
            try {
                //console.log(`SSSSSS: ${JSON.stringify(filePath.split('@'))}`)
                let dateString = filePath.split('@')[1].split('C')[0];
                //console.log(`dateString: ${dateString}`)
                let reggie = /(\d{2})-(\d{2})-(\d{2})T(\d{2})-(\d{2})-(\d{2})-(\d{3})/;
                let dateArray = reggie.exec(dateString); 
                let dateObject = new Date(
                    (2000+(+dateArray[1])), // Year is only 2 digits
                    (+dateArray[2])-1, // Careful, month starts at 0!
                    (+dateArray[3]),
                    (+dateArray[4]),
                    (+dateArray[5]),
                    (+dateArray[6]),
                    (+dateArray[7])
                );
                // Camera position
                let posStr = filePath.split('@')[1].split('C')[1].split('.j')[0];

                return [dateString, dateObject, +posStr]
            } catch (error){
                console.log(`extractDateAndPosition exception with filePath: ${filePath}. Msg: ${error}`);
            }   return [null, null, null];
        },
        async onImgLoading(imgFileKey) {
            console.log(`onImgLoading. imgFileKey: ${imgFileKey}`);
            // Incremment loading counter
            this.numImgsBeingLoaded ++;
        },
        async onImgLoaded(imgObj, imgData) {
            // console.log(`onImgLoaded. imgObj: ${JSON.stringify(imgObj)}`);
            // console.log(`onImgLoaded. ImageData: ${JSON.stringify(imgData)}`);
            console.log(`onImgLoaded. ImageFileKey: ${imgObj.fk}`);
            // Decrease loading counter
            this.numImgsBeingLoaded --;
            // console.log(`onImgLoaded. numImgsBeingLoaded: ${this.numImgsBeingLoaded}`);
            // Get metadata if not got yet
            if (imgObj.al.length === 0) {
                try {
                    let fileKey = imgObj.fk;
                    //console.log(`onImgLoaded. Image: ${fileKey}`);                    
                    // Get metadata from S3 object header
                    //console.log(`onImgLoaded. Getting Metadata from S3 file metadata`);
                    let data = await this.s3Client.headObject({Bucket: appConfig.HistoryS3Bucket, Key: fileKey}).promise()
                    //console.log(`headObject (${fileKey}) Result (${typeof data}): ${JSON.stringify(data)}`);
                    //console.log(`onImgLoaded. head: ${JSON.stringify(data)}`);
                    if (data.Metadata && data.Metadata.metadata) {
                        // console.log(`onImgLoaded. MMMMMMetadata: ${JSON.stringify(data.Metadata.metadata)}`);
                        try {
                            let metadata = JSON.parse(data.Metadata.metadata);
                            // imgObj.al = this.defects;
                            imgObj.lwd = this.defectsWithDetection;
                            imgObj.ap = metadata.PredictAll;
                            // Get al (All Labels) as keys of PredictAll
                            imgObj.al = metadata.PredictAll ? Object.keys(metadata.PredictAll) : [];
                            imgObj.acttrig = metadata.ActTrig;
                            if (metadata.PredictLabels) imgObj.pls = metadata.PredictLabels;
                            if (metadata.PredictBBoxes) imgObj.pbbs = metadata.PredictBBoxes;
                            if (metadata.AssignLabels) imgObj.asls = metadata.AssignLabels;
                            if (metadata.AssignBBoxes) imgObj.asbbs = metadata.AssignBBoxes;
                            imgObj.md = metadata; // All metadata
                            //console.log(`onImgLoaded. Metadata: ${JSON.stringify(metadata)}`);
                            if (metadata.ActTrig) console.log(`onImgLoaded. ImageFileKey: ${imgObj.fk}, ActTrig: ${metadata.ActTrig}`);
                        } catch (error){
                            console.error(`onImgLoaded exception getting metadata in image: ${JSON.stringify(imgObj)}. Error: ${error.message}`);
                        }  
                    }           
                    // Update original size if changed
                    const imgOriginalWidth = imgData.imgOriginalWidth;
                    const imgOriginalHeight = imgData.imgOriginalHeight;
                    if (imgOriginalWidth !== this.imgOriginalWidth || imgOriginalHeight !== this.imgOriginalHeight) {
                        console.log(`onImgLoaded. imgOriginalWidth changed from ${this.imgOriginalWidth} to ${imgOriginalWidth} and imgOriginalHeight changed from ${this.imgOriginalHeight} to ${imgOriginalHeight}`);
                        this.imgOriginalWidth = imgOriginalWidth;
                        this.imgOriginalHeight = imgOriginalHeight;
                        this.resizeImage();
                    }     
                        } catch (error){
                            console.error(`onImgLoaded exception in image: ${JSON.stringify(imgObj)}. Error: ${error.message}`);
                        }    
                    }
        },
        async onImgLoadError(imgObj, imgData) {
            // console.log(`onImgLoadError. ImgObj: ${JSON.stringify(imgObj)}`);
            console.log(`onImgLoadError. ImageData: ${JSON.stringify(imgData)}`);
            // Decrease loading counter
            this.numImgsBeingLoaded --;
            // console.log(`onImgLoadError. numImgsBeingLoaded: ${this.numImgsBeingLoaded}`);
        },
        onImgExpired(imgObj) {
            console.log(`onImgExpired. imgObj: ${JSON.stringify(imgObj)}`);
            if (!this.redirectinToLogin) {
                // Imgage can not be read from S3 --> inform user that session has expired and go to login
                alert(`Session has expired. Please login again`);
                // Redirect to login page
                this.$router.push('/login');
                this.redirectinToLogin = true;
            } else {
                console.error(`onImgExpired. Redirected to login page. No more alerts`);
            }
        },

        // onImgButtonCliked(imgObj, fileKey, pos, label, imgMetaData) {
        //     console.log(`History.onImgButtonCliked. Key: ${fileKey}, Pos: ${pos}, Label: ${label}, Metadata: ${JSON.stringify(imgMetaData)}`);
        //     // Check that any daatset is selected
        //     if (!this.datasetIdSelected) {
        //         alert(`To be able to label images you need to select first the Data Set`);
        //         return;
        //     }
        //     // If clicked any label already selected --> remove assign label
        //     if (imgObj.asls.includes(label)) {
        //         console.log(`Label: ${label} clicked again. Unassigning label`);
        //         imgObj.asls.splice(imgObj.asls.indexOf(label), 1);
        //     } else { // New label clicked
        //         imgObj.asls.push(label);
        //         // Ensure exclusivity between 'Ok' and rest of defects
        //         if (label === 'Ok') imgObj.asls = ['Ok']; // Remove the rest of labels
        //         else if (imgObj.asls.includes('Ok')) imgObj.asls.splice(imgObj.asls.indexOf('Ok'), 1); // --> remove 'Ok if exist'
        //     }
        //     let pieces = fileKey.split('/');
        //     let fileName = pieces[pieces.length -1];  // Get the file name from key
        //     let filePath = fileKey.replace(fileName, ''); // filePath = fileKey removing the fileName 
        //     if (fileName) {                
        //         let body = {
        //             //FileKey: fileKey,
        //             Originator: 'History',
        //             HstFilePath: filePath,
        //             FileName: fileName,
        //             Encrypted: imgMetaData['Encrypted'],
        //             ImageId: imgMetaData['ImageId'],
        //             CamPos: pos,
        //             DevId: imgMetaData['DevId'],
        //             DevName: imgMetaData['DevName'],
        //             PredictLabels: imgMetaData['PredictLabels'],
        //             PredictAll: imgMetaData['PredictAll'],
        //             UserId: this.userId,
        //             AssignLabels: imgObj.asls,
        //             TimeStampStr: imgMetaData['TimeStampStr'],
        //             ProjectId: imgMetaData['ProjectId'],
        //             DatasetId: this.datasetIdSelected,
        //             EngineId: imgMetaData['EngineId'],                        
        //         };
        //         // fetchAPIData('history/v1/labelImage', body);                
        //         labelImage(body, false);
        //     } else console.error(`onImgButtonCliked. Filename not available from file key: ${fileKey}`);            
        // },
        onAnnotateImage(imgObj, imgMetaData, fileKey, pos, assgnLabels, assignBBoxes) {

            // Check if it is readonly
            if (imgObj.ReadOnly) {
                console.error(`onAnnotateImage. This dataset is Read Only`);  
                alert(`This dataset is in read only mode. You can not change labels`);
                return;
            }

            // Save annotations to image object
            imgObj.asls = assgnLabels;            
            imgObj.asbbs = assignBBoxes;

            imgObj.md = imgMetaData;       

            imgObj.chng = !imgObj.chng; // Toggle change flag to force re-render

            const originator = 'History';
            const userId = this.userId;
            const datasetIdTarjet = this.datasetIdSelected;
            const projectId = getProjectIdFromDatasetId(datasetIdTarjet);
            const saveInStore = true;    
            

            console.log(`imgMetadata: ${JSON.stringify(imgMetaData)}`);

            annotateImage(imgMetaData, fileKey, pos, assgnLabels, assignBBoxes, originator, userId, projectId, datasetIdTarjet, saveInStore);
        },
        onWindowResize() {
            this.windowWidth = document.documentElement.scrollWidth;
            this.windowHeight = document.documentElement.scrollHeight;
            console.log(`onWindowResize. Width: ${this.windowWidth}, Height: ${this.windowHeight}`);
            this.resizeImage();
            if (this.modalWZoom && this.imgModalIsShow) this.modalWZoom.prepare();
        },
        resizeImage() {
            // Calculate the image size to accomodate in window
            if (this.camPositions.length) {
                this.imgWidth = Math.round(this.windowWidth / this.camPositions.length) - 7; // WIdth to accomodate all images in a row. -7 for border. Dont know why 7 exactly
                this.imgHeight = this.imgWidth * this.imgOriginalHeight / this.imgOriginalWidth; // Height to keep aspect ratio
            } else console.log(`resizeImage error. No compositions: ${JSON.stringify(this.camPositions)}`);
        },
        onScrollerUpdate(startIndex, endIndex) {
            console.log(`onScrollerUpdate. startIndex: ${startIndex}, endIndex: ${endIndex}, Num Camera Rows: ${this.numCamRows}`);
            // Get more data if scroll is closed to curremt number of cam rows
            if (!this.gettingData && !this.justGotData  && endIndex > this.numCamRows - 10 && endIndex > this.lastScrollEndIndex) this.getMoreImages();
            this.justGotData = false;
            this.lastScrollEndIndex = endIndex;
            console.log(`Scroll: ${this.$refs.mainScroller.scrollTop}`);
            console.log(`Scroll2: ${window.scrollY}`);
        },
        onCamRowClick(rowId) {
            this.rowIdSelected = rowId;
            // this.imgHeightInModal = Math.min(this.windowHeight - 200, this.windowWidth * this.imgOriginalHeight / this.imgOriginalWidth - 5);
            // this.imgWidthtInModal = this.windowWidth;
            // console.log(`Nav Bar Height: ${this.$refs.navBar.clientHeight}`);
            // this.navBarHeight = this.$refs.navBar.clientHeight;

            console.log(`imgOriginalHeight: ${this.imgOriginalHeight}, imgOriginalWidth: ${this.imgOriginalWidth}`);
            let nvBarHeight = this.$refs.navBar.clientHeight;
            console.log(`nvBarHeight: ${nvBarHeight}`);
            let factor1 = window.innerHeight - 2 * nvBarHeight - 5;
            let factor2 = window.innerWidth * this.imgOriginalHeight / this.imgOriginalWidth - 5;
            console.log(`Factor1: ${factor1}, Factor2: ${factor2}`);
            this.imgHeightInModal = Math.min(factor1, factor2);
            this.imgWidthtInModal = window.innerWidth;            
            console.log(`innerHeight: ${window.innerHeight}, innerWidth: ${window.innerWidth}`);
            console.log(`imgHeightInModal: ${this.imgHeightInModal}, imgWidthtInModal: ${this.imgWidthtInModal}`);

            this.initialScrollYModal = window.scrollY;
            this.initialRowSelectedModal = this.rowIdSelected;
            console.log(`onCamRowClick. Row Id: ${rowId}, windowWidth: ${this.windowWidth}, imgHeightInModal: ${this.imgHeightInModal}, imgWidthtInModal: ${this.imgWidthtInModal}`);
            this.showImgModal();
        },
        onNewDateSelected(newDate) {
            console.log(`onNewDateSelected. New Date: ${newDate}`);
            if (formatDateTimeForHistory(newDate) !== this.startDateTime) { // Real new date
                // Just iun case modal is open
                this.closeModal();
                // Set startDateTime
                this.startDateTime = formatDateTimeForHistory(newDate);
                // Clean dataset
                this.errorMsgPre = '';
                this.errorMsgPost = '';
                this.camRowList = [];
                this.camHeads = {};
                this.camRowHead = 0;
                this.getMoreImages();
            }
        },
        getDataForDevice(devId, startDateTime) {
            console.log(`getDataForDevice. Device: ${devId}, Start After: ${startDateTime}`);
            const s3KeyPrefix = `${appConfig.HistImgsBucketPrefix(this.customer, this.projectId)}/${devId}@`; //  `cst/${this.customer}/prj/${this.projectId}/img/${devId}@`; // `hstimgs/${this.projectId}/${devId}@`;
            const s3StartAfter = `${s3KeyPrefix}${startDateTime}`;
            this.dataPerDev[devId] = []; // Empty list
            this.lastTsPerDev[devId] = new Date(8640000000000000); // Max date
            this.s3Client.listObjectsV2({MaxKeys: NUM_IMAGES_TO_DOWNLOAD, Prefix: s3KeyPrefix, StartAfter: s3StartAfter}, async (err, data) => {
                if (err) {
                    console.error(`There was an error listing images for device: ${devId}. Error: ${err.message}`);
                    if(this.numAPIRetries < 3) {
                        this.numAPIRetries ++;
                        console.log(`Retrying to get data for device: ${devId} and startDateTime: ${startDateTime} in 1 second ...`);
                        setTimeout(() => {this.getDataForDevice(devId, startDateTime)}, 1000);
                    } else {
                        console.error(`Max retries reached. Can not get data for device: ${devId}`);
                    }
                } else {
                    this.numAPIRetries = 0; // Reset retries
                    //console.log(`S3 DATA: ${JSON.stringify(data)}`);                    
                    console.log(`Got data for device: ${devId}`);
                    if ('Contents' in data) {   
                        //alert(`Num Images: ${data['Contents'].length}`)   
                        let numImagesWithUrl = 0;                  
                        for (let item of data['Contents']) {
                            const fileKey = item['Key'];
                            // Check if image is encrypted
                            if (fileKey.endsWith('.enx')) { // Encrypted image
                                if (!this.anyImgIsEncrypted && !this.decryptionPassword) { // First time encrypted image found and not defined jet
                                    // Remove overlay
                                    this.loadingOverlay.show = false;
                                    //  Show Enter Password Modal 
                                    this.enterPasswwordModalShow = true;
                                    // Wait until modal is closed
                                    while (this.enterPasswwordModalShow) await new Promise(r => setTimeout(r, 100));
                                    this.loadingOverlay.show = true;
                                }
                                this.anyImgIsEncrypted = true; // Check if file has encryption extension
                            }
                            const dateNpos = this.extractDateAndPosition(fileKey);
                            const dateStr = dateNpos[0];
                            const date = dateNpos[1];
                            const pos = dateNpos[2];
                            if (!(this.camPositions.includes(pos))) this.camPositions.push(pos); // Add position if not in list

                            // Build image data object
                            let imgData = {
                                ps: pos,
                                dt: date,
                                ds: dateStr,
                                fk: fileKey,
                                url: null, // Will be filled later
                                al: [],
                                ap: {},
                            }
                            // Add to list
                            this.dataPerDev[devId].push(imgData);
                            // Get signed URL asynchronously
                            this.s3Client.getSignedUrl('getObject', {
                                    Bucket: appConfig.HistoryS3Bucket,
                                    Key: fileKey,
                                    Expires: 60 * 60 // 1 hour
                                }, (error, url) => {
                                    if (error) {
                                        console.error(`Error getting signed URL for file: ${fileKey}. Error: ${error.message}`);
                                        numImagesWithUrl++;  
                                    } else {
                                        //console.log(`getSignedUrl URL: ${url}`);
                                        // Update url in imgData
                                        imgData.url = url;
                                        imgData['PreSignExprTs'] = Date.now() + 60 * 60 * 1000; // 1 hour
                                        numImagesWithUrl++;  
                                    }
                                }
                            );

                            // Update this.lastTsPerDev
                            this.lastTsPerDev[devId] = date;
                        }
                        this.camPositions.sort();  // Sort camera positions
                        console.log(`Camera positions: ${this.camPositions}`);
                        // Initialize head for position if needed
                        for (let pos of this.camPositions) if (this.camHeads[pos] === undefined) this.camHeads[pos] = 0;
                        // wait until all urls are filled
                        console.log(`Waiting until all (${data['Contents'].length}) urls are filled`);
                        while (numImagesWithUrl < data['Contents'].length)await new Promise(r => setTimeout(r, 100));
                        console.log(`getDataForDevice. Device: ${devId} downloaded: ${this.dataPerDev[devId].length} items. With last Time: ${this.lastTsPerDev[devId]}`)
                    } else console.error(`listObjectsV2 missed Contents: ${JSON.stringify(data)}`);  
                }
                // Remove it from this.pendindDataDevices
                this.pendindDataDevices = this.pendindDataDevices.filter(item => item !== devId);
                // Check if all devices has finished getting data
                if (this.pendindDataDevices.length === 0) this.onAllDevData();
            });
        },
        newPasswordSubmitted(data) {
            console.log(`newPasswordSubmitted: ${JSON.stringify(data)}`);
            this.enterPasswwordModalShow = false;
            store.commit('projects/setProjectPassword', {ProjectId: this.projectId, Password: data.Password});
        },
        getMoreImages() {
            console.log(`getMoreImages. Devices: ${this.allDevIds}, Start After: ${this.startDateTime}`);
            this.gettingData = true;
            this.loadingOverlay = {show: true, text: `Getting images`}; 
            this.pendindDataDevices = [];
            for (let devId of this.allDevIds) {
                this.pendindDataDevices.push(devId);
                this.getDataForDevice(devId, this.startDateTime)
            }
        },
        // Called once all data for all devices has been taken
        onAllDevData() {
            console.log(`onAllDevData`);
            // Get the smallest lastTsPerDev
            let minLastTsPerDev = Object.values(this.lastTsPerDev).sort()[0];
            // Remove all extra data to get all at max same TS as smallest
            for (let devId of this.allDevIds) {
                let numRemoves = 0;
                for (let i = this.dataPerDev[devId].length-1; i>=0; i--) {
                    if (this.dataPerDev[devId][i].dt > minLastTsPerDev) {
                        this.dataPerDev[devId].splice(i);
                        numRemoves ++;
                    }
                }
                console.log(`Num removes for device: ${devId} are: ${numRemoves}`)
            }
            // Merge all data
            let allData = []
            for (let devId of this.allDevIds) {
                allData = allData.concat(this.dataPerDev[devId]);
            }
            console.log(`onAllDevData. Total items: ${allData.length}`);
            // Sort accoring to date
            allData.sort((a, b) => {return a.dt - b.dt;});
            // Build Camera rows
            for (let img of allData) {
                let pos = img.ps;
                if (this.camHeads[pos] === this.camRowHead) { // First camera image for the row
                    let camRow = {id: this.camRowHead}; // Create empty camera row
                    camRow[pos] = img; // Insert image
                    this.camRowList.push(camRow); // Insert camera row to list
                    this.camHeads[pos] ++; // Increment camera head
                    this.camRowHead ++; // Increment camRowHead
                } else { // Camera is not in the head --> fill all previous empty positions until head
                    for (let i=this.camHeads[pos]; i<this.camRowHead; i++) {
                        this.camRowList[i][pos] = img; // Insert image
                    }
                    this.camHeads[pos] = this.camRowHead; // Incremnet head
                }
            }
            //alert(`camRowHead: ${this.camRowHead}`)
            // Complete all empty camera rows extending last available image
            for (let pos of this.camPositions) {
                console.log(`pos: ${pos}, head: ${this.camHeads[pos]}`)
                for (let i=this.camHeads[pos]; i<this.camRowHead; i++) {
                    console.log(i)
                    if (this.camRowList[i-1] && this.camRowList[i-1][pos]) {
                        this.camRowList[i][pos] = {ps: pos, dt: this.camRowList[i-1][pos].dt, ds: this.camRowList[i-1][pos].ds, fk: this.camRowList[i-1][pos].fk, url: this.camRowList[i-1][pos].url, al: [], ap: {}}; // Insert image
                    }
                }
                this.camHeads[pos] = this.camRowHead; // Incremnet head
            }
            // Force change in this.camRowList to refresh UI
            this.camRowList = [].concat(this.camRowList);
            // Remove spinner
            this.loadingOverlay = {show: false}; 
            // Update img size if needed
            window.dispatchEvent(new Event('resize'));
            // update startDateTime to get further data
            if (allData.length) {
                let lastDate = allData[allData.length-1].ds;
                this.startDateTime = lastDate + 'ZZZ'; // + 'ZZZ' to get from next oine
            } else {
                if (this.camRowList.length) this.errorMsgPost = 'No more images found'
                else this.errorMsgPre = 'No images found'
            }
            this.gettingData = false;
            this.justGotData = true;
        },
        // showModal () {
        //     console.log(`Showing modal window`);
        //     this.imgModalIsShow = true;
        //     this.helpInfo = 'Use the arrows:\n ◄ Zoom out \n ► Zoom in \n ▲ Previous image \n ▼ Next image';
        // },
        // closeModal () {
        //     console.log(`Closing modal window. Row Selected: ${this.rowIdSelected}`);
        //     this.imgModalIsShow = false;
        //     this.helpInfo = 'Click on any image to enlarge';
        //     //window.scroll(0, this.imgHeight * this.rowIdSelected + 100);
        // },
        onKeyUpInModal (event) {
            console.log('onKeyUpInModal');
            if (this.rowIdSelected > 0) {
                this.rowIdSelected --;
                window.scroll(window.scrollX, this.initialScrollYModal + (this.rowIdSelected - this.initialRowSelectedModal) * this.imgHeight);
            }
            event.stopPropagation();
            event.preventDefault();
            this.showImgModal();
        },
        onKeyDownInModal (event) {
            console.log('onKeyDownInModal');
            if (this.rowIdSelected < this.camRowList.length-1) {
                this.rowIdSelected ++;
                window.scroll(window.scrollX, this.initialScrollYModal + (this.rowIdSelected - this.initialRowSelectedModal) * this.imgHeight);
            }
            event.stopPropagation();
            event.preventDefault();
            this.showImgModal();
        },
        // onKeyRightInModal () {
        //     console.log('onKeyRightInModal');
        //     this.imgHeightInModal = this.imgHeightInModal * 1.2;
        //     this.imgWidthtInModal = this.imgWidthtInModal * 1.2;
        // },
        // onKeyLeftInModal () {
        //     console.log('onKeyLeftInModal');
        //     this.imgHeightInModal = this.imgHeightInModal / 1.2;
        //     this.imgWidthtInModal = this.imgWidthtInModal / 1.2;
        // },
        showImgModal() {
            this.imgModalIsShow = true;
            // this.modalPos = pos;
            // this.modalImgClass = {...imgClass}; // Clone imgClass to get static image
            document.getElementById("ImgModal").style.display = "block";
            let windowHeight = window.innerHeight;
            let windowWidth = window.innerWidth;
            let numImgsInRow = Object.keys(this.camRowList[this.rowIdSelected]).length - 1; // -1 becouse has the images and 'id'
            let vertRate =  windowHeight / (numImgsInRow * this.imgHeight);
            let horRate = windowWidth / this.imgWidth;
            console.log(`showImgModal. numImgsInRow: ${numImgsInRow}, vertRate: ${vertRate}, horRate: ${horRate}, windowHeight: ${windowHeight}, windowWidth: ${windowWidth}, minScale: ${Math.min(vertRate / horRate, 1)}`);
            // Start WZoom object
            setTimeout(() => {   
                if (this.modalWZoom) { this.modalWZoom.destroy(); this.modalWZoom = null; }   
                if (this.imgModalIsShow) {
                    this.modalWZoom = WZoom.create('#zoompan', {
                        type: 'html',
                        speed: 5,
                        minScale: Math.min(0.9 * vertRate / horRate, 1), // 0.9 to have some margin
                        maxScale: 10,
                        zoomOnClick: false,
                        zoomOnDblClick: true,
                    });
                }
            }, 10);
        },
        closeModal() {
            console.log(`closeModal`);
            this.helpInfo = 'Use the arrows:\n ◄ Zoom out \n ► Zoom in \n ▲ Previous image \n ▼ Next image';
            this.imgModalIsShow = false;
            this.rowIdSelected = null;
            document.getElementById("ImgModal").style.display = "none";
        },
        onScroll() {
            //console.log(`onScroll`);
            // Recreate modalWZoom if needed
            if (this.imgModalIsShow && this.modalWZoom) {
                this.modalWZoom.prepare();
            }
        },
        onDatasetsData(datasetsDataResult) {
            if (datasetsDataResult === null || datasetsDataResult.length == 0) {
                alert(`No datasets created in project: ${this.projectName}. At least one dataset should be created`);
                this.loadingOverlay = {show: false};
            }
            console.log(`onDatasetsData: ${JSON.stringify(datasetsDataResult)}`);
            this.datasetOptions = [];
            for (let ds of datasetsDataResult) {
                // Exclude internal Datasets
                if (ds.DatasetName[0] !='[') {
                    this.datasetOptions.push({id: ds.DatasetId, name: ds.DatasetName})
                }
            }
            // Get the defects from first Dataset
            this.defects = datasetsDataResult[0].Defects;
            this.defectsWithDetection = datasetsDataResult[0].DefectsWithDetection || [];
            console.log(`Defecst are: ${this.defects}, Defects with detection are: ${this.defectsWithDetection}`);
            
            // // Get initial data set
            // this.getMoreImages();
        },
        refreshDatasetsData(onDataReceivedCB=null) {
            console.log('Refreshing Datasets');
            
            this.loadingOverlay = {show: true, text: 'Loading Datasets'};
            // Get Project Data for the user
            getDatasetsOfProject(this.projectId, (datasetsDataResult) => {
                this.onDatasetsData(datasetsDataResult);
                // Call callback if defined
                if (onDataReceivedCB) onDataReceivedCB();
            });
        },
        onNewOptionSelected(newOption) {
            console.log(`newOptionSelected: ${newOption}`);
            this.datasetIdSelected = newOption;
        },
        onWsConnected() {
            console.log(`onWsConnected`);
            let customeAuthorized = this.isCustomerAuthorized(this.customer);
            if (customeAuthorized) {
                console.log(`Customer authorized: ${this.customer}`);                
                this.onUserAuthorized();
            } else { // Redirect to login
                console.log(`Customer not authorized: ${this.customer}`);
                this.loadingOverlay.show = false;
                // Redirect to login page
                window.location.href = '/login';
            }
        },
        onUserAuthorized() {
            console.log(`onUserAuthorized`);
            this.loadingOverlay.show = false;
            // this.refreshDatasetsData(() => {
            //     // Get initial data set
            //     console.log(`Getting initial data set`);
            //     this.getMoreImages();
            // });
            this.refreshDatasetsData();
            // Get initial data set
            console.log(`Getting initial data set`);
            this.getMoreImages();
        },
    },
    computed: {   
        //servConnected: function () { return store.getters['connection/isWscConnected']}, // Whether WebSocket to signalling server is conneced or not
        //devConnected: function () { return store.getters['connection/isDevWrtcConnected'](this.devId) || store.getters['connection/isDevSioConnected'](this.devId)},  // Whether WebRTC or SocketIO to device is conneced or not
        userId: function () { return store.state.login.email },
        s3KeyStartAfter: function () { return this.s3KeyPrefix + '@' + this.startDateTime; },
        numCamRows: function () { return this.camRowList.length; },
        decryptionPassword: function () { if (this.projectId) return store.state.projects.passwordPerProject[this.projectId] },
        ...mapGetters('login', ['isCustomerAuthorized']),
    },
    // Lifecycle hooks
    mounted() {
        console.log(`History Device View Mounting`);
        // Get URL path parameeters
        this.projectId = this.$route.params.ProjectId;
        this.projectName = getProjectNameFromProjectId(this.projectId);
        this.startDateTime = this.$route.params.startDateTime;
        this.allDevIds = this.$route.params.DevIds.split('+');
        console.log(`Project Name: ${this.projectName}, Device Name: ${this.devName}, StartDateTime: ${this.startDateTime}, DevIds: ${this.allDevIds}`);
        // Connect to AWS signalling server through Web Socket to get authorization data
        this.loadingOverlay = {show: true, text: 'Authorizing...'};
        connectWs(this.onWsConnected);
        // Set date picker date to startDateTime
        this.date = setDateFromShortStr(this.date, this.startDateTime); 
        // Get datasets of project of this device
        // this.refreshDatasetsData();
        this.helpInfo = 'Click on any image to enlarge';
        this.showBackButton = this.$router.options.history.state.back != null ? true : false;
        document.title = `History-${this.devName}`; // Set Page title
        // Open spinner overlay
        //this.loadingOverlay = {show: true, text: `Getting images`}; 

        window.addEventListener("resize", this.onWindowResize);
    
    
        // // Get initial data set
        // console.log(`Getting initial data set`);
        // this.getMoreImages();
        
        // Join and Leave Room when visibility change
        document.onvisibilitychange = () => {
            if (document.visibilityState === "visible") {
                console.log('History page being visible');
                
            } else { //Hiden
                console.log('History page being hidden');
                
            }
        };
        // Prevent scroll being driver by keys
        let thisis = this;
        this.handleKeyDown = (event) => { 
            console.log('keydown');
            // // Prevent scroll being driver by keys if any image is being downloaded
            // if (thisis.numImgsBeingLoaded > 4) {
            //     console.log(`Preventing scroll being driver by keys if any image is being downloaded`);
            //     event.preventDefault();
            //     return;
            // }
            console.log(`Modal visibility: ${this.imgModalIsShow}`);
            if (thisis.imgModalIsShow) {
                if (event.code === "ArrowUp") this.onKeyUpInModal(event)
                else if (event.code === "ArrowDown") this.onKeyDownInModal(event)
                // else if (event.code === "ArrowRight") this.onKeyRightInModal(event)
                // else if (event.code === "ArrowLeft") this.onKeyLeftInModal(event)
                else if(event.code === "Escape") this.closeModal();    // Close modal windows if 'Escape' key is pressed
            }   
        };
        window.addEventListener("keydown", this.handleKeyDown, false);
        // Scroll event
        addEventListener("scroll", this.onScroll);
    },
    unmounted() {
        console.log('History View Unmounted')
        if (this.devId) {
            // Close WebRTC connection
            console.log(`Closing WebRTC connection to master device: ${this.devId}`);
            //closeWrtc(this.devId); // Master

        }

        window.removeEventListener("resize", this.onWindowResize);
        window.removeEventListener("scroll", this.onScroll);
        window.removeEventListener("keydown", this.handleKeyDown, false);
        removeOnConnectedCB(this.onWsConnected); // Remove callback for onWsConnected
       
    },
    beforeCreate() {console.log('HistoryDevice View beforeCreate')},
    beforeMount() {console.log('HistoryDevice View beforeMount')},
    created() {console.log('HistoryDevice View created')},
    //beforeUpdate() {console.log('HistoryDevice View beforeUpdate')},
    //updated() {console.log('HistoryDevice View updated')},
    activated() {console.log('HistoryDevice View activated')},
    deactivated() {console.log('HistoryDevice View deactivated')},
    beforeUnmount() {console.log('HistoryDevice View beforeUnmount')},
    errorCaptured() {console.log('HistoryDevice View errorCaptured')},
    // renderTracked() {console.log('History View renderTracked')},
    
})
export default class History extends Vue {}
</script>

<style>

    /* .vue-universal-modal-content {
        overflow-x: auto;
    } */

</style>

<style scoped>
    .scroller {
    height: 100%;
    }

    .cam_row {
    display: flex;
    align-items: center;
    }

    .imgModal {
        z-index: 2000;
    }

    /* #mainScroller {
        overflow-x: scroll;
    } */

    /* Center down content */
    .center-down {
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
